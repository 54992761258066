import React, { useState } from 'react'
import { DsBox, DsButton } from '@am92/react-design-system'

import FundsAndMarginDetails from '../FundsAndMarginDetails'
import { FormValues } from '../OrderFormSection'
import SliceOrderStatusModal from './SliceOrderStatusModal'

import { TSliceConfig } from '~/src/Redux/AppState/TYPES'
import { T_PLACE_ORDER_PAYLOAD } from '~/src/Redux/Orders/Services/placeOrder.Service'
import { T_SCRIPTDATA } from '~/src/Redux/StockMaster/Reducer'

import { triggerCleverTapEvent } from '~/src/Utils/global'

export interface ISliceOrderModalBottomProps {
  orderDetails: T_PLACE_ORDER_PAYLOAD
  stockDetails: T_SCRIPTDATA
  values: FormValues
  handlePlaceOrder: (item: FormValues, type: string) => Promise<any>
  handleCloseDetailsModal: () => void
  updateSliceBannerSeen: (item: boolean) => void
  sliceOrderConfig: TSliceConfig
}

export interface OrderResponse {
  omsOrderId: string
  orderRefId: string
  shortFallFlag: string
  status: string
  isAmo: boolean
  orderNumber: number
  quantity: number
}

const SliceOrderModalBottom: React.FC<ISliceOrderModalBottomProps> = props => {
  const [orders, setOrders] = useState<OrderResponse[]>([])
  const [openStatusModal, setOpenStatusModal] = useState(false)
  const {
    orderDetails,
    values,
    stockDetails,
    handlePlaceOrder,
    handleCloseDetailsModal,
    updateSliceBannerSeen,
    sliceOrderConfig
  } = props
  const {
    transactionType = '',
    segment = '',
    scriptId = '',
    totalQty = 0
  } = orderDetails || {}
  const { maxSingleOrderQty = 0, lotsize } = stockDetails || {}
  const { bnpl = '' } = values || {}
  const color = transactionType === 'BUY' ? 'success' : 'error'

  const { max_slice_limit } = sliceOrderConfig

  // Calculate the number of orders considering the allowed limit
  const calculatedOrders = Math.ceil(totalQty / maxSingleOrderQty)
  const formattedNumberOfOrders =
    calculatedOrders > max_slice_limit ? 0 : calculatedOrders

  // Distribute quantity with maximum allowed first, leaving the smallest for the last order
  let remainingQty = totalQty
  const sliceOrders = Array.from(
    { length: formattedNumberOfOrders },
    (_, index) => {
      const quantity =
        index === formattedNumberOfOrders - 1
          ? remainingQty
          : Math.min(maxSingleOrderQty, remainingQty)
      remainingQty -= quantity
      return {
        orderNumber: index + 1,
        quantity
      }
    }
  )

  const handleSubmitSliceOrder = async () => {
    updateSliceBannerSeen(true)
    setOpenStatusModal(true)

    for (const [index, slice] of sliceOrders.entries()) {
      const formattedQuantity =
        segment !== 'EQ' && slice.quantity && lotsize
          ? slice.quantity / lotsize
          : slice.quantity

      const formattedValues: FormValues = {
        ...values,
        quantity: formattedQuantity
      }

      const customAttributes = {
        Event_Status: 'Pass',
        Screen_Name: 'Sliced Order',
        Action: transactionType,
        ...formattedValues
      }

      triggerCleverTapEvent('Order', customAttributes)

      const placeOrderResponse = await handlePlaceOrder(
        formattedValues,
        'slice'
      )
      const formattedResponse: OrderResponse = {
        ...placeOrderResponse,
        ...slice,
        orderNumber: index + 1
      }

      // Updating the new order
      setOrders(prevOrders =>
        [...prevOrders, formattedResponse].sort(
          (a, b) => a.orderNumber - b.orderNumber
        )
      )
    }
  }

  const disableButton = sliceOrders.length !== orders.length

  return (
    <>
      {openStatusModal ? (
        <SliceOrderStatusModal
          orders={orders}
          open={openStatusModal}
          onClose={setOpenStatusModal}
          setOrders={setOrders}
          disableButton={disableButton}
          handleCloseDetailsModal={handleCloseDetailsModal}
        />
      ) : null}
      <DsBox
        sx={{
          position: 'sticky',
          bottom: '0',
          left: '0',
          background: 'var(--ds-colour-surfaceBackground)',
          p: 'var(--ds-spacing-bitterCold) 0 0 0',
          width: '100%'
        }}
      >
        <DsBox
          sx={{
            p: 'var(--ds-spacing-glacial)',
            pl: 'var(--ds-spacing-bitterCold)'
          }}
        >
          <FundsAndMarginDetails
            orderBuyOrSell={transactionType}
            scriptId={scriptId}
            segment={segment}
            values={values}
            stockDetails={stockDetails}
            isBmpl={bnpl}
            type={'slice'}
          />
        </DsBox>
        <DsBox sx={{ display: 'flex', justifyContent: 'center' }}>
          <DsButton
            onClick={handleSubmitSliceOrder}
            color={color}
            size='medium'
            sx={{ width: '94%' }}
          >
            Confirm Order
          </DsButton>
        </DsBox>
      </DsBox>
    </>
  )
}

export default SliceOrderModalBottom
