import {
  APP_TO_WORKER_ACTIONS,
  T_ONERROR_CTX,
  T_ONMESSAGE_CTX,
  T_WORKER_TO_APP_ACTIONS_TYPES,
  WORKER_TO_APP_ACTIONS,
  T_LOGIN_DATA,
  getSubscriptionArray,
  SESSION_EXPIRED_EVENT_FROM_SOCKET
} from './SOCKET_WORKER_CONSTANT'
import AppStore from '../../Configurations/AppStore'
import { updateOdinConnectionIndicatorAction } from '../../Redux/Indicators/Actions'
import { T_SCRIPTS_OBJ } from '~/src/Redux/WatchList/Reducer'
import { updateStockMasterAction } from '~/src/Redux/StockMaster/Actions'
import { updateStockTickerAction } from '~/src/Redux/StockTicker/Actions'
import TickerStore from '~/src/Configurations/TickerStore'
import { AS_TCS_WSS_URL } from '~/src/Configurations/env'
import loginWithRefreshTokenService from '~/src/Redux/Auth/Services/loginWithRefreshToken.Service'

class SocketWorkerManagerClass {
  worker: any
  userCallbacks: {
    onMessageCtxNFunc: T_ONMESSAGE_CTX
    onErrorCtxNFunc: T_ONERROR_CTX
  }

  constructor(
    onMessageCtxNFunc: T_ONMESSAGE_CTX,
    onErrorCtxNFunc: T_ONERROR_CTX
  ) {
    if (!!window.Worker) {
      this.worker = new Worker(new URL('./SocketWorker.ts', import.meta.url), {
        type: 'module'
      })
      this.worker.onerror = (e: MessageEvent) => this.onError(e)
      this.worker.onmessage = (e: MessageEvent) => this.onMessage(e)
      this.userCallbacks = {
        onMessageCtxNFunc,
        onErrorCtxNFunc
      }
    } else {
      throw new Error(
        'WebWorker not supported by browser. Please use an updated browser.'
      )
    }
  }

  postMessage(data = {}) {
    this.worker.postMessage(data)
  }

  socketInitiate(loginData: T_LOGIN_DATA) {
    this.postMessage({
      action: APP_TO_WORKER_ACTIONS.WEB_SOCKET_INITIALIZE,
      payload: {
        socketURL: AS_TCS_WSS_URL,
        loginData
      }
    })
  }

  socketReconnect(token: string) {
    this.postMessage({
      action: APP_TO_WORKER_ACTIONS.WEB_SOCKET_RECONNECT,
      payload: {
        token
      }
    })
  }

  subscribeLtpData(scriptsArray: T_SCRIPTS_OBJ[] | any[]) {
    const { scriptsDetailedArray, subscriptionArray } =
      getSubscriptionArray(scriptsArray)
    if (scriptsDetailedArray.length) {
      AppStore.dispatch(updateStockMasterAction(scriptsDetailedArray))
    }
    if (subscriptionArray.length) {
      this.postMessage({
        action: APP_TO_WORKER_ACTIONS.WEB_SOCKET_SUBSCRIBE_TO_LTP,
        payload: subscriptionArray
      })
    }
  }

  subscribeMarketDepthData(scriptsArray: T_SCRIPTS_OBJ[] | any[]) {
    const { scriptsDetailedArray, subscriptionArray } =
      getSubscriptionArray(scriptsArray)
    if (scriptsDetailedArray.length) {
      AppStore.dispatch(updateStockMasterAction(scriptsDetailedArray))
    }
    if (subscriptionArray.length) {
      this.postMessage({
        action: APP_TO_WORKER_ACTIONS.WEB_SOCKET_SUBSCRIBE_TO_MARKET_DEPTH,
        payload: subscriptionArray
      })
    }
  }

  subscribeTotalBidTotalAskData(scriptsArray: T_SCRIPTS_OBJ[] | any[]) {
    const { scriptsDetailedArray, subscriptionArray } =
      getSubscriptionArray(scriptsArray)
    if (scriptsDetailedArray.length) {
      AppStore.dispatch(updateStockMasterAction(scriptsDetailedArray))
    }
    if (subscriptionArray.length) {
      this.postMessage({
        action:
          APP_TO_WORKER_ACTIONS.WEB_SOCKET_SUBSCRIBE_TO_TOTAL_BID_TOTAL_ASK,
        payload: subscriptionArray
      })
    }
  }

  unSubscribeLtpData(scriptsArray: T_SCRIPTS_OBJ[] | any[]) {
    this.postMessage({
      action: APP_TO_WORKER_ACTIONS.WEB_SOCKET_UNSUBSCRIBE_TO_LTP,
      payload: scriptsArray
    })
  }

  unSubscribeMarketDepthData(scriptsArray: T_SCRIPTS_OBJ[] | any[]) {
    this.postMessage({
      action: APP_TO_WORKER_ACTIONS.WEB_SOCKET_UNSUBSCRIBE_TO_MARKET_DEPTH,
      payload: scriptsArray
    })
  }

  unSubscribeTotalBidTotalAskData(scriptsArray: T_SCRIPTS_OBJ[] | any[]) {
    this.postMessage({
      action:
        APP_TO_WORKER_ACTIONS.WEB_SOCKET_UNSUBSCRIBE_TO_TOTAL_BID_TOTAL_ASK,
      payload: scriptsArray
    })
  }

  terminate() {
    this.worker.closeWebSocket()
    this.worker.terminate()
    this.worker = null
  }

  onError = (event: MessageEvent) => {
    console.log(
      'There is an error with the dedicated worker thread of Order Table',
      event
    )

    this.userCallbacks.onErrorCtxNFunc.func.apply(
      this.userCallbacks.onErrorCtxNFunc.ctx,
      [event]
    )
  }

  onMessage = (event: MessageEvent) => {
    this.userCallbacks.onMessageCtxNFunc.func.apply(
      this.userCallbacks.onMessageCtxNFunc.ctx,
      [event]
    )
  }

  getNewAuthTokenForReconnection = async (subAccountId: string) => {
    const data = { subAccountId }
    const response = await AppStore.dispatch(loginWithRefreshTokenService(data))
    if (response._isCustomError) {
      const { error } = response
      const { code = '' } = error || {}
      if (code === '1081')
        dispatchEvent(new Event(SESSION_EXPIRED_EVENT_FROM_SOCKET))
    }
  }
}

const workerOnMessageHandler = (
  workerData: MessageEvent<{ action: T_WORKER_TO_APP_ACTIONS_TYPES; data: any }>
) => {
  const { action, data } = workerData.data
  switch (action) {
    case WORKER_TO_APP_ACTIONS.WEB_SOCKET_ONOPEN:
      AppStore.dispatch(updateOdinConnectionIndicatorAction(true))
      break
    case WORKER_TO_APP_ACTIONS.ODIN_DATA_RECEIVED:
      if (data) TickerStore.dispatch(updateStockTickerAction(data))
      break
    case WORKER_TO_APP_ACTIONS.WEB_SOCKET_RECONNECT:
      socketWorkerManager.getNewAuthTokenForReconnection(data)
      break
    default:
      break
  }
}

const errorOnMessageHandler = () => {
  console.log('error')
}

export const socketWorkerManager = new SocketWorkerManagerClass(
  {
    func: workerOnMessageHandler,
    ctx: this
  },
  {
    func: errorOnMessageHandler,
    ctx: this
  }
)
