import { createSelector } from '@reduxjs/toolkit'

import { TAppStore } from '~/src/Configurations/AppStore'
export const SLICE_NAME = 'appState'

const select = (state: TAppStore) => state[SLICE_NAME]

export const getAppStateDialog = createSelector(
  select,
  appState => appState.dialog
)

export const getDeepLinkAttributes = createSelector(
  select,
  appState => appState.deepLinkData
)

export const getCancelOrderDrawerSelector = createSelector(
  select,
  appState => appState.cancelOrderDrawer
)

export const getOrderDetailsDrawerSelector = createSelector(
  select,
  appState => appState.orderDrawer
)
export const getOptionDrawerSelector = createSelector(
  select,
  appState => appState.optionChainDrawer
)
export const getPlatformConfigsSelector = createSelector(select, indicators => {
  return indicators.config.surveillance_indicator
})

const selectDashboardConfig = (state: TAppStore) =>
  state[SLICE_NAME].config.dashboard_layout

export const getDashboardConfigsSelector = (category: string) =>
  createSelector(
    selectDashboardConfig,
    dashboardConfig => dashboardConfig[category]
  )

export const getSliceConfigSelector = createSelector(select, indicators => {
  return indicators.config?.slice_order_config
})
