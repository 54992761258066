export type TPositionCommonData = {
  realizedPL: number
  exchange: string
  scriptId: string
  product: string
  symbol: string
  lotSize: number
  instrument: string
  optionType: string
  sellQty: number
  buyQty: number
  netQty: number
  netPrice: number
  avgBuyPrice: number
  avgSellPrice: number
  mtm: number
  netValue: number
  positionSide: string
  id: string
  assetClass: string
  closeprice: number
  segment: string
  investedValue: number
  isNonTradable: boolean
  CMOTS_COCODE: string | number
  emarginDate?: string
  securityDescription?: string
}

export type TTodayPosition = TPositionCommonData & {
  avgSellPrice: number
  sellValue: number
  ltp: number
  mtm: number
  displayName: string
}

export type TOpenPosition = TPositionCommonData & {
  date?: string
  displayName: string
}

export type TCumulativePosition = TPositionCommonData & {
  totalSellQuantity: number
  avgTradedPrice: number
  unrealizedPL: number
  overallPL: number
  displayName: string
}

export type TPositionsFilter = {
  stocksFilter: string[]
  assetsFilter: string[]
  productsFilter: string[]
  sortBy: string
}

export type TPositionsFilterLabels = {
  productFilterLabel: string[]
  assetFilterLabel: string[]
  stockFilterLabel: string[]
}

export type TScriptToPositionMapper = {
  [key: string]: {
    netQty: number
    emarginDate: string
    product: string
  }
}

export type TPositionsReducer = {
  openPositionData: TOpenPosition[]
  todayPositionData: TTodayPosition[]
  cumulativePositionsData: TCumulativePosition[]
  totalInvestedAmountForOpenPositions: number
  totalInvestedAmountForTodayPositions: number
  totalInvestedAmountForCumulativePositions: number
  cumulativePositionsTabIndicator: number
  positionsFilter: TPositionsFilter
  isDefaultFiltersEnabled: boolean
  filterLabels: TPositionsFilterLabels
  scriptToPositionMapper: TScriptToPositionMapper
}

export const INITIAL_STATE: TPositionsReducer = {
  openPositionData: [],
  todayPositionData: [],
  cumulativePositionsData: [],
  totalInvestedAmountForOpenPositions: 0,
  totalInvestedAmountForTodayPositions: 0,
  totalInvestedAmountForCumulativePositions: 0,
  cumulativePositionsTabIndicator: 0,
  positionsFilter: {
    stocksFilter: [],
    assetsFilter: [],
    productsFilter: [],
    sortBy: 'PNL_HIGH_TO_LOW'
  },
  isDefaultFiltersEnabled: true,
  filterLabels: {
    productFilterLabel: [],
    assetFilterLabel: [],
    stockFilterLabel: []
  },
  scriptToPositionMapper: {}
}
