import React, { Component } from 'react'
import { DsRadioGroup, DsStack, DsTypography } from '@am92/react-design-system'

import StockTickerNew from '../../TickerBasedComponents/StockTickerNew'
import ExchangeSelection from './ExchangeSelection'

import { holdingDetailsScriptIndexSelector } from '~/src/Redux/Dashboard/Selectors'
import { modifyOrderFormData } from '~/src/Redux/Orders/reducer'
import {
  getCommonIsinCodeScriptIdArray,
  getOrderScriptId,
  getPositionRowIdSelector,
  getSelectedExchange,
  isModifyOrderRequest,
  orderPlacementSectionSelector
} from '~/src/Redux/Orders/selectors'
import { scriptToPositionMapperSelector } from '~/src/Redux/Positions/selectors'
import { T_SCRIPTDATA } from '~/src/Redux/StockMaster/Reducer'
import { getStockMasterData } from '~/src/Redux/StockMaster/Selectors'

import withErrorConnect from '~/src/Lib/withErrorConnect'
// lib
import { IWithRouterProps } from '~/src/Lib/withRouter'
import withStockSubUnSub from '~/src/Lib/withStockSubUnSub'

type ActionTypes = {
  modifyOrderFormData: (requestData: any) => Promise<any>
}

export interface IOrderHeaderBottomProps extends IWithRouterProps {
  actions: ActionTypes
  orderScriptId: string
  commonIsinCodeScriptArray: string[]
  selectedDefaultExchange: string
  isModifyOrder: boolean
  stockDetails: T_SCRIPTDATA
  subscribeLtpData: (scriptIdArray: any[]) => void
  positionData: {
    netQty: number
    emarginDate: string
    product: string
  }
  holdingData: {
    holdingQty: number
    emarginDate: string
  }
  orderPlacementSection: string
}

type IOrderHeaderBottomState = {
  selectedExchange: string
}

class OrderHeaderBottom extends Component<
  IOrderHeaderBottomProps,
  IOrderHeaderBottomState
> {
  //TODO - check if below code can be move to orderheader middle
  componentDidUpdate(prevProps: Readonly<IOrderHeaderBottomProps>): void {
    const {
      actions,
      commonIsinCodeScriptArray,
      orderScriptId,
      subscribeLtpData,
      orderPlacementSection,
      holdingData,
      positionData
    } = this.props
    const {
      commonIsinCodeScriptArray: PREV_COMMON_ISIN_SCRIPT_ID_ARRAY,
      orderScriptId: prevOrderScriptId
    } = prevProps

    if (commonIsinCodeScriptArray !== PREV_COMMON_ISIN_SCRIPT_ID_ARRAY) {
      const scriptArrayToSubscribe = []
      for (let i = 0; i < commonIsinCodeScriptArray.length; i++) {
        const scriptId = commonIsinCodeScriptArray[i]
        if (scriptId.toString() !== orderScriptId.toString()) {
          scriptArrayToSubscribe.push({ scriptId })
        }
      }
      subscribeLtpData(scriptArrayToSubscribe)
    }

    const { holdingQty, emarginDate } = holdingData || {}
    const {
      netQty,
      emarginDate: positionEmarginDate,
      product
    } = positionData || {}

    if (orderScriptId && orderScriptId !== prevOrderScriptId) {
      if (orderPlacementSection === 'holding') {
        actions.modifyOrderFormData({
          emarginDate,
          quantity: holdingQty
        })
      } else if (orderPlacementSection === 'position') {
        if (product === 'EMARGIN') {
          actions.modifyOrderFormData({
            emarginDate: positionEmarginDate,
            quantity: netQty,
            sellableQtyType: 'BMPL'
          })
        } else {
          actions.modifyOrderFormData({
            quantity: netQty
          })
        }
      }
    }
  }

  render() {
    const {
      commonIsinCodeScriptArray,
      isModifyOrder,
      orderScriptId,
      stockDetails,
      selectedDefaultExchange
    } = this.props
    const {
      closeprice = 0,
      exchange = '',
      segment = ''
    } = (stockDetails as T_SCRIPTDATA) || {}

    return (
      <>
        <DsRadioGroup value={selectedDefaultExchange}>
          <DsStack
            sx={{
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
            {(!isModifyOrder &&
              commonIsinCodeScriptArray.length > 1 &&
              commonIsinCodeScriptArray?.map(scriptId => {
                return <ExchangeSelection scriptId={scriptId} key={scriptId} />
              })) || (
              <>
                <DsStack
                  sx={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: 'var(--ds-spacing-glacial)'
                  }}
                >
                  <DsTypography>{exchange}</DsTypography>
                  <StockTickerNew
                    scriptId={orderScriptId}
                    closeprice={closeprice}
                    segment={segment}
                    showNetChange={false}
                    LtpTypoProps={{
                      variant: 'bodyBoldSmall'
                    }}
                  />
                </DsStack>
              </>
            )}
          </DsStack>
        </DsRadioGroup>
      </>
    )
  }
}

const mapStateToProps = (state: any) => {
  const orderScriptId = getOrderScriptId(state)
  const commonIsinCodeScriptArray = getCommonIsinCodeScriptIdArray(state)
  const selectedDefaultExchange = getSelectedExchange(state)
  const isModifyOrder = isModifyOrderRequest(state)
  const stockDetails = getStockMasterData(orderScriptId)(state)
  const positionRowId = getPositionRowIdSelector(state)
  const orderPlacementSection = orderPlacementSectionSelector(state)
  const positionData = scriptToPositionMapperSelector(
    `${orderScriptId}_${positionRowId}`
  )(state)
  const holdingData = holdingDetailsScriptIndexSelector(
    orderScriptId.toString()
  )(state)

  return {
    orderScriptId,
    commonIsinCodeScriptArray,
    selectedDefaultExchange,
    isModifyOrder,
    stockDetails,
    orderPlacementSection,
    positionData,
    holdingData
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    modifyOrderFormData: (requestData: any) =>
      dispatch(modifyOrderFormData(requestData))
  }
})

export default withStockSubUnSub(
  withErrorConnect(mapStateToProps, mapDispatchToProps)(OrderHeaderBottom)
)
