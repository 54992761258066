import React from 'react'
import { DsTypography, DsTypographyProps } from '@am92/react-design-system'

import { getIndividualCurrentValue } from '~/src/Redux/HoldingTicker/Selectors'
import { getCurrentValueUsingHolding } from '~/src/Redux/StockTicker/Selectors'

import { TTickerStore } from '~/src/Configurations/TickerStore'
import { getCurrencyFormat } from '~/src/Utils/global'

import { useTickerSelector } from '~/src/hooks/useRedux'

interface IIndividualHoldingsCurrentValueTickerProps {
  scriptId: string
  holdings?: number | string
  investedValue?: number | string
  closeprice?: number | string
  currentValueTypoProps?: DsTypographyProps
  currentValueNumberFormatOptions?: Intl.NumberFormatOptions
}

const IndividualHoldingsCurrentValueTicker: React.FunctionComponent<
  IIndividualHoldingsCurrentValueTickerProps
> = (props: IIndividualHoldingsCurrentValueTickerProps) => {
  const {
    scriptId,
    holdings = null,
    investedValue,
    closeprice,
    currentValueTypoProps
  } = props

  const currentVal =
    useTickerSelector(
      (state: TTickerStore) =>
        (holdings &&
          getCurrentValueUsingHolding(
            state,
            scriptId,
            holdings,
            investedValue,
            closeprice
          )) ||
        getIndividualCurrentValue(state, scriptId)
    ) || 0

  const getTypoColor = () => {
    return currentVal
      ? currentVal > 0
        ? 'var(--ds-colour-iconPositive)'
        : 'var(--ds-colour-iconNegative)'
      : 'var(--ds-colour-typoPrimary)'
  }

  const getCurrentValue = () => {
    const { currentValueNumberFormatOptions } = props
    const options: Intl.NumberFormatOptions = {
      minimumFractionDigits: 2
    }
    return `${getCurrencyFormat(currentVal, {
      ...options,
      ...currentValueNumberFormatOptions
    })} `
  }
  const typoColor = getTypoColor()

  return (
    <DsTypography
      color={typoColor}
      variant='bodyRegularMedium'
      {...currentValueTypoProps}
    >
      {getCurrentValue()}
    </DsTypography>
  )
}

export default IndividualHoldingsCurrentValueTicker
