import React from 'react'
import {
  DsBox,
  DsButton,
  DsChip,
  DsDialog,
  DsDivider,
  DsImage,
  DsStack,
  DsTypography,
  enqueueNotistack,
  withBreakpoints
} from '@am92/react-design-system'

import { OrderResponse } from './SliceOrderModalBottom'

import {
  changeOrderbookTab,
  changeOrderFormData,
  resetOrderData,
  updateOrderPageModalIndicator
} from '~/src/Redux/Orders/reducer'
import getOrderBookAction, {
  T_GET_ORDER_BOOK_PAYLOAD
} from '~/src/Redux/Orders/Services/getOrderBook.Service'
import { T_UPDATE_ORDER_PAGE_MODAL_PAYLOAD } from '~/src/Redux/Orders/TYPES'

import APP_ROUTES from '~/src/Constants/APP_ROUTES'
import {
  CROSS_ASSET,
  SLICE_ORDER_TRIAL,
  TICK_ASSET
} from '~/src/Constants/ASSET_MAP'
import { TAppDispatch } from '~/src/Configurations/AppStore'
import { getDeviceActiveScreen } from '~/src/Utils/deviceDetails'

import withErrorConnect from '~/src/Lib/withErrorConnect'
import { IWithRouterProps } from '~/src/Lib/withRouter'
import { ORDERBOOK_TAB } from '~/src/Pages/Orderbook/Orderbook.constants'

export interface ISliceOrderStatusModalProps extends IWithRouterProps {
  open: boolean
  breakpoints: any
  orders: OrderResponse[]
  onClose: (item: boolean) => void
  setOrders: (item: []) => void
  handleCloseDetailsModal: () => void
  disableButton: boolean
  actions: ActionTypes
}

type ActionTypes = {
  getOrderBook: (data: T_GET_ORDER_BOOK_PAYLOAD) => Promise<any>
  updateOrderPageModalIndicator: (
    requestData: T_UPDATE_ORDER_PAGE_MODAL_PAYLOAD
  ) => Promise<any>
  resetOrderData: (requestData: string) => Promise<any>
  changeOrderFormData: (payload: { key: string; data: any }) => Promise<any>
  changeOrderbookTab: (orderbookTab: string) => Promise<any>
}

const SliceOrderStatusModal: React.FC<ISliceOrderStatusModalProps> = props => {
  const {
    open,
    onClose,
    breakpoints = {},
    orders,
    setOrders,
    navigateTo,
    disableButton,
    handleCloseDetailsModal,
    actions
  } = props

  const { isDesktop } = getDeviceActiveScreen(breakpoints)

  const handleClose = () => {
    setOrders([])
    onClose(false)
  }

  const handleNavigateToOrderbook = () => {
    handleClose()
    handleCloseDetailsModal()
    handleCloseOrderForm()

    if (location.pathname === APP_ROUTES.ORDERBOOK.pathname) {
      fetchOrderBook()
    } else {
      navigateTo(APP_ROUTES.ORDERBOOK.pathname)
    }

    actions.resetOrderData('resetOrderData')
    actions.changeOrderbookTab(ORDERBOOK_TAB.Orders.value)
  }

  const handleCloseOrderForm = () => {
    actions.updateOrderPageModalIndicator({
      orderPageModalIndicator: false,
      orderScriptId: '',
      orderBuyOrSell: '',
      isModifyOrderRequest: false,
      isRepeatOrderRequest: false
    })
  }

  const fetchOrderBook = async () => {
    const requestPayload: T_GET_ORDER_BOOK_PAYLOAD = {
      segment: 'EQ',
      omsOrderId: '',
      orderStatus: '-1'
    }

    const getOrderBookResponse = await actions.getOrderBook(requestPayload)
    if (getOrderBookResponse?._isCustomError) {
      enqueueNotistack({
        message: 'Order book API Failed.',
        autoHideDuration: 2000
      })
      console.log('failed API - getOrderBook')
      return
    }
  }

  const renderStatusImage = (status: string) => {
    if (status === 'SUCCESS') {
      return <DsImage srcSet={TICK_ASSET} />
    }
    return <DsImage srcSet={CROSS_ASSET} />
  }

  const renderStatus = (status: string) => {
    if (status === 'SUCCESS') {
      return (
        <DsChip label={status} variant='filled' type='nudge' color='success' />
      )
    }
    return (
      <DsChip label={'Rejected'} variant='filled' type='nudge' color='error' />
    )
  }

  return (
    <>
      <DsDialog
        sx={{
          justifyContent: 'space-between',
          '.MuiPaper-root': {
            borderRadius: {
              xs: 'var(--ds-spacing-zero)',
              md: 'var(--ds-spacing-bitterCold)',
              width: isDesktop ? '480px' : '100%'
            },
            '& .MuiStack-root': {},
            '.MuiDialogContent-root': {
              marginTop: 'var(--ds-spacing-quickFreeze)',
              height: '33rem',
              pb: '72px',
              overflow: 'scroll'
            }
          }
        }}
        keepMounted={false}
        showClose={false}
        // onClose={handleClose}
        fullScreen={!isDesktop}
        open={open}
      >
        <DsBox
          sx={{
            width: '100%',
            border: '1px solid var(--ds-colour-strokeDefault)',
            borderRadius: 'var(--ds-radius-glacial)',
            p: 'var(--ds-spacing-bitterCold)'
          }}
        >
          <DsBox
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%'
            }}
          >
            <DsTypography variant='bodyBoldLarge'>Order Status </DsTypography>
            <DsTypography variant='bodyRegularMedium'>
              {`No.of orders:- ${orders.length}`}
            </DsTypography>
          </DsBox>
          <DsDivider sx={{ width: '100%', marginY: '1rem' }} />
          {orders?.map(order => {
            const { status, orderNumber, quantity } = order

            return (
              <DsStack py='1rem' gap='4px' key={orderNumber}>
                <DsBox
                  sx={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <DsBox
                    sx={{
                      display: 'grid',
                      gridTemplateColumns: 'auto 60px',
                      gap: '8px',
                      alignItems: 'center'
                    }}
                  >
                    <DsBox sx={{ position: 'relative' }}>
                      {renderStatusImage(status)}
                      {orderNumber < orders.length && (
                        <DsImage
                          style={{
                            position: 'absolute',
                            bottom: '-44px',
                            left: '8px'
                          }}
                          srcSet={SLICE_ORDER_TRIAL}
                        />
                      )}
                    </DsBox>
                    <DsBox sx={{ display: 'flex', alignItems: 'start' }}>
                      <DsTypography variant='bodyRegularMedium'>
                        {`Order ${orderNumber}`}
                      </DsTypography>
                    </DsBox>
                  </DsBox>
                  {renderStatus(status)}
                </DsBox>
                <DsTypography paddingLeft='28px' variant='bodyRegularSmall'>
                  Quantity: {quantity.toLocaleString()}
                </DsTypography>
              </DsStack>
            )
          })}
        </DsBox>
        <DsBox
          sx={{
            position: 'absolute',
            bottom: '0',
            left: '0',
            width: '100%',
            height: 'var(--ds-spacing-superheated)',
            background: 'var(--ds-colour-surfaceBackground)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 'var(--ds-spacing-bitterCold)',
            gap: 'var(--ds-spacing-bitterCold)'
          }}
        >
          <DsButton
            onClick={handleNavigateToOrderbook}
            fullWidth
            color='primary'
            size='medium'
            disabled={disableButton}
          >
            Order Book
          </DsButton>
          <DsButton
            onClick={handleClose}
            fullWidth
            color='secondary'
            size='medium'
            disabled={disableButton}
          >
            Repeat Order
          </DsButton>
        </DsBox>
      </DsDialog>
    </>
  )
}

const mapStateToProps = () => {
  return {}
}
const mapDispatchToProps = (dispatch: TAppDispatch) => ({
  actions: {
    getOrderBook: (data: T_GET_ORDER_BOOK_PAYLOAD) =>
      dispatch(getOrderBookAction(data)),
    updateOrderPageModalIndicator: (
      requestData: T_UPDATE_ORDER_PAGE_MODAL_PAYLOAD
    ) => dispatch(updateOrderPageModalIndicator(requestData)),
    resetOrderData: (requestData: any) => dispatch(resetOrderData(requestData)),
    changeOrderbookTab: (orderbookTab: string) =>
      dispatch(changeOrderbookTab(orderbookTab)),
    changeOrderFormData: (payload: { key: string; data: any }) =>
      dispatch(changeOrderFormData(payload))
  }
})

export default withBreakpoints(
  withErrorConnect(mapStateToProps, mapDispatchToProps)(SliceOrderStatusModal)
)
