// NOTE: CLEANUP: @pratik
// 1. check EmarginInfoModal component props

import React, { Component } from 'react'
import {
  DsButton,
  DsCheckbox,
  DsRemixIcon,
  DsStack,
  DsTypography
} from '@am92/react-design-system'
import { FormikErrors } from 'formik'

import EmarginInfoModal from '../../Emargin/EmarginInfoModal'
import EmarginModal from '../../Emargin/EmarginModal'
import BnplValue from './BnplValue'
import { FormValues } from './OrderFormSection'

import { resetEmarginData } from '~/src/Redux/Customer/Reducer'
import {
  getEMarginDetails,
  isEmarginActive
} from '~/src/Redux/Customer/Selectors'
import getEmarginDetailsAction, {
  getEmarginDetailsServiceName,
  T_GET_E_MARGIN_PAYLOAD
} from '~/src/Redux/Customer/Services/getEmarginDetails.Service'
import { getServiceSelector } from '~/src/Redux/ServiceTracker/Selectors'
import { T_SCRIPTDATA } from '~/src/Redux/StockMaster/Reducer'

import { TAppDispatch, TAppStore } from '~/src/Configurations/AppStore'
import { triggerCleverTapEvent } from '~/src/Utils/global'

import withErrorConnect from '~/src/Lib/withErrorConnect'
// lib
import { IWithRouterProps } from '~/src/Lib/withRouter'

type ActionTypes = {
  getEmarginDetails: (reqData: T_GET_E_MARGIN_PAYLOAD) => Promise<any>
  resetEmarginData: (requestData: string) => Promise<any>
}

export interface IBnplSectionProps extends IWithRouterProps {
  actions: ActionTypes
  orderType: string
  orderBuyOrSell: string
  isEmarginActiveFlag: boolean
  stockDetails: T_SCRIPTDATA
  orderScriptId: string
  handleError: (res: any) => void
  eMarginPercentage: number
  hasError: boolean
  isEmarginPreviledged: boolean
  bnpl: boolean
  quantity: number
  price: number
  marketDepth: string
  handleChange: (e: React.ChangeEvent<any>) => void
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean
  ) => Promise<void | FormikErrors<FormValues>>
  isModifyOrder: boolean
}

class BnplSection extends Component<IBnplSectionProps> {
  state = {
    showEmarginInfoModal: false,
    showActicateBNPLModal: false,
    forceRender: false
  }

  async componentDidMount(): Promise<void> {
    const { orderScriptId } = this.props
    if (orderScriptId) {
      this.getEmarginDetails()
    }
  }

  async componentDidUpdate(
    prevProps: Readonly<IBnplSectionProps>
  ): Promise<void> {
    const {
      orderScriptId,
      actions,
      isEmarginPreviledged,
      quantity,
      marketDepth,
      price
    } = this.props
    const {
      orderScriptId: PREV_SCRIPT_ID,
      isEmarginPreviledged: PREV_EMARGIN_PREV,
      quantity: prevQuantity,
      marketDepth: prevMarketDepth,
      price: prevPrice
    } = prevProps
    const { forceRender } = this.state
    if (orderScriptId !== PREV_SCRIPT_ID) {
      this.setState({ forceRender: !forceRender })
      await actions.resetEmarginData('reset')
      if (orderScriptId) {
        this.getEmarginDetails()
      }
    }
    if (
      isEmarginPreviledged !== PREV_EMARGIN_PREV ||
      this.isQuantityChanged(quantity, prevQuantity) ||
      marketDepth !== prevMarketDepth ||
      price !== prevPrice
    ) {
      this.setState({ forceRender: !forceRender })
    }
  }
  isQuantityChanged(quantity: number, prevQuantity: number) {
    if (Number.isNaN(quantity) && Number.isNaN(prevQuantity)) {
      return false
    }

    return quantity !== prevQuantity
  }
  componentWillUnmount(): void {
    const { setFieldValue } = this.props
    setFieldValue('bnpl', false)
  }

  setShowEmarginInfoModal = () => {
    const { showEmarginInfoModal } = this.state
    this.setState({ showEmarginInfoModal: !showEmarginInfoModal })
  }

  setShowActivateBNPLModal = () => {
    const { showActicateBNPLModal } = this.state
    this.setState({ showActicateBNPLModal: !showActicateBNPLModal })
  }

  getEmarginDetails = async () => {
    const { actions, orderScriptId } = this.props
    const requestData: T_GET_E_MARGIN_PAYLOAD = {
      scriptId: orderScriptId.toString()
    }
    const getEmarginDetailsResponse =
      await actions.getEmarginDetails(requestData)
    if (getEmarginDetailsResponse._isCustomError) {
      console.log('failed API - getEmarginDetails')
      // handleError(getEmarginDetailsResponse)
      this.setState({ apiError: true })
      return
    }
  }

  handleBMPLClicked = () => {
    const customAttributes = {
      Event_Status: 'Pass',
      Screen_Name: 'Transaction_Emargin_Nudge',
      Action: 'BUY'
    }
    triggerCleverTapEvent('Order', customAttributes)
  }

  render() {
    const {
      orderType,
      orderBuyOrSell,
      isEmarginActiveFlag,
      stockDetails,
      orderScriptId,
      eMarginPercentage,
      hasError,
      isEmarginPreviledged,
      bnpl,
      handleChange,
      isModifyOrder,
      quantity,
      marketDepth,
      price
    } = this.props
    const { segment = '', closeprice = 0 } = stockDetails || {}
    const { showEmarginInfoModal, showActicateBNPLModal, forceRender } =
      this.state
    const isBUY = orderBuyOrSell === 'BUY'
    const orderColor = isBUY
      ? 'var(--ds-colour-supportPositive) !important'
      : 'var(--ds-colour-supportNegative) !important'
    const buttonColor = isBUY ? 'success' : 'error'
    const isDeliveryOrder = orderType === 'DELIVERY' || orderType === 'MTF'
    const isEquitySegment = segment === 'EQ'
    const options: Intl.NumberFormatOptions = {
      minimumFractionDigits: segment === 'CURR' ? 4 : 2
    }
    const doNotShowBnplInModify = isModifyOrder && !isEmarginActiveFlag
    const showBnplSection =
      isEmarginPreviledged &&
      isEquitySegment &&
      isBUY &&
      isDeliveryOrder &&
      !hasError &&
      !doNotShowBnplInModify

    return (
      <>
        {showBnplSection && (
          <>
            <DsStack
              sx={{
                flexDirection: 'column',
                p: {
                  xs: 'var(--ds-spacing-glacial) var(--ds-spacing-bitterCold)',
                  md: 'var(--ds-spacing-frostbite) var(--ds-spacing-mild)'
                },
                gap: 'var(--ds-spacing-glacial)',
                backgroundColor: 'var(--ds-colour-neutral2)'
              }}
            >
              <DsStack
                sx={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: 'var(--ds-spacing-quickFreeze)'
                }}
              >
                <DsStack
                  sx={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: 'var(--ds-spacing-glacial)'
                  }}
                >
                  {(isEmarginActiveFlag && (
                    <DsCheckbox
                      checked={bnpl}
                      disabled={isModifyOrder}
                      onChange={handleChange}
                      onClick={this.handleBMPLClicked}
                      name='bnpl'
                      sx={{
                        padding: 'var(--ds-spacing-zero)',
                        '&.Mui-checked': {
                          color: orderColor
                        }
                      }}
                    />
                  )) ||
                    null}
                  <DsTypography variant='bodyRegularSmall'>
                    Use BMPL & Pay{' '}
                    <BnplValue
                      scriptId={orderScriptId}
                      closeprice={closeprice}
                      forceRender={forceRender}
                      quantity={quantity}
                      marketDepth={marketDepth}
                      price={price}
                      eMarginPercentage={eMarginPercentage}
                      options={options}
                      isEmarginActiveFlag={isEmarginActiveFlag}
                    />
                  </DsTypography>
                  {(!isEmarginActiveFlag && (
                    <DsTypography variant='bodyRegularSmall'>
                      for the same quantity
                    </DsTypography>
                  )) ||
                    null}
                </DsStack>
                <DsRemixIcon
                  onClick={() => {
                    this.setState({ showEmarginInfoModal: true })
                  }}
                  className='ri-information-line'
                  fontSize='bitterCold'
                />
              </DsStack>
              {(!isEmarginActiveFlag && (
                <DsButton
                  variant='text'
                  color={buttonColor}
                  sx={{
                    justifyContent: 'flex-start',
                    padding: 'var(--ds-spacing-zero)'
                  }}
                  onClick={() => {
                    this.setState({ showActicateBNPLModal: true })
                    const customAttributes = {
                      Event_Status: 'Pass',
                      Screen_Name: 'Transaction_Emargin_Nudge',
                      Action: 'Activate'
                    }
                    triggerCleverTapEvent('Order', customAttributes)
                  }}
                >
                  Activate
                </DsButton>
              )) ||
                null}
            </DsStack>

            <EmarginModal
              showActivateBNPLModal={showActicateBNPLModal}
              setShowActivateBNPLModal={this.setShowActivateBNPLModal}
            />

            <EmarginInfoModal
              showEmarginInfoModal={showEmarginInfoModal}
              setShowEmarginInfoModal={this.setShowEmarginInfoModal}
            />
          </>
        )}
      </>
    )
  }
}

const mapStateToProps = (state: TAppStore) => {
  const isEmarginActiveFlag = isEmarginActive(state)
  const { eMarginPercentage, isEmarginPreviledged } = getEMarginDetails(state)
  const serviceDetails = getServiceSelector(state, getEmarginDetailsServiceName)
  const hasError = serviceDetails === 'ERROR'

  return {
    isEmarginActiveFlag,
    eMarginPercentage,
    hasError,
    isEmarginPreviledged
  }
}

const mapDispatchToProps = (dispatch: TAppDispatch) => ({
  actions: {
    getEmarginDetails: (reqData: T_GET_E_MARGIN_PAYLOAD) =>
      dispatch(getEmarginDetailsAction(reqData)),
    resetEmarginData: (requestData: string) =>
      dispatch(resetEmarginData(requestData))
  }
})

export default withErrorConnect(
  mapStateToProps,
  mapDispatchToProps
)(BnplSection)
