import { createSelector } from '@reduxjs/toolkit'
import { TAppStore } from '~/src/Configurations/AppStore'
export const SLICE_NAME = 'sortModel'

const select = (state: TAppStore) => state[SLICE_NAME]
const selectSortModel = (state: TAppStore, sectionName: string) =>
  select(state)?.[sectionName]

export const getSortValues = createSelector(selectSortModel, sortModel => {
  const { sortBy, sortDirection } = sortModel
  return {
    sortBy,
    sortDirection
  }
})
