import React from 'react'
import {
  DsBox,
  DsDivider,
  DsStack,
  DsTab,
  DsTabs
} from '@am92/react-design-system'

export interface IPortfolioTabsProps {
  value: string
  onChange: (event: any, value: string) => any
}

export const PORTFOLIO_TAB_OPTIONS = {
  Portfolio: { label: 'Portfolio', value: 'Portfolio', isActive: true },
  Positions: {
    label: 'Positions',
    value: 'Positions',
    isActive: true
  }
}
export const PORTFOLIO_TAB_OPTIONS_LIST = Object.values(PORTFOLIO_TAB_OPTIONS)

export default class PortfolioTabs extends React.PureComponent<IPortfolioTabsProps> {
  render() {
    const { value, onChange } = this.props
    return (
      <DsStack
        sx={{
          pt: 'var(--ds-spacing-glacial)'
        }}
      >
        <DsBox
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <DsTabs
            sx={{ width: { xs: '100%', md: '70%' } }}
            textColor='secondary'
            indicatorColor='secondary'
            TabIndicatorProps={{
              sx: { height: 'var(--ds-spacing-deepFreeze)' }
            }}
            value={value}
            onChange={onChange}
          >
            {PORTFOLIO_TAB_OPTIONS_LIST.map((tab, index) => {
              const { label, value, isActive } = tab
              return (
                <DsTab
                  disabled={!isActive}
                  key={`${label}-${index}`}
                  sx={{
                    textTransform: 'none',
                    width: { xs: '50%', md: '40%' }
                  }}
                  label={label}
                  value={value}
                />
              )
            })}
          </DsTabs>
        </DsBox>
        <DsDivider sx={{ width: '100%' }} />
      </DsStack>
    )
  }
}
