import React, { Component } from 'react'

// lib
import { IWithRouterProps } from '~/src/Lib/withRouter'
import withErrorConnect from '~/src/Lib/withErrorConnect'
import {
  DsBottomSheet,
  DsDrawer,
  DsRemixIcon,
  DsStack,
  DsTypography
} from '@am92/react-design-system'
import { FormValues } from './OrderFormSection'
import getTaxAndChargesAction, {
  T_GET_TAX_CHARGES_PAYLOAD
} from '~/src/Redux/Orders/Services/getTaxAndCharges.Service'
import { T_SCRIPTDATA } from '~/src/Redux/StockMaster/Reducer'
import ViewChargesContent from './ViewChargesContent'
import { tickerValue, triggerCleverTapEvent } from '~/src/Utils/global'

type ActionTypes = {
  getTaxAndChargesOnOrder: (reqData: T_GET_TAX_CHARGES_PAYLOAD) => Promise<any>
}

export interface IViewTaxChargesProps extends IWithRouterProps {
  actions: ActionTypes
  values: FormValues
  stockDetails: T_SCRIPTDATA
  scriptId: string
  handleError: (res: any) => void
  orderBuyOrSell: string
  marketDepth: string
}

type IViewTaxChargesState = {
  openViewTaxChargesModal: boolean
}

class ViewTaxCharges extends Component<
  IViewTaxChargesProps,
  IViewTaxChargesState
> {
  constructor(props: IViewTaxChargesProps) {
    super(props)
    this.state = {
      openViewTaxChargesModal: false
    }
  }

  async componentDidUpdate(
    prevProps: Readonly<IViewTaxChargesProps>,
    prevState: Readonly<IViewTaxChargesState>
  ): Promise<void> {
    const { openViewTaxChargesModal } = this.state
    const { openViewTaxChargesModal: PREV_VIEW_TAX_CHARGES_MODAL } = prevState
    const { scriptId, values } = this.props
    const { values: PREV_VALUES } = prevProps
    const { quantity, price, marketDepth } = values
    const {
      price: prevPrice,
      quantity: prevQnty,
      marketDepth: prevMarketDepth
    } = PREV_VALUES
    const { scriptId: PREV_ORDERSCRIPTID = '' } = prevProps
    if (scriptId && PREV_ORDERSCRIPTID !== scriptId) {
      this.setState({ openViewTaxChargesModal: false })
    }
    if (
      openViewTaxChargesModal &&
      (Number(quantity) !== Number(prevQnty) ||
        Number(price) !== Number(prevPrice) ||
        marketDepth !== prevMarketDepth ||
        openViewTaxChargesModal !== PREV_VIEW_TAX_CHARGES_MODAL)
    ) {
      this.getTaxAndCharges()
    }
  }

  handleOpenCloseViewTaxChargesModal = () => {
    const { openViewTaxChargesModal } = this.state
    const customAttributes = {
      Event_Status: 'Pass',
      Screen_Name: 'Transaction_Initiated',
      Action: 'View_Tax_And_Charges'
    }
    triggerCleverTapEvent('Order', customAttributes)
    this.setState({ openViewTaxChargesModal: !openViewTaxChargesModal })
  }

  getTaxAndCharges = async () => {
    const {
      actions,
      handleError,
      values,
      stockDetails,
      scriptId,
      orderBuyOrSell
    } = this.props
    const { quantity, price, orderType, marketDepth } = values
    const { segment, lotsize, closeprice } = stockDetails || {}
    const isSegmentEquity = segment === 'EQ'
    const isMarketDepthMarket = marketDepth === 'market'
    const orderQuantity =
      (isSegmentEquity && Number(quantity)) ||
      Number(quantity) * Number(lotsize)
    const orderPrice =
      (isMarketDepthMarket && Number(tickerValue(scriptId) || closeprice)) ||
      Number(price)
    const orderValue = orderQuantity * orderPrice

    const requestData: T_GET_TAX_CHARGES_PAYLOAD = {
      scriptId: scriptId.toString(),
      orderQuantity,
      orderValue,
      segment,
      productType: orderType,
      transactionType: orderBuyOrSell
    }

    const getTaxAndChargesOnOrderResponse =
      await actions.getTaxAndChargesOnOrder(requestData)
    if (getTaxAndChargesOnOrderResponse._isCustomError) {
      // handleError(getTaxAndChargesOnOrderResponse)
      console.log('failed API - getTaxAndChargesOnOrder')
      return
    }
  }

  render() {
    const { openViewTaxChargesModal } = this.state
    const { values, stockDetails, marketDepth, scriptId } = this.props
    const { coname, exchangeSymbol, segment, lotsize } = stockDetails || {}
    const { quantity, price, orderType, bnpl } = values
    const isSegmentEquity = segment === 'EQ'
    const scriptName = isSegmentEquity ? exchangeSymbol : coname
    const productType = bnpl ? 'Delivery | BMPL' : orderType
    const orderQuantity =
      (isSegmentEquity && Number(quantity)) ||
      Number(quantity) * Number(lotsize)

    return (
      <>
        <DsTypography
          variant='bodyRegularSmall'
          sx={{
            textDecoration: 'underline',
            textDecorationStyle: 'dotted',
            padding: {
              md: 'var(--ds-spacing-zero) var(--ds-spacing-mild)',
              xs: 'var(--ds-spacing-zero) var(--ds-spacing-bitterCold)'
            },
            cursor: 'pointer',
            marginBottom: 'var(--ds-spacing-plasma) !important'
          }}
          onClick={this.handleOpenCloseViewTaxChargesModal}
        >
          View tax & charges
        </DsTypography>

        <DsDrawer
          PaperProps={{
            sx: {
              top: '250px',
              zIndex: '10',
              boxShadow: '0px 16px 24px 0px rgba(0, 0, 0, 0.08)',
              width: '460px',
              left: '40%',
              borderTop: 'unset',
              borderRadius:
                'var(--ds-spacing-bitterCold) var(--ds-spacing-zero) var(--ds-spacing-zero) var(--ds-spacing-zero)'
            }
          }}
          anchor='bottom'
          open={openViewTaxChargesModal}
          variant='persistent'
          hideBackdrop
          sx={{
            display: {
              xs: 'none',
              md: 'block'
            }
          }}
          ModalProps={{
            keepMounted: false
          }}
        >
          <DsStack
            sx={{
              padding: 'var(--ds-spacing-mild)'
            }}
            gap={3}
          >
            <DsStack
              sx={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <DsTypography variant='headingBoldSmall'>
                Tax & Charges
              </DsTypography>
              <DsRemixIcon
                onClick={this.handleOpenCloseViewTaxChargesModal}
                className='ri-close-fill'
                fontSize='mild'
                sx={{
                  cursor: 'pointer'
                }}
              />
            </DsStack>
            {openViewTaxChargesModal && (
              <ViewChargesContent
                scriptName={scriptName}
                coname={coname}
                orderType={productType}
                quantity={orderQuantity}
                price={price}
                marketDepth={marketDepth}
                scriptId={scriptId}
                openViewTaxChargesModal={openViewTaxChargesModal}
                stockDetails={stockDetails}
              />
            )}
          </DsStack>
        </DsDrawer>

        <DsBottomSheet
          open={openViewTaxChargesModal}
          sx={{
            display: {
              xs: 'block',
              md: 'none'
            }
          }}
          onClose={this.handleOpenCloseViewTaxChargesModal}
          title='Tax & Charges'
        >
          <DsStack gap={3}>
            {openViewTaxChargesModal && (
              <ViewChargesContent
                coname={scriptName}
                orderType={orderType}
                quantity={orderQuantity}
                marketDepth={marketDepth}
                scriptId={scriptId}
                price={price}
                openViewTaxChargesModal={openViewTaxChargesModal}
                stockDetails={stockDetails}
              />
            )}
          </DsStack>
        </DsBottomSheet>
      </>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {}
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    getTaxAndChargesOnOrder: (reqData: T_GET_TAX_CHARGES_PAYLOAD) =>
      dispatch(getTaxAndChargesAction(reqData))
  }
})

export default withErrorConnect(
  mapStateToProps,
  mapDispatchToProps
)(ViewTaxCharges)
