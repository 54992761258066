import React from 'react'
import {
  DsBox,
  DsImage,
  DsStack,
  DsTypography
} from '@am92/react-design-system'

import { CUSTOMERSUPPORT } from '~/src/Constants/ASSET_MAP'

const Maintenance = () => {
  return (
    <DsStack
      bgcolor='var(--ds-colour-surfacePrimary)'
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: 'var(--ds-spacing-mild)',
        textAlign: 'center',
        height: { md: '100vh', xs: '80vh' }
      }}
    >
      <DsBox>
        <DsImage srcSet={CUSTOMERSUPPORT} />
      </DsBox>
      <DsTypography variant='headingBoldLarge'>
        Service Under Maintenance
      </DsTypography>
      <DsTypography variant='bodyRegularLarge'>
        We are upgrading our systems, access to the application will be
        temporarily unavailable.
      </DsTypography>
      <DsTypography variant='bodyRegularLarge'>
        We regret for the inconvienence
      </DsTypography>
    </DsStack>
  )
}

export default Maintenance
