{
  "name": "invest-axis-direct",
  "version": "2.1.1",
  "description": "",
  "scripts": {
    "lint": "tsc --noEmit && eslint src/* --quiet",
    "lint:fix": "eslint src/* --fix --quiet",
    "test": "echo \"Error: no test specified\" && exit 1",
    "analyze": "npm run clean-and-copy && parcel build public/index.ejs public/version.ejs --dist-dir build --reporter @parcel/reporter-bundle-analyzer",
    "start": "npm run clean-and-copy && parcel public/index.ejs --dist-dir build --open chrome --port 3000",
    "build": "npm run clean-and-copy && parcel build public/index.ejs public/version.ejs --dist-dir build",
    "clean-and-copy": "rm -rf build .parcel-cache && npm run copy",
    "copy": "mkdir build && mkdir build/static && cp -r public/static/ build/static/ && cp public/robots.txt build/ && cp -r public/.well-known build/",
    "prepare": "husky"
  },
  "keywords": [],
  "author": "",
  "license": "ISC",
  "dependencies": {
    "@am92/react-design-system": "2.7.0",
    "@am92/web-http": "^2.0.25",
    "@chartiq/react-components": "9.0.0",
    "@emotion/react": "^11.11.1",
    "@emotion/styled": "^11.11.0",
    "@mui/x-data-grid": "^6.16.2",
    "@parcel/service-worker": "^2.9.2",
    "@reduxjs/toolkit": "^1.9.5",
    "@types/lodash": "^4.14.202",
    "bowser": "^2.11.0",
    "chartiq": "9.0.0",
    "cosmiconfig": "8.2.0",
    "date-fns": "^2.30.0",
    "file-saver": "^2.0.5",
    "formik": "^2.4.5",
    "fuzzysort": "2.0.4",
    "global": "^4.4.0",
    "jszip": "^3.10.1",
    "localforage": "^1.10.0",
    "localstorage-slim": "^2.4.0",
    "lodash": "^4.17.21",
    "lottie-react": "^2.4.0",
    "notistack": "^3.0.1",
    "parcel-transformer-papaparse": "^0.1.0",
    "react": "^18.2.0",
    "react-beautiful-dnd": "^13.1.1",
    "react-dom": "^18.2.0",
    "react-fast-marquee": "^1.6.4",
    "react-number-format": "^5.3.3",
    "react-redux": "^8.1.0",
    "react-router-dom": "^6.12.1",
    "redux": "^4.2.1",
    "redux-persist": "^6.0.0",
    "serve": "^14.2.1",
    "socket.io-client": "^4.8.0",
    "styled-components": "^5.3.11",
    "utility-types": "^3.11.0",
    "uuid": "^9.0.0",
    "yup": "^1.3.3"
  },
  "devDependencies": {
    "@parcel/config-default": "2.9.3",
    "@parcel/core": "2.9.3",
    "@parcel/optimizer-data-url": "2.9.3",
    "@parcel/optimizer-terser": "2.9.3",
    "@parcel/packager-raw-url": "2.9.3",
    "@parcel/packager-xml": "2.9.3",
    "@parcel/reporter-bundle-analyzer": "2.9.3",
    "@parcel/resolver-default": "2.9.3",
    "@parcel/transformer-inline-string": "2.9.3",
    "@parcel/transformer-raw": "2.9.3",
    "@parcel/transformer-sass": "2.9.3",
    "@types/node": "^20.3.1",
    "@types/react": "^18.2.12",
    "@types/react-beautiful-dnd": "^13.1.4",
    "@types/react-dom": "^18.2.5",
    "@types/uuid": "^9.0.2",
    "@typescript-eslint/eslint-plugin": "8.16.0",
    "@typescript-eslint/parser": "8.16.0",
    "assert": "^2.1.0",
    "buffer": "^6.0.3",
    "console-browserify": "^1.2.0",
    "custom-env": "^2.0.2",
    "eslint": "8.56.0",
    "eslint-config-prettier": "9.1.0",
    "eslint-plugin-import": "2.29.1",
    "eslint-plugin-jsonc": "2.13.0",
    "eslint-plugin-prettier": "5.1.3",
    "eslint-plugin-react": "7.33.2",
    "eslint-plugin-simple-import-sort": "12.0.0",
    "eslint-plugin-unused-imports": "3.2.0",
    "events": "^3.3.0",
    "husky": "^9.1.7",
    "lint-staged": "^15.2.10",
    "os-browserify": "^0.3.0",
    "parcel": "2.9.3",
    "parcel-namer-rewrite": "2.0.0-rc.3",
    "parcel-transformer-ejs": "^1.0.1",
    "path-browserify": "^1.0.1",
    "prettier": "^3.2.5",
    "process": "^0.11.10",
    "prop-types": "^15.8.1",
    "react-jsx": "^1.0.0",
    "typescript": "^5.3.3",
    "util": "^0.12.3"
  },
  "parcel-namer-rewrite": {
    "profiles": {
      "development": {
        "disable": true
      },
      "production": {
        "chain": "@parcel/namer-default",
        "rules": {
          "(.*).css$": "styles/$1{.hash}.css",
          "service-worker.js": "service-worker.js",
          "public(.*).webmanifest": "manifest{.hash}.webmanifest",
          "public(.*).xml": "browserconfig{.hash}.xml",
          "public(.*).webp": "assets/favicons/favicon{.hash}.webp",
          "public(.*).png": "assets/favicons/favicon{.hash}.png",
          "(.*).js$": "scripts/$1{.hash}.js",
          "(.*).png$": "assets/images/$1{.hash}.png",
          "(.*).webp$": "assets/images/$1{.hash}.webp",
          "(.*).jpg$": "assets/images/$1{.hash}.jpg",
          "(.*).jpeg$": "assets/images/$1{.hash}.jpeg",
          "(.*).svg$": "assets/images/$1{.hash}.svg",
          "(.*).gif$": "assets/images/$1{.hash}.gif",
          "(.*).avif$": "assets/images/$1{.hash}.avif",
          "version.html": "version.json"
        }
      }
    }
  },
  "@parcel/resolver-default": {
    "packageExports": true
  }
}
