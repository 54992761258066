import { CROSS_ASSET, TICK_ASSET } from '~/src/Constants/ASSET_MAP'
import { ORDERS } from '~/src/Constants/LABEL'

export const FILTER_DATA = [
  { name: 'All' },
  { name: 'Executed' },
  { name: 'Pending' },
  { name: 'Unsuccessful' }
]

type T_STATUS_MAPPER = {
  [key: string]: string
}

export const STATUS_MAPPER: T_STATUS_MAPPER = {
  PENDING: 'OPEN',
  EXECUTED: 'EXECUTED',
  OMSXMITTED: 'TRANSIT',
  CANCELLED: 'CANCELLED',
  REJECTED: 'REJECTED',
  AMOACCEPTED: 'OPEN'
}

type T_STATUS_FILTER_MAPPER = {
  [key: string]: string
}

export const STATUS_FILTER_MAPPER: T_STATUS_FILTER_MAPPER = {
  PENDING: 'Pending',
  EXECUTED: 'Executed',
  OMSXMITTED: 'Pending', // to ask kashish
  CANCELLED: 'Unsuccessful',
  REJECTED: 'Unsuccessful',
  AMOACCEPTED: 'Pending'
}

export const ORDER_DATA = [
  [
    ['TATAPOWER', 'NSE'],
    ['BUY', 'LONGTERM | SL'],
    '₹90,809.35',
    '0/10',
    '₹90,809.35',
    '2 mins ago',
    'EXECUTED'
  ],
  [
    ['AXISBANK', 'BSE'],
    ['BUY', 'LONGTERM | SL'],
    '₹90,809.35',
    '0/10',
    '₹70,809.35',
    '2 mins ago',
    'OPEN'
  ],
  [
    ['SBI', 'NSE'],
    ['BUY', 'LONGTERM | SL'],
    '₹90,809.35',
    '0/10',
    '₹70,809.35',
    '2 mins ago',
    'REJECTED'
  ],
  [
    ['ICICIBANK', 'NSE'],
    ['SELL', 'LONGTERM | SL'],
    '₹90,809.35',
    '0/10',
    '₹70,809.35',
    '2 mins ago',
    'EXECUTED'
  ]
]
export const MOCK_DATA = [
  {
    id: 0,
    scriptName: 'AATAPOWER',
    term: 'LONG TERM | SL',
    termOnly: 'LONG TERM',
    sl: 'SL',
    exchangeName: 'NSE',
    type: 'BUY',
    lowTradingPrice: '₹90,100.35',
    quantity: 20,
    avgPrice: '₹90,200.35',
    createdOn: '16:32:09',
    status: 'Open'
  },
  {
    id: 1,
    exchangeName: 'NSE',
    scriptName: 'EATAPOWER',
    termOnly: 'LONG TERM',
    sl: 'SL',
    term: 'LONG TERM | SL',
    type: 'BUY',
    lowTradingPrice: '₹90,000.35',
    quantity: 10,
    avgPrice: '₹90,300.35',
    createdOn: '16:32:05',
    status: 'Executed'
  },
  {
    id: 2,
    exchangeName: 'NSE',
    scriptName: 'DATASTEEL',
    term: 'LONG TERM | SL',
    termOnly: 'LONG TERM',
    sl: 'SL',
    type: 'BUY',
    lowTradingPrice: '₹90,800.35',
    quantity: 50,
    avgPrice: '₹90,000.35',
    createdOn: '16:32:01',
    status: 'Rejected'
  },
  {
    id: 4,
    exchangeName: 'NSE',
    scriptName: 'CATASTEEL',
    term: 'LONG TERM | SL',
    termOnly: 'LONG TERM',
    sl: 'SL',
    type: 'BUY',
    lowTradingPrice: '₹90,400.35',
    quantity: 1,
    avgPrice: '₹90,200.35',
    createdOn: '16:32:02',
    status: 'Open'
  },
  {
    id: 5,
    exchangeName: 'NSE',
    scriptName: 'BATASTEEL',
    term: 'LONG TERM | SL',
    termOnly: 'LONG TERM',
    sl: 'SL',
    type: 'BUY',
    lowTradingPrice: '₹90,200.35',
    quantity: 2,
    avgPrice: '₹90,150.35',
    createdOn: '16:32:00',
    status: 'Open'
  }
]
export const TRADES = [
  {
    id: '456039302199',
    date: '17th March 2022',
    qty: '88,8888 x 800.50',
    total: '₹8,88,888.55'
  },
  {
    id: '456039302199',
    date: '17th March 2022',
    qty: '88,8888 x 800.50',
    total: '₹8,88,888.55'
  },
  {
    id: '456039302199',
    date: '17th March 2022',
    qty: '88,8888 x 800.50',
    total: '₹8,88,888.55'
  }
]
export const TRAILS = [
  {
    head: 'Open',
    time: '(19 Aug 2019, 13:34:23)',
    qty: '1000',
    filledQty: '0',
    disclosedQty: '100',
    price: '₹230.25',
    validity: 'Day'
  },
  {
    head: 'Pending Order',
    time: '(19 Aug 2019, 13:34:23)',
    qty: '1000',
    filledQty: '0',
    disclosedQty: '100',
    price: '₹230.25',
    validity: 'Day'
  },
  {
    head: 'Pending Validation',
    time: '(19 Aug 2019, 13:34:23)',
    qty: '1000',
    filledQty: '0',
    disclosedQty: '100',
    price: '₹230.25',
    validity: 'Day'
  },
  {
    head: 'Order Received',
    time: '(19 Aug 2019, 13:34:23)',
    qty: '1000',
    filledQty: '0',
    disclosedQty: '100',
    price: '₹230.25',
    validity: 'Day'
  }
]

export const CANCELORDER = {
  title: 'Cancel Order(s)?',
  desc: 'Are you sure you want to Cancel the order(s)?'
}

type T_ICON_COLOR = {
  label: string
  color: string
  icon: string
}

type T_STATUS_MAPPER_WITH_ICON_COLOR = {
  [key: string]: T_ICON_COLOR
}

export const STATUS_MAPPER_WITH_ICON_COLOR: T_STATUS_MAPPER_WITH_ICON_COLOR = {
  //TODO - Pending validation needs to be added
  PENDING: {
    label: 'Open',
    icon: 'ri-open-arm-line',
    color: 'var(--ds-colour-neutral1)'
  },
  EXECUTED: {
    label: 'Executed',
    icon: 'ri-check-fill',
    color: 'var(--ds-colour-neutral2)'
  },
  OMSXMITTED: {
    label: 'Transit',
    icon: 'ri-time-line',
    color: 'var(--ds-colour-neutral5)'
  },
  CANCELLED: {
    label: 'Cancelled',
    icon: 'ri-close-fill',
    color: 'var(--ds-colour-supportNegativeNeutral)'
  },
  REJECTED: {
    label: 'Rejected',
    icon: 'ri-close-fill',
    color: 'var(--ds-colour-supportNegativeNeutral)'
  },
  AMOACCEPTED: {
    label: 'Open',
    icon: 'ri-open-arm-line',
    color: 'var(--ds-colour-neutral1)'
  }
}

type T_ORDER_TRAIL = {
  label: string
  image: {
    src: string
    alt: string
    as: string
  }[]
}

type T_STATUS_MAPPER_WITH_ORDER_TRAIL = {
  [key: string]: T_ORDER_TRAIL
}

export const STATUS_MAPPER_WITH_ORDER_TRAIL: T_STATUS_MAPPER_WITH_ORDER_TRAIL =
  {
    PENDING: {
      label: ORDERS.ORDER_DETAILS.OPEN,
      image: TICK_ASSET
    },
    EXECUTED: {
      label: ORDERS.ORDER_DETAILS.EXECUTED,
      image: TICK_ASSET
    },
    OMSXMITTED: {
      label: ORDERS.ORDER_DETAILS.TRANSIT,
      image: TICK_ASSET
    },
    CANCELLED: {
      label: ORDERS.ORDER_DETAILS.CANCELLED,
      image: CROSS_ASSET
    },
    REJECTED: {
      label: ORDERS.ORDER_DETAILS.REJECTED,
      image: CROSS_ASSET
    },
    AMOACCEPTED: {
      label: ORDERS.ORDER_DETAILS.AMOACCEPTED,
      image: TICK_ASSET
    }
  }

export type TTabEntry = {
  label: string
  value: string
}

export type TOrderbookTab = {
  [key: string]: TTabEntry
}

export const ORDERBOOK_TAB: TOrderbookTab = {
  Orders: {
    label: 'Orders',
    value: 'Orders'
  },
  GTD: {
    label: 'GTD',
    value: 'GTD'
  },
  SIP: {
    label: 'Stock SIP',
    value: 'Stock SIP'
  }
}

export const ORDERBOOK_TAB_OPTIONS = Object.values(ORDERBOOK_TAB)

export const TRIGGER_SLICE_ORDER_MESSAGE =
  'Single order limit hit This is a Slice Order.'
