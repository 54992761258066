import { ClevertapAnalytics } from '~/src/Lib/withClevertap'

export class SIPClevertap extends ClevertapAnalytics {
  onLoad = () => {
    const eventObject = {
      eventName: 'Trackscreen',
      customAttributes: {
        Event_Status: 'Pass',
        Target_Screen: 'Orders_SIP'
      }
    }
    this.trackEvent(eventObject)
  }

  onUnMount = () => {
    const eventObject = {
      eventName: 'Trackscreen',
      customAttributes: {
        Event_Status: 'Pass',
        Initial_Screen: 'Orders_SIP'
      }
    }
    this.trackEvent(eventObject)
  }

  //57
  onClickNewSIP = () => {
    const eventObject = {
      eventName: 'Order',
      customAttributes: {
        Event_Status: 'Pass',
        Action: 'New SIP',
        Screen_Name: 'Order_SIP'
      }
    }
    this.trackEvent(eventObject)
  }

  //57
  onClickStartSIP = () => {
    const eventObject = {
      eventName: 'Order',
      customAttributes: {
        Event_Status: 'Pass',
        Action: 'Start SIP',
        Screen_Name: 'Order_SIP'
      }
    }
    this.trackEvent(eventObject)
  }

  // yet to implement as we have
  onSwipeBanner = () => {
    const eventObject = {
      eventName: 'Order',
      customAttributes: {
        Event_Status: 'Pass',
        Action: 'Swipe on Banner Carousal',
        Screen_Name: 'Order_SIP'
      }
    }
    this.trackEvent(eventObject)
  }

  //58
  getSIPList = (sipArray: any) => {
    const eventObject = {
      eventName: 'Order',
      customAttributes: {
        Event_Status: 'Pass',
        Details: sipArray,
        Screen_Name: 'Order_SIP_List'
      }
    }
    this.trackEvent(eventObject)
  }

  //60
  getStartSIPInfo = (details: any, action: string) => {
    const eventObject = {
      eventName: 'Order',
      customAttributes: {
        Event_Status: 'Pass',
        Details: details,
        Action: action,
        Screen_Name: 'Order_SIP_Details'
      }
    }
    this.trackEvent(eventObject)
  }

  //61
  onClickBackBtn = (action: string) => {
    const eventObject = {
      eventName: 'Order',
      customAttributes: {
        Event_Status: 'Pass',
        Action: action,
        Screen_Name: 'Order_SIP_Details_Back'
      }
    }
    this.trackEvent(eventObject)
  }

  //62
  onClickAddStock = (action: string, details: any) => {
    const eventObject = {
      eventName: 'Order',
      customAttributes: {
        Event_Status: 'Pass',
        Action: action,
        Details: details,
        Screen_Name: 'Order_SIP_Add_Scrip'
      }
    }
    this.trackEvent(eventObject)
  }

  //63 not implemented
  onClickAddSurvellianceStock = (action: string, details: any) => {
    const eventObject = {
      eventName: 'Order',
      customAttributes: {
        Event_Status: 'Pass',
        Action: action,
        Details: details,
        Screen_Name: 'Order_SIP_Scrip_Error'
      }
    }
    this.trackEvent(eventObject)
  }

  //64
  onClickNextOrBack = (SIPDetails: any, action: string) => {
    const eventObject = {
      eventName: 'Order',
      customAttributes: {
        Event_Status: 'Pass',
        Action: action,
        SIP_Details: SIPDetails,
        Screen_Name: 'Order_SIP_Order_List'
      }
    }
    this.trackEvent(eventObject)
  }

  //65
  onClickConfirm = (SIPDetails: any, action: string) => {
    const eventObject = {
      eventName: 'Order',
      customAttributes: {
        Event_Status: 'Pass',
        Action: action,
        SIP_Details: SIPDetails,
        Screen_Name: 'Order_SIP_Confirm'
      }
    }
    this.trackEvent(eventObject)
  }
  //66
  onClickSIPInfoScreen = (SIPDetails: any, action: string) => {
    const eventObject = {
      eventName: 'Order',
      customAttributes: {
        Event_Status: 'Pass',
        Action: action,
        SIP_Details: SIPDetails,
        Screen_Name: 'Order_SIP_Info'
      }
    }
    this.trackEvent(eventObject)
  }

  //67
  onClickStopSIP = (action: string) => {
    const eventObject = {
      eventName: 'Order',
      customAttributes: {
        Event_Status: 'Pass',
        Action: action,
        Screen_Name: 'Order_SIP_Stop_SIP'
      }
    }
    this.trackEvent(eventObject)
  }

  //68
  onClickPauseSIP = (action: string, pauseTill: any) => {
    const eventObject = {
      eventName: 'Order',
      customAttributes: {
        Event_Status: 'Pass',
        Action: action,
        Details: pauseTill,
        Screen_Name: 'Order_SIP_Pause_SIP'
      }
    }
    this.trackEvent(eventObject)
  }

  //68 not applicable as we dont have any UI for the same
  onClickExtendSIP = (action: string, pauseTill: string) => {
    const eventObject = {
      eventName: 'Order',
      customAttributes: {
        Event_Status: 'Pass',
        Action: action,
        Details: pauseTill,
        Screen_Name: 'Order_SIP_Extend_SIP'
      }
    }
    this.trackEvent(eventObject)
  }
  static onLoad: any
  static onUnMount: any
}
