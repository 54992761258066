import React, { Component } from 'react'
import { DsBox, DsTypography } from '@am92/react-design-system'

import { getLtpTickSelector } from '~/src/Redux/StockTicker/Selectors'

import { tickerContext, TTickerStore } from '~/src/Configurations/TickerStore'

import withErrorConnect from '~/src/Lib/withErrorConnect'

interface ITickerPriceInTaxCharges {
  scriptId: string
  openViewTaxChargesModal: boolean
  LTP: number
  closeprice: number
}

interface ITickerPriceInTaxChargesState {
  showLTP: boolean
  tickerPriceInTaxCharges: number | string
}

class TickerPriceInTaxCharges extends Component<
  ITickerPriceInTaxCharges,
  ITickerPriceInTaxChargesState
> {
  constructor(props: ITickerPriceInTaxCharges) {
    super(props)
    const { LTP = 0 } = this.props || {}
    this.state = {
      showLTP: false,
      tickerPriceInTaxCharges: LTP
    }
  }

  componentDidUpdate(prevProps: any): void {
    const { openViewTaxChargesModal, LTP = 0 } = this.props || {}
    const { showLTP } = this.state

    if (LTP?.toString() && !showLTP) {
      this.setState({ tickerPriceInTaxCharges: LTP, showLTP: true })
    }

    if (openViewTaxChargesModal !== prevProps.openViewTaxChargesModal) {
      this.setState({ tickerPriceInTaxCharges: LTP })
    }
  }

  render() {
    const { tickerPriceInTaxCharges } = this.state
    return (
      <>
        <DsBox>
          <DsTypography variant='bodyRegularSmall'>
            ₹{tickerPriceInTaxCharges}
          </DsTypography>
        </DsBox>
      </>
    )
  }
}

const mapStateToProps = (state: TTickerStore, ownProps: any) => {
  const { scriptId, closeprice } = ownProps
  const LTP = getLtpTickSelector(state, scriptId) || closeprice
  return {
    LTP
  }
}

const mapDispatchToProps = () => ({
  actions: {}
})

export default withErrorConnect(mapStateToProps, mapDispatchToProps, null, {
  context: tickerContext
})(TickerPriceInTaxCharges)
