import React, { Component } from 'react'

// lib
import { IWithRouterProps } from '~/src/Lib/withRouter'
import withErrorConnect from '~/src/Lib/withErrorConnect'
import {
  DsListItem,
  DsListItemButton,
  withBreakpoints
} from '@am92/react-design-system'
import { DraggableProvided } from 'react-beautiful-dnd'
import { setSelectedExchange } from '~/src/Redux/Orders/reducer'
import { To } from 'react-router-dom'
import HoverItems from './HoverItems'
import WatchlistSecurityRow from '../Sidebar/Components/WatchlistSecurityRow'

type ActionTypes = {
  setSelectedExchange: (requestData: any) => Promise<any>
}

export interface ISingleSecurityProps extends IWithRouterProps {
  actions: ActionTypes
  sequenceNumber: number
  provided: DraggableProvided
  scriptId: string
  selectedScript: string
  disableMounseHover: boolean
  hover: number
  handleHover: (sequenceNumber: number) => void
  handleLeaveHover: () => void
  handleViewMore: (sequenceNumber: any) => void
  securitiesIndex: number
  breakpoints: any
}

class SingleSecurity extends Component<ISingleSecurityProps> {
  handleSelectedStock = (scriptId: string) => {
    const { actions } = this.props
    const { exchange } = (window as any).secMaster.getByScriptId(scriptId) || {}
    actions.setSelectedExchange({
      selectedExchange: exchange
    })
    this.navigateTo(`/quotes/${scriptId}`)
  }

  navigateTo = (route: To) => {
    const { navigateTo } = this.props
    navigateTo(route)
  }

  render() {
    const {
      sequenceNumber,
      scriptId,
      provided,
      selectedScript,
      disableMounseHover,
      hover,
      handleHover,
      handleLeaveHover,
      handleViewMore,
      securitiesIndex,
      breakpoints
    } = this.props

    const isDesktop =
      breakpoints?.md || breakpoints?.lg || breakpoints?.xl || false

    return (
      <DsListItem
        sx={{
          backgroundColor: 'var(--ds-colour-surfaceBackground)'
        }}
        key={sequenceNumber}
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        disablePadding
        onClick={() => this.handleSelectedStock(scriptId)}
        onMouseEnter={() => {
          isDesktop && !disableMounseHover && handleHover(sequenceNumber)
        }}
        onMouseLeave={() => {
          isDesktop && !disableMounseHover && handleLeaveHover()
        }}
      >
        <DsListItemButton
          sx={{
            display: 'block',
            p: 'var(--ds-spacing-glacial) var(--ds-spacing-mild)'
          }}
        >
          <WatchlistSecurityRow
            scriptId={scriptId}
            showLtpWithHighlight={true}
          />
        </DsListItemButton>
        <HoverItems
          scriptId={scriptId}
          sequenceNumber={sequenceNumber}
          selectedScript={selectedScript}
          hover={hover}
          securitiesIndex={securitiesIndex}
          handleViewMore={handleViewMore}
        />
      </DsListItem>
    )
  }
}

const mapStateToProps = (state: any, ownProps: any) => {
  return {}
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    setSelectedExchange: (requestData: any) =>
      dispatch(setSelectedExchange(requestData))
  }
})

export default withBreakpoints(
  withErrorConnect(mapStateToProps, mapDispatchToProps)(SingleSecurity)
)
