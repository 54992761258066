import { TOrderBookWithAdditionalKeysObject } from '../Orders/TYPES'

export type TDeepLink = {
  path: string
  parameterList: string[]
}

export type TAppDrawerData = {
  scriptId: string
  orderStatus?: string
}

export type TAppDrawerState = {
  open: boolean
  data: TOrderBookWithAdditionalKeysObject | null
}
export type TAppConfigState = {
  surveillance_indicator: { [key: string]: any }
  dashboard_layout: { [key: string]: any }
  slice_order_config: TSliceConfig
}

export type TSliceConfig = {
  allowedSegment: string[]
  allowedUsers: string[]
  enableSliceOrder: boolean
  max_slice_limit: number
}

export interface IAppStateReducer {
  dialog: object
  deepLinkData: TDeepLink | null
  orderDrawer: TAppDrawerState
  cancelOrderDrawer: TAppDrawerState
  optionChainDrawer: TAppDrawerState
  config: TAppConfigState
  servilience: any
}

export const INITIAL_STATE: IAppStateReducer = {
  dialog: {},
  deepLinkData: null,
  orderDrawer: {
    open: false,
    data: null
  },
  cancelOrderDrawer: {
    open: false,
    data: null
  },
  optionChainDrawer: {
    open: false,
    data: null
  },
  config: {
    surveillance_indicator: {},
    dashboard_layout: {},
    slice_order_config: {
      allowedSegment: [],
      allowedUsers: [],
      enableSliceOrder: false,
      max_slice_limit: 0
    }
  },
  servilience: {}
}
