import React, { Component, Suspense } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { WEB_HTTP_CONTEXT, WEB_HTTP_REQ_HEADERS } from '@am92/web-http'
import Bowser from 'bowser'

import Loader from './Components/Loader'

import AppRouter from './AppRouter'
import { odinWorkerManager } from './Workers/Odin/OdinWorkerManager'
import { socketWorkerManager } from './Workers/tcs/SocketWorkerManager'

import { servilienceEventsServiceName } from './Redux/AppState/actions'
import { createOdinWorker, setDeviceDetails } from './Redux/Indicators/Reducer'
import {
  isOdinWorkerCreated,
  isSecurityMasterLoadedIndicator
} from './Redux/Indicators/Selectors'
import {
  modifyOrderServiceName,
  placeOrderServiceName
} from './Redux/Orders/actions'
import {
  getServiceSelector,
  isServiceLoading
} from './Redux/ServiceTracker/Selectors'
import {
  getCustomerName,
  getSSOAccessTokenSubAccountIdSelector,
  getSSORefreshTokenSelector,
  getSubAccountIdSelector
} from './Redux/SSO/Selectors'
import performHandshake from './Services/performHandshake'

import { appContext, TAppDispatch, TAppStore } from './Configurations/AppStore'
import { CONNECT_ODIN_SOCKET } from './Configurations/env'
import { asHttp } from './Configurations/WebHttp'
import { getOrCreateDeviceID } from './Utils/global'

export interface IAppInitializerState {
  hasError: boolean
  isLoading: boolean
  handshakeComplete: boolean
}
const DEFAULT_STATE: IAppInitializerState = {
  hasError: false,
  isLoading: false,
  handshakeComplete: false
}
class AppInitializer extends Component<PropsFromRedux, IAppInitializerState> {
  state = DEFAULT_STATE
  socketWorkerManager = null as any
  odinWorkerManager = null as any
  async componentDidMount() {
    this.setTokensIfExist()
    this.setDeviceDetails()
    await this.initialize()
    this.setDeviceIdHeader()
  }
  async componentDidUpdate(prevProps: Readonly<PropsFromRedux>) {
    const { isSecurityMasterLoaded, accessToken, subAccountId } = this.props
    const {
      isSecurityMasterLoaded: prevIsSecurityMasterLoaded,
      accessToken: prevAccessToken,
      subAccountId: prevSubAccountId
    } = prevProps
    if (
      prevIsSecurityMasterLoaded !== isSecurityMasterLoaded ||
      accessToken !== prevAccessToken ||
      subAccountId !== prevSubAccountId
    ) {
      const { handshakeComplete } = this.state
      if (isSecurityMasterLoaded && handshakeComplete) {
        this.setState({
          isLoading: false
        })
        if (accessToken && subAccountId) {
          this.initiateSocketWorker()
        }
      }
    }
  }
  initialize = async () => {
    this.setState({ isLoading: true })
    try {
      await performHandshake()
      this.setState(
        {
          handshakeComplete: true
        },
        () => {
          const { isSecurityMasterLoaded, accessToken } = this.props
          if (isSecurityMasterLoaded) {
            this.setState({
              isLoading: false
            })
            if (accessToken) {
              this.initiateSocketWorker()
            }
          }
        }
      )
    } catch (error: any) {
      this.setState({ isLoading: false, hasError: true }, () => {
        throw error
      })
    }
  }
  initiateSocketWorker = async () => {
    const { isOdinWorkerCreatedAlready, accessToken } = this.props
    if (!isOdinWorkerCreatedAlready) {
      await this.createWorker()
    } else {
      if (!CONNECT_ODIN_SOCKET)
        this.socketWorkerManager.socketReconnect(accessToken)
    }
  }
  setTokensIfExist = () => {
    const { accessToken, refreshToken } = this.props
    if (accessToken && refreshToken) {
      asHttp.context.set(WEB_HTTP_CONTEXT.ACCESS_TOKEN, accessToken)
      asHttp.context.set(
        WEB_HTTP_CONTEXT.AUTHENTICATION_TOKEN_KEY,
        WEB_HTTP_REQ_HEADERS.AUTH_TOKEN
      )
      asHttp.context.set(WEB_HTTP_CONTEXT.REFRESH_TOKEN, refreshToken)
    }
  }
  setDeviceDetails = () => {
    const { actions } = this.props
    actions.setDeviceDetails({
      deviceName:
        Bowser.parse(window.navigator.userAgent).browser.name +
          ' ' +
          Bowser.parse(window.navigator.userAgent).browser.version ||
        'Not Fetched',
      osType:
        Bowser.parse(window.navigator.userAgent).os.name +
          ' ' +
          Bowser.parse(window.navigator.userAgent).os.version || 'Not Fetched'
    })
  }

  setDeviceIdHeader = () => {
    const deviceID = getOrCreateDeviceID()
    asHttp.client.defaults.headers.common['X-DeviceId'] = deviceID
  }

  createWorker = async () => {
    const { actions, accessToken, subAccountId } = this.props
    //NOTE - kept old socket connection as requested by Suhas Kharade from ASL
    if (CONNECT_ODIN_SOCKET) {
      this.odinWorkerManager = odinWorkerManager
      await this.odinWorkerManager.socketInitiate({
        USER_ID: 'API TEST',
        TOKEN:
          'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJDdXN0b21lcklkIjoiMTQwNCIsImV4cCI6MTY4OTA0NDg4MCwiaWF0IjoxNjU3NTA4OTIxfQ.46QVfspRCeq9cz-lLai6panvyD8vOr6dxXCDCril7MM'
      })
      actions.createOdinWorkerIndicator(true)
      return
    }
    //NOTE - New Socket
    this.socketWorkerManager = socketWorkerManager
    await this.socketWorkerManager.socketInitiate({
      token: accessToken,
      entid: subAccountId
    })
    actions.createOdinWorkerIndicator(true)
  }
  renderView = () => {
    const { handshakeComplete, hasError, isLoading } = this.state
    const { isModifyOrderLoading, isPlaceOrderLoading, isServilienceLoading } =
      this.props
    return (
      <>
        {(isModifyOrderLoading ||
          isPlaceOrderLoading ||
          isServilienceLoading) && <Loader />}
        <Suspense fallback={<Loader />}>
          {(!hasError && !isLoading && handshakeComplete && <AppRouter />) || (
            <Loader />
          )}
        </Suspense>
      </>
    )
  }
  render() {
    return this.renderView()
  }
}
const mapStateToProps = (state: TAppStore) => {
  const { token: accessToken } = getSSOAccessTokenSubAccountIdSelector(state)
  const subAccountId = getSubAccountIdSelector(state)
  const refreshToken = getSSORefreshTokenSelector(state)
  const isSecurityMasterLoaded = isSecurityMasterLoadedIndicator(state)
  const isOdinWorkerCreatedAlready = isOdinWorkerCreated(state)
  const isPlaceOrderLoading =
    getServiceSelector(state, placeOrderServiceName) === 'LOADING'
  const isModifyOrderLoading =
    getServiceSelector(state, modifyOrderServiceName) === 'LOADING'
  const isServilienceLoading = isServiceLoading(state, [
    servilienceEventsServiceName
  ])

  const customerName = getCustomerName(state)
  return {
    accessToken,
    refreshToken,
    isSecurityMasterLoaded,
    isOdinWorkerCreatedAlready,
    isPlaceOrderLoading,
    isModifyOrderLoading,
    isServilienceLoading,
    customerName,
    subAccountId
  }
}
const mapDispatchToProps = (dispatch: TAppDispatch) => ({
  actions: {
    setDeviceDetails: (data: any) => dispatch(setDeviceDetails(data)),
    createOdinWorkerIndicator: (requestData: boolean) =>
      dispatch(createOdinWorker(requestData))
  }
})
const connector = connect(mapStateToProps, mapDispatchToProps, null, {
  context: appContext
})
type PropsFromRedux = ConnectedProps<typeof connector>
export default connector(AppInitializer)
