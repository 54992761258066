import React, { useState } from 'react'
import {
  DsBox,
  DsButton,
  DsDivider,
  DsStack,
  DsTypography
} from '@am92/react-design-system'

import { T_PLACE_ORDER_PAYLOAD } from '~/src/Redux/Orders/Services/placeOrder.Service'

import { ORDERS } from '~/src/Constants/LABEL'
import { getCurrencyFormat } from '~/src/Utils/global'

export interface ISliceOrderOverviewProps {
  orderDetails: T_PLACE_ORDER_PAYLOAD
  margin: number
}

const SliceOrderOverview: React.FC<ISliceOrderOverviewProps> = props => {
  const [showFull, setShowFull] = useState(false)
  const { orderDetails, margin } = props

  const {
    productType,
    orderType,
    totalQty,
    triggerPrice,
    validityType,
    disclosedQty,
    orderPrice
  } = orderDetails

  return (
    <DsStack
      sx={{ flexDirection: 'column', gap: 'var(--ds-spacing-frostbite)' }}
    >
      <DsStack
        sx={{
          p: 'var(--ds-spacing-bitterCold)',
          gap: 'var(--ds-spacing-bitterCold)',
          alignItems: 'start',
          flexDirection: 'column',
          backgroundColor: 'var(--ds-colour-surfaceBackground)',
          border: '1px solid var(--ds-colour-strokeDefault)',
          borderRadius: 'var(--ds-radius-glacial)',
          width: '100%'
        }}
      >
        <DsTypography variant='bodyBoldLarge' textAlign={'start'}>
          Order Overview
        </DsTypography>
        <DsDivider sx={{ width: '100%' }} />
        <DsStack
          sx={{
            justifyContent: 'space-between',
            flexDirection: 'row',
            display: 'flex',
            alignItems: 'center',
            width: '100%'
          }}
        >
          <DsTypography
            variant='supportRegularMetadata'
            sx={{ color: 'var(--ds-colour-typoSecondary)' }}
          >
            {ORDERS.SLICE_ORDER_OVERVIEW.PRODUCT_TYPE}
          </DsTypography>
          <DsTypography
            variant='bodyBoldSmall'
            sx={{ color: 'var(--ds-colour-typoSecondary)' }}
          >
            {productType}
          </DsTypography>
        </DsStack>

        <DsStack
          sx={{
            justifyContent: 'space-between',
            flexDirection: 'row',
            display: 'flex',
            alignItems: 'center',
            width: '100%'
          }}
        >
          <DsTypography
            variant='supportRegularMetadata'
            sx={{ color: 'var(--ds-colour-typoSecondary)' }}
          >
            {ORDERS.SLICE_ORDER_OVERVIEW.PRICE_TYPE}
          </DsTypography>
          <DsTypography
            variant='bodyBoldSmall'
            sx={{ color: 'var(--ds-colour-typoSecondary)' }}
          >
            {orderType}
          </DsTypography>
        </DsStack>

        <DsStack
          sx={{
            justifyContent: 'space-between',
            flexDirection: 'row',
            display: 'flex',
            alignItems: 'center',
            width: '100%'
          }}
        >
          <DsTypography
            variant='supportRegularMetadata'
            sx={{ color: 'var(--ds-colour-typoSecondary)' }}
          >
            {ORDERS.SLICE_ORDER_OVERVIEW.ORDER_QUANTITY}
          </DsTypography>
          <DsTypography
            variant='bodyBoldSmall'
            sx={{ color: 'var(--ds-colour-typoSecondary)' }}
          >
            {totalQty}
          </DsTypography>
        </DsStack>

        <DsStack
          sx={{
            justifyContent: 'space-between',
            flexDirection: 'row',
            display: 'flex',
            alignItems: 'center',
            width: '100%'
          }}
        >
          <DsTypography
            variant='supportRegularMetadata'
            sx={{ color: 'var(--ds-colour-typoSecondary)' }}
          >
            {ORDERS.SLICE_ORDER_OVERVIEW.PRICE_QUANTITY}
          </DsTypography>
          <DsTypography
            variant='bodyBoldSmall'
            sx={{ color: 'var(--ds-colour-typoSecondary)' }}
          >
            {orderPrice}
          </DsTypography>
        </DsStack>

        <DsStack
          sx={{
            justifyContent: 'space-between',
            flexDirection: 'row',
            display: 'flex',
            alignItems: 'center',
            width: '100%'
          }}
        >
          <DsTypography
            variant='supportRegularMetadata'
            sx={{ color: 'var(--ds-colour-typoSecondary)' }}
          >
            {ORDERS.SLICE_ORDER_OVERVIEW.REQ_MARGIN}
          </DsTypography>
          <DsTypography
            variant='bodyBoldSmall'
            sx={{ color: 'var(--ds-colour-typoSecondary)' }}
          >
            {getCurrencyFormat(margin || 0)}
          </DsTypography>
        </DsStack>

        {showFull ? (
          <>
            {' '}
            {triggerPrice ? (
              <DsStack
                sx={{
                  justifyContent: 'space-between',
                  flexDirection: 'row',
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%'
                }}
              >
                <DsTypography
                  variant='supportRegularMetadata'
                  sx={{ color: 'var(--ds-colour-typoSecondary)' }}
                >
                  {ORDERS.SLICE_ORDER_OVERVIEW.TRIGGER_PRICE}
                </DsTypography>
                <DsTypography
                  variant='bodyBoldSmall'
                  sx={{ color: 'var(--ds-colour-typoSecondary)' }}
                >
                  {getCurrencyFormat(triggerPrice || 0)}
                </DsTypography>
              </DsStack>
            ) : null}
            <DsStack
              sx={{
                justifyContent: 'space-between',
                flexDirection: 'row',
                display: 'flex',
                alignItems: 'center',
                width: '100%'
              }}
            >
              <DsTypography
                variant='supportRegularMetadata'
                sx={{ color: 'var(--ds-colour-typoSecondary)' }}
              >
                {ORDERS.SLICE_ORDER_OVERVIEW.VALIDITY}
              </DsTypography>
              <DsTypography
                variant='bodyBoldSmall'
                sx={{ color: 'var(--ds-colour-typoSecondary)' }}
              >
                {validityType}
              </DsTypography>
            </DsStack>
            {disclosedQty ? (
              <DsStack
                sx={{
                  justifyContent: 'space-between',
                  flexDirection: 'row',
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%'
                }}
              >
                <DsTypography
                  variant='supportRegularMetadata'
                  sx={{ color: 'var(--ds-colour-typoSecondary)' }}
                >
                  {ORDERS.SLICE_ORDER_OVERVIEW.DISCLOSED_QUANTITY}
                </DsTypography>
                <DsTypography
                  variant='bodyBoldSmall'
                  sx={{ color: 'var(--ds-colour-typoSecondary)' }}
                >
                  {disclosedQty}
                </DsTypography>
              </DsStack>
            ) : null}
          </>
        ) : null}

        <DsBox
          sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}
        >
          <DsButton
            onClick={() => setShowFull(!showFull)}
            variant='text'
            color='secondary'
          >
            {!showFull ? 'View More' : 'View Less'}
          </DsButton>
        </DsBox>
      </DsStack>
    </DsStack>
  )
}

export default SliceOrderOverview
