import React, { Component } from 'react'

// lib
import { IWithRouterProps } from '~/src/Lib/withRouter'
import withErrorConnect from '~/src/Lib/withErrorConnect'
import {
  DsStack,
  DsTypography,
  DsDivider,
  DsRemixIcon,
  DsSkeleton
} from '@am92/react-design-system'
import TickerPriceInTaxCharges from './TickerPriceInTaxCharges'
import {
  getRequiredMarginData,
  getTaxAndChargesDetails
} from '~/src/Redux/Orders/selectors'
import { getCurrencyFormat, tickerValue } from '~/src/Utils/global'
import { getRequiredMarginServiceName } from '~/src/Redux/Orders/actions'
import { getServiceSelector } from '~/src/Redux/ServiceTracker/Selectors'
import { T_SCRIPTDATA } from '~/src/Redux/StockMaster/Reducer'

export interface IViewChargesContentProps extends IWithRouterProps {
  coname: string
  scriptName: string
  orderType: string
  quantity: number
  price: string | number
  marketDepth: string
  scriptId: string
  openViewTaxChargesModal: boolean
  stockDetails: T_SCRIPTDATA

  brokerageValue: number
  gstValue: number
  exchTranChargeValue: number
  sebiTurnoverFeeValue: number
  secTranTaxValue: number
  stampDutyValue: number
  finalTotalValue: number
  requiredMargin: {
    margin: string | number
  }
  isGetMarginApiLoding: boolean
  hasGetMarginApiFailed: boolean
}

type IViewChargesContentState = {
  orderValue: number
}

class ViewChargesContent extends Component<
  IViewChargesContentProps,
  IViewChargesContentState
> {
  constructor(props: IViewChargesContentProps) {
    super(props)
    this.state = {
      orderValue: this.handleGetOrderValue()
    }
  }

  handleGetOrderValue = () => {
    const { quantity, price, marketDepth, scriptId, stockDetails } = this.props
    const { segment, lotsize, closeprice } = stockDetails || {}
    const isSegmentEquity = segment === 'EQ'
    const isMarketDepthMarket = marketDepth === 'market'
    // const orderQuantity =
    //   (isSegmentEquity && Number(quantity)) ||
    //   Number(quantity) * Number(lotsize)
    const orderPrice =
      (isMarketDepthMarket && Number(tickerValue(scriptId) || closeprice)) ||
      Number(price)
    const orderValue = quantity * orderPrice
    return orderValue
  }

  componentDidUpdate(prevProps: Readonly<IViewChargesContentProps>): void {
    const { price, quantity, marketDepth } = this.props
    const {
      price: prevPrice,
      quantity: prevQnty,
      marketDepth: prevMarketDepth
    } = prevProps
    if (
      Number(quantity) !== Number(prevQnty) ||
      Number(price) !== Number(prevPrice) ||
      marketDepth !== prevMarketDepth
    ) {
      this.setState({ orderValue: this.handleGetOrderValue() })
    }
  }

  renderLoaderErrorOrNoDataForRequiredMargin = () => {
    const { isGetMarginApiLoding, hasGetMarginApiFailed, requiredMargin } =
      this.props
    const { margin } = requiredMargin

    if (isGetMarginApiLoding) {
      return (
        <DsSkeleton
          variant='text'
          width={40}
          sx={{ fontSize: 'var(--ds-spacing-frostbite)' }}
        />
      )
    }

    if (!isGetMarginApiLoding && hasGetMarginApiFailed) {
      return (
        <DsRemixIcon
          sx={{
            '.MuiTypography-root': { fontWeight: 400 },
            alignItems: 'flex-start'
          }}
          className='ri-error-warning-line'
          fontSize='bitterCold'
        />
      )
    }

    if (!isGetMarginApiLoding && !hasGetMarginApiFailed) {
      return (
        <DsTypography variant='bodyBoldSmall'>
          {getCurrencyFormat(margin)}
        </DsTypography>
      )
    }

    return null
  }

  render() {
    const {
      coname,
      scriptName,
      orderType,
      quantity,
      price,
      marketDepth,
      scriptId,
      openViewTaxChargesModal,
      brokerageValue,
      gstValue,
      exchTranChargeValue,
      sebiTurnoverFeeValue,
      secTranTaxValue,
      stampDutyValue,
      finalTotalValue,
      requiredMargin,
      stockDetails
    } = this.props

    const { orderValue } = this.state
    const { margin } = requiredMargin || {}
    const isTotalMarginRequiredAvailable = margin && finalTotalValue
    const totalMarginRequired = Number(margin) + finalTotalValue
    const priceToRender = Number(price) ? Number(price) : 0
    const qntyToRender = Number(quantity) ? Number(quantity) : 0
    const { closeprice = 0 } = stockDetails || {}
    return (
      <>
        <DsStack gap={4}>
          <DsStack
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <DsTypography
              variant='bodyRegularSmall'
              color='var(--ds-colour-typoSecondary)'
            >
              Scrip Name
            </DsTypography>
            <DsTypography variant='bodyRegularSmall'>{scriptName}</DsTypography>
          </DsStack>
          <DsStack
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <DsTypography
              variant='bodyRegularSmall'
              color='var(--ds-colour-typoSecondary)'
            >
              Company Name
            </DsTypography>
            <DsTypography variant='bodyRegularSmall'>{coname}</DsTypography>
          </DsStack>
          <DsStack
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <DsTypography
              variant='bodyRegularSmall'
              color='var(--ds-colour-typoSecondary)'
            >
              Quantity
            </DsTypography>
            <DsTypography variant='bodyRegularSmall'>
              {qntyToRender}
            </DsTypography>
          </DsStack>
          <DsStack
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <DsTypography
              variant='bodyRegularSmall'
              color='var(--ds-colour-typoSecondary)'
            >
              Price
            </DsTypography>

            {(marketDepth === 'market' && (
              <TickerPriceInTaxCharges
                scriptId={scriptId}
                openViewTaxChargesModal={openViewTaxChargesModal}
                closeprice={closeprice}
              />
            )) || (
              <DsTypography variant='bodyRegularSmall'>
                {getCurrencyFormat(priceToRender)}
              </DsTypography>
            )}
          </DsStack>
          <DsStack
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <DsTypography
              variant='bodyRegularSmall'
              color='var(--ds-colour-typoSecondary)'
            >
              Product
            </DsTypography>
            <DsTypography variant='bodyRegularSmall'>{orderType}</DsTypography>
          </DsStack>
          <DsStack
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <DsTypography
              variant='bodyRegularSmall'
              color='var(--ds-colour-typoSecondary)'
            >
              Order Value
            </DsTypography>
            <DsTypography variant='bodyRegularSmall'>
              {getCurrencyFormat(orderValue)}
            </DsTypography>
          </DsStack>
          <DsStack
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <DsTypography variant='bodyBoldSmall'>
              Margin Applicable (A)
            </DsTypography>
            {this.renderLoaderErrorOrNoDataForRequiredMargin()}
          </DsStack>
          <DsStack
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <DsTypography variant='bodyBoldSmall'>
              Total Taxes & Charges (B)
            </DsTypography>
            <DsTypography variant='bodyBoldSmall'>
              {getCurrencyFormat(finalTotalValue)}
            </DsTypography>
          </DsStack>
          <DsStack
            sx={{
              paddingLeft: 'var(--ds-spacing-bitterCold)'
            }}
            gap={4}
          >
            <DsStack
              sx={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <DsTypography
                variant='bodyRegularSmall'
                color='var(--ds-colour-typoSecondary)'
              >
                Brokerage
              </DsTypography>
              <DsTypography>{getCurrencyFormat(brokerageValue)}</DsTypography>
            </DsStack>
            <DsStack
              sx={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <DsTypography
                variant='bodyRegularSmall'
                color='var(--ds-colour-typoSecondary)'
              >
                GST on brokerage
              </DsTypography>
              <DsTypography>{getCurrencyFormat(gstValue)}</DsTypography>
            </DsStack>
            <DsStack
              sx={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <DsTypography
                variant='bodyRegularSmall'
                color='var(--ds-colour-typoSecondary)'
              >
                Exchange Transaction Charges
              </DsTypography>
              <DsTypography>
                {getCurrencyFormat(exchTranChargeValue)}
              </DsTypography>
            </DsStack>
            <DsStack
              sx={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <DsTypography
                variant='bodyRegularSmall'
                color='var(--ds-colour-typoSecondary)'
              >
                SEBI Turnover Charges
              </DsTypography>
              <DsTypography>
                {getCurrencyFormat(sebiTurnoverFeeValue)}
              </DsTypography>
            </DsStack>
            <DsStack
              sx={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <DsTypography
                variant='bodyRegularSmall'
                color='var(--ds-colour-typoSecondary)'
              >
                Securities Transaction Tax (STT)
              </DsTypography>
              <DsTypography>{getCurrencyFormat(secTranTaxValue)}</DsTypography>
            </DsStack>
            <DsStack
              sx={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <DsTypography
                variant='bodyRegularSmall'
                color='var(--ds-colour-typoSecondary)'
              >
                Stampduty
              </DsTypography>
              <DsTypography>{getCurrencyFormat(stampDutyValue)}</DsTypography>
            </DsStack>
          </DsStack>
          <DsDivider />
          <DsStack
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <DsTypography variant='bodyBoldMedium'>
              Total Margin Required (A+B)
            </DsTypography>
            {(isTotalMarginRequiredAvailable && (
              <DsTypography variant='bodyBoldMedium'>
                {getCurrencyFormat(totalMarginRequired)}
              </DsTypography>
            )) || (
              <DsRemixIcon
                sx={{
                  '.MuiTypography-root': { fontWeight: 400 },
                  alignItems: 'flex-start'
                }}
                className='ri-error-warning-line'
                fontSize='bitterCold'
              />
            )}
          </DsStack>
        </DsStack>
        <DsStack>
          <DsTypography variant='supportRegularInfo'>
            The displayed charges are estimated and may vary. Please refer to
            the contract note for actual brokerage and fees, which can change
            with product or position conversions.
          </DsTypography>
        </DsStack>
      </>
    )
  }
}

const mapStateToProps = (state: any, ownProps: any) => {
  const {
    brokerageValue,
    gstValue,
    exchTranChargeValue,
    sebiTurnoverFeeValue,
    secTranTaxValue,
    stampDutyValue,
    finalTotalValue
  } = getTaxAndChargesDetails(state)
  const requiredMargin = getRequiredMarginData(state)
  const getMarginApiState = getServiceSelector(
    state,
    getRequiredMarginServiceName
  )
  const isGetMarginApiLoding = getMarginApiState === 'LOADING'
  const hasGetMarginApiFailed = getMarginApiState === 'ERROR'

  return {
    brokerageValue,
    gstValue,
    exchTranChargeValue,
    sebiTurnoverFeeValue,
    secTranTaxValue,
    stampDutyValue,
    finalTotalValue,
    requiredMargin,
    isGetMarginApiLoding,
    hasGetMarginApiFailed
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: {}
})

export default withErrorConnect(
  mapStateToProps,
  mapDispatchToProps
)(ViewChargesContent)
