import React, { useState } from 'react'
import {
  DsBox,
  DsButton,
  DsDivider,
  DsStack,
  DsTypography
} from '@am92/react-design-system'

import { TSliceConfig } from '~/src/Redux/AppState/TYPES'
import { T_PLACE_ORDER_PAYLOAD } from '~/src/Redux/Orders/Services/placeOrder.Service'
import { T_SCRIPTDATA } from '~/src/Redux/StockMaster/Reducer'

export interface ISliceOrderSummaryProps {
  orderDetails: T_PLACE_ORDER_PAYLOAD
  stockDetails: T_SCRIPTDATA
  sliceOrderConfig: TSliceConfig
}

const SliceOrderSummary: React.FC<ISliceOrderSummaryProps> = props => {
  const [showFull, setShowFull] = useState(false)
  const { stockDetails, orderDetails, sliceOrderConfig } = props
  const { totalQty = 0 } = orderDetails || {}
  const { maxSingleOrderQty = 0 } = stockDetails || {}

  const { max_slice_limit } = sliceOrderConfig

  // Calculate the number of orders considering the allowed limit
  const calculatedOrders = Math.ceil(totalQty / maxSingleOrderQty)
  const formattedNumberOfOrders =
    calculatedOrders > max_slice_limit ? 0 : calculatedOrders

  // Distribute quantity with maximum allowed first, leaving the smallest for the last order
  let remainingQty = totalQty
  const sliceOrders = Array.from(
    { length: formattedNumberOfOrders },
    (_, index) => {
      const quantity =
        index === formattedNumberOfOrders - 1
          ? remainingQty
          : Math.min(maxSingleOrderQty, remainingQty)
      remainingQty -= quantity
      return {
        orderNumber: index + 1,
        quantity
      }
    }
  )

  const summaryDescription = `Your order exceeds the maximum limit of ${maxSingleOrderQty} Qty,
so it will be split into ${formattedNumberOfOrders} separate orders.`

  const ordersToDisplay = showFull ? sliceOrders : sliceOrders.slice(0, 5)

  return (
    <DsStack
      sx={{
        width: '100%',
        border: '1px solid var(--ds-colour-strokeDefault)',
        borderRadius: 'var(--ds-radius-glacial)'
      }}
    >
      <DsStack
        sx={{
          p: 'var(--ds-spacing-bitterCold)',
          gap: 'var(--ds-spacing-quickFreeze)',
          alignItems: 'start',
          backgroundColor: 'var(--ds-colour-neutral1)'
        }}
      >
        <DsTypography variant='bodyBoldLarge' textAlign='start'>
          Order Summary
        </DsTypography>
        <DsTypography
          variant='bodyRegularSmall'
          sx={{ color: 'var(--ds-colour-typoSecondary)' }}
        >
          {summaryDescription}
        </DsTypography>
      </DsStack>
      <DsDivider sx={{ width: '100%' }} />

      <DsStack
        sx={{
          p: 'var(--ds-spacing-bitterCold)',
          gap: 'var(--ds-spacing-bitterCold)'
        }}
      >
        <DsStack
          sx={{
            justifyContent: 'space-between',
            flexDirection: 'row',
            display: 'flex',
            alignItems: 'center',
            width: '100%'
          }}
        >
          <DsTypography
            variant='bodyRegularMedium'
            sx={{ color: 'var(--ds-colour-typoSecondary)' }}
          >
            No.of orders
          </DsTypography>
          <DsTypography
            variant='bodyRegularMedium'
            sx={{ color: 'var(--ds-colour-typoSecondary)' }}
          >
            Qty
          </DsTypography>
        </DsStack>

        {ordersToDisplay.map(order => (
          <DsStack
            key={order.orderNumber}
            sx={{
              justifyContent: 'space-between',
              flexDirection: 'row',
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              mb: 1
            }}
          >
            <DsTypography
              variant='bodyRegularMedium'
              sx={{ color: 'var(--ds-colour-typoPrimary)' }}
            >
              Order {order.orderNumber}
            </DsTypography>
            <DsTypography
              variant='bodyBoldMedium'
              sx={{ color: 'var(--ds-colour-typoPrimary)' }}
            >
              {order.quantity.toLocaleString()}
            </DsTypography>
          </DsStack>
        ))}

        {showFull || sliceOrders.length <= 5 ? (
          <>
            <DsDivider sx={{ width: '100%' }} />
            <DsBox
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                pb: 'var(--ds-spacing-bitterCold)'
              }}
            >
              <DsTypography variant='bodyRegularMedium'>
                Total Orders:- {ordersToDisplay.length}
              </DsTypography>
              <DsTypography variant='bodyBoldMedium'>
                Total Qty :- {totalQty}
              </DsTypography>
            </DsBox>
          </>
        ) : null}
      </DsStack>

      {sliceOrders.length >= 5 && (
        <DsBox
          sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}
        >
          <DsButton
            onClick={() => setShowFull(!showFull)}
            variant='text'
            color='secondary'
          >
            {!showFull ? 'View More' : 'View Less'}
          </DsButton>
        </DsBox>
      )}
    </DsStack>
  )
}

export default SliceOrderSummary
