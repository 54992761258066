import { SERVICE } from './CONFIG';
/** @ignore */
const DEFAULT_ERROR_MSG = 'String Utils Error';
/** @ignore */
const DEFAULT_ERROR_STATUS_CODE = 500;
/** @ignore */
const DEFAULT_ERROR_CODE = 'StringUtils::GENERIC';
/**
 * Error class whose instance is thrown in case of any error.
 *
 * @class
 * @typedef {StringUtilsError}
 * @extends {Error}
 */
export default class StringUtilsError extends Error {
    /**
     * Flag to identify if error is a custom error.
     */
    _isCustomError = true;
    /**
     * Flag to identify if error is a StringUtilsError.
     */
    _isStringUtilsError = true;
    /**
     * Node project from which Error is thrown.
     */
    service;
    /**
     * Error's message string.
     */
    message;
    /**
     * HTTP status code associated with the error.
     */
    statusCode;
    /**
     * Error Code.
     */
    errorCode;
    /**
     * Error object.
     */
    error;
    /**
     * Creates an instance of StringUtilsError.
     *
     * @constructor
     * @param [e] Any Error instance to wrap with StringUtilsError.
     * @param [eMap] StringUtilsErrorMap to rewrap error for better understanding.
     */
    constructor(e, eMap) {
        super();
        this.service = SERVICE;
        this.message = eMap?.message || e?.message || DEFAULT_ERROR_MSG;
        this.statusCode = eMap?.statusCode || DEFAULT_ERROR_STATUS_CODE;
        this.errorCode = eMap?.errorCode || e?.code || DEFAULT_ERROR_CODE;
        this.error = e;
    }
}
