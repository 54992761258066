import { createAction } from '@reduxjs/toolkit'

import traceActionsCreator from '../traceActionsCreator'

export const getOpenPositionsServiceName = 'positions/getOpenPositions'
export const getOpenPositionsActions = traceActionsCreator(
  getOpenPositionsServiceName
)

export const getTodayPositionsServiceName = 'positions/getTodayPositions'
export const getTodayPositionsActions = traceActionsCreator(
  getTodayPositionsServiceName
)

export const getCumulativePositionsServiceName =
  'positions/getCumulativePositions'
export const getCumulativePositionsActions = traceActionsCreator(
  getCumulativePositionsServiceName
)

export const flushOpenPositionDataAction = createAction(
  'positions/flushOpenPositionDataAction'
)

export const flushTodayPositionDataAction = createAction(
  'positions/flushTodayPositionDataAction'
)

export const flushCumulativePositionDataAction = createAction(
  'positions/flushCumulativePositionDataAction'
)

export const setCumulativePositionsTabAction = createAction<number>(
  'positions/setCumulativePositionsTabAction'
)
