import {
  ActionReducerMapBuilder,
  createSlice,
  CreateSliceOptions
} from '@reduxjs/toolkit'
import _ from 'lodash'

import { getRecommendationByScripIdActions } from '../ResearchAndRecommendation/Actions'
import {
  flushHoldingDataAction,
  getCustomersPortfolioDetailsActions,
  getHoldingQuantitiesOfPrimaryPortfolioActions,
  getHoldingsDetailsActions,
  getHoldingsSummaryActions,
  getTradingHistoryActions
} from './Actions'
import { SLICE_NAME } from './Selectors'
import {
  INITIAL_STATE,
  T_HOLDING_DETAILS_HOLDINGS_OBJECT,
  T_HOLDING_SUMMARY_SCRIPTS_IN_HOLDING
} from './TYPES'

import { UUIDGenerator_V4 } from '~/src/Utils/global'

export const DEFAULT_VALUES = {
  selectedSortBy: 'INVESTED_HIGH_TO_LOW',
  selectedSortByPledge: 'HAIRCUT_HIGH_TO_LOW'
}

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    holdingsFilter: (state, action) => {
      state.holdingsFilter = action?.payload
      if (checkIfDefaultFiltersAreSet(state)) {
        state.isDefaultFiltersEnabled = true
      } else {
        state.isDefaultFiltersEnabled = false
      }
    },
    setHoldings: (state, action) => {
      state.SCRIPTS_IN_HOLDING = action?.payload
    },
    pledgeFilter: (state, action) => {
      state.pledgeFilter = action?.payload

      if (checkIfPledgeDefaultFiltersAreSet(state)) {
        state.isPledgeDefaultFiltersEnabled = true
      } else {
        state.isPledgeDefaultFiltersEnabled = false
      }
    },
    setPledge: (state, action) => {
      state.pledgeDetails = action?.payload
    },
    changeSelectedFilterTabInHolding: (state, action) => {
      state.selectedFilterTabInHolding = action?.payload
    }
  },
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {
    builder.addCase(
      getCustomersPortfolioDetailsActions.success,
      (state, { payload }) => {
        state.Portfolio = payload.data
      }
    )

    builder.addCase(
      getRecommendationByScripIdActions.success,
      (state, { payload }) => {
        state.recommendationDataByScripId = payload || []
        const updatedData = _.map(state.SCRIPTS_IN_HOLDING, item => {
          const match = _.find(state.recommendationDataByScripId, {
            isin: item.isinCode
          })
          return match ? _.merge({}, item, match) : item
        })
        state.SCRIPTS_IN_HOLDING = updatedData
      }
    )

    builder.addCase(getTradingHistoryActions.success, (state, { payload }) => {
      state.tradingHistory = payload
    })

    builder.addCase(getHoldingsSummaryActions.success, (state, { payload }) => {
      const { holdingDetails } = payload
      let HOLDING_SUMMARY_SUMMED_INVESTED_VALUE: number = 0
      const HOLDING_SUMMARY_SCRIPTS_IN_HOLDING: T_HOLDING_SUMMARY_SCRIPTS_IN_HOLDING[] =
        []

      holdingDetails?.forEach((item: T_HOLDING_DETAILS_HOLDINGS_OBJECT) => {
        const {
          investedValue,
          holdingQty,
          securityInfo,
          isinCode,
          avgPrice,
          unrealizedGain,
          collateralValue,
          dmatfreeQty,
          emarginNetQuantity,
          collateralQty
        } = item

        const { scriptId, symbol, haircutPct } = securityInfo[0]
        if (scriptId !== '0') {
          HOLDING_SUMMARY_SUMMED_INVESTED_VALUE =
            HOLDING_SUMMARY_SUMMED_INVESTED_VALUE + (investedValue || 0)
          HOLDING_SUMMARY_SCRIPTS_IN_HOLDING.push({
            scriptId,
            holdingQty,
            investedValue,
            avgPrice,
            symbol,
            isinCode,
            haircutPct,
            collateralValue,
            calls: []
          })
        }

        for (let i = 0; i < securityInfo.length; i++) {
          const { scriptId } = securityInfo[i]
          state.HOLDING_SUMMARY_INDEX[scriptId] = {
            holdings: holdingQty,
            avgPrice: avgPrice,
            investedValue: investedValue,
            unrealizedGain,
            dmatfreeQty,
            emarginNetQuantity,
            collateralQty
          }
        }
      })
      state.HOLDING_SUMMARY_SUMMED_INVESTED_VALUE =
        HOLDING_SUMMARY_SUMMED_INVESTED_VALUE
      let newArray = [...HOLDING_SUMMARY_SCRIPTS_IN_HOLDING]
      newArray = newArray.sort((ITEM_A: any, ITEM_B: any) => {
        const { investedValue: investedValueA } = ITEM_A
        const { investedValue: investedValueB } = ITEM_B
        return investedValueB - investedValueA
      })

      state.HOLDING_SUMMARY_SCRIPTS_IN_HOLDING = newArray
    })

    builder.addCase(getHoldingsSummaryActions.error, state => {
      state.HOLDING_SUMMARY_SUMMED_INVESTED_VALUE =
        INITIAL_STATE.HOLDING_SUMMARY_SUMMED_INVESTED_VALUE
      state.HOLDING_SUMMARY_SCRIPTS_IN_HOLDING =
        INITIAL_STATE.HOLDING_SUMMARY_SCRIPTS_IN_HOLDING
      state.HOLDING_SUMMARY_INDEX = INITIAL_STATE.HOLDING_SUMMARY_INDEX
    })

    builder.addCase(getHoldingsDetailsActions.success, (state, { payload }) => {
      const {
        holdingDetails,
        SCRIPTS_IN_HOLDING,
        SUMMED_INVESTED_VALUE,
        scriptsArray,
        pledgeData,
        pledgeDetails,
        pledgeDataNotAllowed,
        holdingDetailsScriptIndex
      } = payload

      state.holdingDetails = holdingDetails
      state.SCRIPTS_IN_HOLDING = SCRIPTS_IN_HOLDING
      state.SUMMED_INVESTED_VALUE = SUMMED_INVESTED_VALUE
      state.scriptsArray = scriptsArray
      state.pledgeData = pledgeData
      state.pledgeDetails = pledgeDetails
      state.pledgeDataNotAllowed = pledgeDataNotAllowed
      state.holdingDetailsScriptIndex = holdingDetailsScriptIndex

      if (checkIfDefaultFiltersAreSet(state)) {
        state.isPledgeDefaultFiltersEnabled = true
      } else {
        state.isPledgeDefaultFiltersEnabled = false
      }
    })

    builder.addCase(getHoldingsDetailsActions.error, state => {
      state.SUMMED_INVESTED_VALUE = INITIAL_STATE.SUMMED_INVESTED_VALUE
      state.SCRIPTS_IN_HOLDING = INITIAL_STATE.SCRIPTS_IN_HOLDING
      state.isDefaultFiltersEnabled = INITIAL_STATE.isDefaultFiltersEnabled
    })

    builder.addCase(
      getHoldingQuantitiesOfPrimaryPortfolioActions.success,
      (state, { payload }) => {
        const { holdingDetails } = payload
        state.holdingDetails = holdingDetails
        holdingDetails?.forEach((item: T_HOLDING_DETAILS_HOLDINGS_OBJECT) => {
          const {
            investedValue,
            holdingQty,
            securityInfo,
            totalFreeQty = 0,
            avgPrice,
            realizedGain,
            unrealizedGain,
            interSettlementQty,
            dematHeldQty = 0,
            collateralQty = 0,
            emarginNetQuantity,
            isinCode,
            dmatfreeQty = 0,
            collateralValue = 0
          } = item

          const {
            scriptId,
            symbol,
            exchange = '',
            haircutPct = 0
          } = securityInfo[0]
          const freeQty = Number(holdingQty) + dematHeldQty || 0

          if (scriptId !== '0') {
            const holdingObj = {
              id: UUIDGenerator_V4(),
              scriptId,
              holdingQty,
              investedValue: investedValue || 0,
              avgPrice,
              symbol,
              exchange: exchange ? exchange.split('_')[0] : '',
              realizedGain,
              unrealizedGain,
              interSettlementQty,
              freeQty,
              collateralQty,
              emarginNetQuantity,
              isinCode,
              dmatfreeQty,
              collateralValue,
              haircutPct,
              totalFreeQty,
              initialDmatQty: dmatfreeQty,
              calls: []
            }
            state.holdingQuantitiesForPrimaryPortfolio[isinCode] = holdingObj
          }
        })
      }
    )

    builder.addCase(flushHoldingDataAction, state => {
      // flushing previous data
      state.holdingDetails = []
      state.SCRIPTS_IN_HOLDING = []
      state.SUMMED_INVESTED_VALUE = 0
      state.scriptsArray = []
      state.pledgeData = []
      state.pledgeDetails = []
      state.pledgeDataNotAllowed = []
    })
  }
}

function checkIfDefaultFiltersAreSet(state: any) {
  const { holdingsFilter } = state
  const { stocksFilter } = holdingsFilter
  const noFilters = stocksFilter.length === 0
  if (holdingsFilter.sortBy === DEFAULT_VALUES.selectedSortBy && noFilters) {
    return true
  }
  return false
}

function checkIfPledgeDefaultFiltersAreSet(state: any) {
  const { pledgeFilter } = state
  const { stocksFilter } = pledgeFilter
  const noFilters = stocksFilter.length === 0
  if (
    pledgeFilter.sortBy === DEFAULT_VALUES.selectedSortByPledge &&
    noFilters
  ) {
    return true
  }
  return false
}

const slice = createSlice(sliceOptions)
export const {
  holdingsFilter,
  setHoldings,
  changeSelectedFilterTabInHolding,
  pledgeFilter,
  setPledge
} = slice.actions
export default slice.reducer
