export const SORT_BY_OPTION_LIST = {
  LTP_HTOL: {
    label: 'LTP: High to Low',
    value: 'LTP_HTOL'
  },
  LTP_LTOH: {
    label: 'LTP: Low to High',
    value: 'LTP_LTOH'
  },
  LTP_PERCENT_HTOL: {
    label: 'LTP%: High to Low',
    value: 'LTP_PERCENT_HTOL'
  },
  LTP_PERCENT_LTOH: {
    label: 'LTP%: Low to High',
    value: 'LTP_PERCENT_LTOH'
  },
  ATOZ: {
    label: 'Alphabetical: A to Z',
    value: 'ATOZ'
  },
  ZTOA: {
    label: 'Alphabetical: Z to A',
    value: 'ZTOA'
  }
}

type TSortDirection = {
  [key: string]: string
}

type TSortMap = {
  [key: string]: TSortDirection
}

export const SORT_MAP: TSortMap = {
  ltp: {
    asc: 'LTP_LTOH',
    desc: 'LTP_HTOL'
  },
  perChange: {
    asc: 'LTP_PERCENT_LTOH',
    desc: 'LTP_PERCENT_HTOL'
  },
  name: {
    asc: 'ATOZ',
    desc: 'ZTOA'
  }
}

export const SORT_BY_OPTIONS = Object.values(SORT_BY_OPTION_LIST)

// Indices for NSE
export const NSE_INDICES_LIST = {
  'Nifty 50': {
    label: 'Nifty 50',
    value: 'Nifty 50'
  },
  'Nifty 100': {
    label: 'Nifty 100',
    value: 'Nifty 100'
  },
  'Nifty 200': {
    label: 'Nifty 200',
    value: 'Nifty 200'
  },
  'Nifty IT': {
    label: 'Nifty IT',
    value: 'Nifty IT'
  },
  'Nifty Bank': {
    label: 'Nifty Bank',
    value: 'Nifty Bank'
  }
}
export const NSE_INDICES_LIST_OPTIONS = Object.values(NSE_INDICES_LIST)

// Indices for BSE
export const BSE_INDICES_LIST = {
  'BSE 100': {
    label: 'BSE 100',
    value: 'BSE 100'
  },
  'BSE 200': {
    label: 'BSE 200',
    value: 'BSE 200'
  },
  'BSE 500': {
    label: 'BSE 500',
    value: 'BSE 500'
  },
  'BSE Auto': {
    label: 'BSE Auto',
    value: 'BSE Auto'
  },
  'BSE BANKEX': {
    label: 'BSE BANKEX',
    value: 'BSE BANKEX'
  }
}
export const BSE_INDICES_LIST_OPTIONS = Object.values(BSE_INDICES_LIST)

export const SEGMENT_LIST: any = {
  ALL: {
    label: 'All Segments',
    value: 'ALL SEGMENTS'
  },
  INDEX: {
    label: 'Index',
    value: 'INDEX'
  },
  STOCKS: {
    label: 'Stocks',
    value: 'STOCKS'
  }
}
export const SEGMENT_OPTIONS = Object.values(SEGMENT_LIST)

export const EXCHANGE_LIST = {
  NSE: {
    label: 'NSE',
    value: 'NSE'
  },
  BSE: {
    label: 'BSE',
    value: 'BSE'
  }
}
export const EXCHANGE_OPTIONS = Object.values(EXCHANGE_LIST)

export const CALL_PUT_LIST: any = {
  ALL: {
    label: 'All Options',
    value: 'ALL OPTIONS'
  },
  CALL: {
    label: 'Call',
    value: 'CALL'
  },
  PUT: {
    label: 'Put',
    value: 'PUT'
  }
}
export const CALL_PUT_OPTIONS = Object.values(CALL_PUT_LIST)

export interface IFilterOption {
  label: string
  keyCode: string
  value: string
}

export interface IFilterOptions {
  SORT_BY: IFilterOption
  INDICES: IFilterOption
  EXPIRY: IFilterOption
  SEGMENT: IFilterOption
  EXCHANGE: IFilterOption
  CALLORPUT: IFilterOption
}

export const FILTER_OPTIONS: IFilterOptions = {
  SORT_BY: {
    label: 'Sort By',
    keyCode: 'sortBy',
    value: 'SORT_BY'
  },
  INDICES: {
    label: 'Indices',
    keyCode: 'indices',
    value: 'INDICES'
  },
  EXPIRY: {
    label: 'Expiry',
    keyCode: 'expiry',
    value: 'EXPIRY'
  },
  SEGMENT: {
    label: 'Segments',
    keyCode: 'segment',
    value: 'SEGMENT'
  },
  EXCHANGE: {
    label: 'Exchange',
    keyCode: 'exchange',
    value: 'EXCHANGE'
  },
  CALLORPUT: {
    label: 'Call/Put',
    keyCode: 'callOrPut',
    value: 'CALLORPUT'
  }
}

export const FILTERS = [
  {
    label: 'Sort By'
  },
  {
    label: 'Indices'
  },
  {
    label: 'Expiry'
  },
  {
    label: 'Segment'
  },
  {
    label: 'Exchange'
  },
  {
    label: 'Call/Put'
  }
]

/* -------------------------------------------------------------------------- */
/*                               FILTER OPTIONS                               */
/* -------------------------------------------------------------------------- */

// Filter Options For - Equity
export const EQUITY_FILTERS = [FILTER_OPTIONS.SORT_BY, FILTER_OPTIONS.INDICES]

// Filter Options For - Derivative
export const DERIVATIVE_FUTURES_FILTERS = [
  FILTER_OPTIONS.SORT_BY,
  FILTER_OPTIONS.EXPIRY,
  FILTER_OPTIONS.SEGMENT
]
export const DERIVATIVE_OPTIONS_FILTERS = [
  FILTER_OPTIONS.SORT_BY,
  FILTER_OPTIONS.CALLORPUT,
  FILTER_OPTIONS.EXPIRY,
  FILTER_OPTIONS.SEGMENT
]

// Filter Options For - Currency
export const CURRENCY_FUTURES_FILTERS = [
  FILTER_OPTIONS.SORT_BY,
  FILTER_OPTIONS.EXPIRY
]

export const CURRENCY_OPTIONS_FILTERS = [
  FILTER_OPTIONS.SORT_BY,
  FILTER_OPTIONS.CALLORPUT,
  FILTER_OPTIONS.EXPIRY
]

// Filter Options For - Comodoities
export const COMODOITIES_FUTURES_FILTERS = [
  FILTER_OPTIONS.SORT_BY,
  FILTER_OPTIONS.EXCHANGE,
  FILTER_OPTIONS.EXPIRY
]
export const COMODOITIES_OPTIONS_FILTERS = [
  FILTER_OPTIONS.SORT_BY,
  FILTER_OPTIONS.EXCHANGE,
  FILTER_OPTIONS.CALLORPUT,
  FILTER_OPTIONS.EXPIRY
]

/* -------------------------------------------------------------------------- */
/*                             FILTER OPTION LIST                             */
/* -------------------------------------------------------------------------- */

// Filter Options List - Equity
export const EQUITY_FILTER_SORT_BY_OPTIONS_LIST = [
  SORT_BY_OPTION_LIST.LTP_HTOL,
  SORT_BY_OPTION_LIST.LTP_LTOH,
  SORT_BY_OPTION_LIST.LTP_PERCENT_HTOL,
  SORT_BY_OPTION_LIST.LTP_PERCENT_LTOH,
  SORT_BY_OPTION_LIST.ATOZ,
  SORT_BY_OPTION_LIST.ZTOA
]

// Filter Options List - Equity
export const EQUITY_FILTER_INDICES_NSE_OPTIONS_LIST = [
  ...NSE_INDICES_LIST_OPTIONS
]

export const EQUITY_FILTER_INDICES_BSE_OPTIONS_LIST = [
  ...BSE_INDICES_LIST_OPTIONS
]

// DERIVATIVE
// DERIVATIVE  - FUTURES
export const DERIVATIVES_FUTURES_FILTER_INDICES_OPTIONS_LIST = [
  ...NSE_INDICES_LIST_OPTIONS
]
export const DERIVATIVES_FUTURES_FILTER_SEGMENT_OPTIONS_LIST = [
  ...SEGMENT_OPTIONS
]

// DERIVATIVE  - OPTIONS
export const DERIVATIVES_OPTIONS_FILTER_INDICES_OPTIONS_LIST = [
  ...NSE_INDICES_LIST_OPTIONS
]
export const DERIVATIVES_OPTIONS_FILTER_SEGMENT_OPTIONS_LIST = [
  ...SEGMENT_OPTIONS
]

// CURRENCY

export interface IOptionsArg {
  list?: any[]
}

type TFilterName =
  | 'SORT_BY'
  | 'INDICES'
  | 'EXPIRY'
  | 'SEGMENT'
  | 'EXCHANGE'
  | 'CALLORPUT'

// COMODITIES

export const SCREENS_SEGMENT_MAPPER: any = {
  STOCKS: { OPTIONS: 'OPTSTK', FUTURES: 'FUTSTK' },
  INDEX: { OPTIONS: 'OPTIDX', FUTURES: 'FUTIDX' },
  ALL: 'ALL'
}
export const SCREENS_CALLPUT_MAPPER: any = {
  CALL: 'CE',
  PUT: 'PE',
  ALL: 'ALL'
}

export const getOptionListByAssetClass = (
  filterName: TFilterName,
  assetClass: string,
  exchange: string = 'NSE',
  options: IOptionsArg = {}
) => {
  if (assetClass && filterName) {
    if (filterName === 'SORT_BY') return EQUITY_FILTER_SORT_BY_OPTIONS_LIST
    // equity
    if (assetClass === 'STOCKS') {
      if (exchange === 'NSE' && filterName === 'INDICES')
        return EQUITY_FILTER_INDICES_NSE_OPTIONS_LIST
      if (exchange === 'BSE' && filterName === 'INDICES')
        return EQUITY_FILTER_INDICES_BSE_OPTIONS_LIST
    }
    // der - futures
    if (assetClass === 'FUTURES') {
      if (filterName === 'SEGMENT') {
        return DERIVATIVES_FUTURES_FILTER_SEGMENT_OPTIONS_LIST
      }
      if (filterName === 'INDICES') {
        return DERIVATIVES_FUTURES_FILTER_INDICES_OPTIONS_LIST
      }
      if (filterName === 'EXPIRY') {
        return options.list
      }
    }

    // der - options
    if (assetClass === 'OPTIONS') {
      if (filterName === 'SEGMENT') {
        return DERIVATIVES_OPTIONS_FILTER_SEGMENT_OPTIONS_LIST
      }
      if (filterName === 'INDICES') {
        return DERIVATIVES_OPTIONS_FILTER_INDICES_OPTIONS_LIST
      }
      if (filterName === 'EXPIRY') {
        return options.list
      }
      if (filterName === 'CALLORPUT') {
        return CALL_PUT_OPTIONS
      }
    }

    // current futures
    if (assetClass === 'CURR-FUT') {
      if (filterName === 'EXPIRY') {
        return options.list
      }
      if (filterName === 'CALLORPUT') {
        return CALL_PUT_OPTIONS
      }
    }
    // current options
    if (assetClass === 'CURR-OPT') {
      if (filterName === 'EXPIRY') {
        return options.list
      }
      if (filterName === 'CALLORPUT') {
        return CALL_PUT_OPTIONS
      }
    }
  }

  return []
}

export const getFilterOptionsByAssetClass = (assetClass: string) => {
  switch (assetClass) {
    case 'STOCKS':
      return EQUITY_FILTERS
    case 'FUTURES':
      return DERIVATIVE_FUTURES_FILTERS
    case 'OPTIONS':
      return DERIVATIVE_OPTIONS_FILTERS
    case 'CURR-FUT':
      return CURRENCY_FUTURES_FILTERS
    case 'CURR-OPT':
      return CURRENCY_OPTIONS_FILTERS
    case 'COMMODITY-FUTURES':
      return COMODOITIES_FUTURES_FILTERS
    case 'COMMODITY-OPTIONS':
      return COMODOITIES_FUTURES_FILTERS
    default:
      return []
  }
}

export function isEquity(assetClassName: string) {
  return assetClassName === 'STOCKS'
}
