import { NavigateOptions, To } from 'react-router-dom'

type AppRouteItem = {
  pathname: To
  options?: NavigateOptions
}

type AppRoute = {
  [key: string]: AppRouteItem
}

const APP_ROUTES: AppRoute = {
  DASHBOARD: {
    pathname: '/dashboard'
  } as AppRouteItem,
  QUOTES: {
    pathname: '/quotes/:scriptId'
  } as AppRouteItem,
  PLEDGE: {
    pathname: '/pledge'
  } as AppRouteItem,
  PROFILEMENU: {
    pathname: '/profile-menu'
  } as AppRouteItem,
  // PLEDGEHISTORY: {
  //   pathname: '/pledge-history'
  // } as AppRouteItem,
  PROFILE: {
    pathname: '/profile'
  } as AppRouteItem,
  REPORTS: {
    pathname: '/reports'
  } as AppRouteItem,
  BANKDETAILS: {
    pathname: '/bank-details'
  } as AppRouteItem,
  EXCHANGEMESSAGES: {
    pathname: '/exchange-messages'
  } as AppRouteItem,
  MARKETSTATUS: {
    pathname: '/market-status'
  } as AppRouteItem,
  FUNDSDASHBOARD: {
    pathname: '/funds'
  } as AppRouteItem,
  REGISTER_LINK: {
    pathname: 'https://digitalaccount.axisdirect.in/register'
  } as AppRouteItem,
  ORDERBOOK: {
    pathname: '/orderbook'
  } as AppRouteItem,
  MOBILE_WATCHLIST: {
    pathname: '/watchlist'
  } as AppRouteItem,
  MOBILE_EDIT_WATCHLIST: {
    pathname: '/editWatchlist'
  } as AppRouteItem,
  MOBILE_SEARCH: {
    pathname: '/search-stocks'
  } as AppRouteItem,
  AUTHENTICATE_SSO: {
    pathname: '/sso/authenticate'
  } as AppRouteItem,
  VALIDATE_SSO: {
    pathname: '/sso/validate'
  } as AppRouteItem,
  LOGIN: {
    pathname: '/'
  } as AppRouteItem,
  CHART: {
    pathname: '/chart/:scriptId'
  } as AppRouteItem,
  SOMETHING_WENT_WRONG: {
    pathname: '/something-went-wrong'
  } as AppRouteItem,
  SESSION_EXPIRED: {
    pathname: '/session-expired'
  } as AppRouteItem,
  CUSTOMER_SUPPORT: {
    pathname: '/customer-support'
  } as AppRouteItem,
  RESEARCH: {
    pathname: '/research'
  } as AppRouteItem,
  SCREENERS: {
    pathname: '/research/screeners'
  } as AppRouteItem,

  //SECTION - Portfolio Routes
  MULTIPLE_PORTFOLIO: {
    pathname: '/portfolio'
  } as AppRouteItem,
  STOCK_PORTFOLIO: {
    pathname: '/portfolio/:portfolioType'
  } as AppRouteItem,
  MUTUAL_FUND_PORTFOLIO: {
    pathname: '/portfolio-mutualfund'
  } as AppRouteItem,
  POSITION_SQ_OFF: {
    pathname: '/portfolio/positions-sq-off'
  } as AppRouteItem,
  DEMAT_COLLATERAL_STOCKS: {
    pathname: '/portfolio/demat/collateral-stocks'
  } as AppRouteItem,
  DEMAT_UNSETTLED_STOCKS: {
    pathname: '/portfolio/demat/unsettled-stocks'
  } as AppRouteItem,

  LIMIT_STATEMENT: {
    pathname: '/limit-statement/:segment'
  } as AppRouteItem,
  DASHBOARD_NEWS: {
    pathname: '/dashboard/news'
  } as AppRouteItem,
  PARTNERS_INFO: {
    pathname: '/partners-info'
  } as AppRouteItem,
  BNPL_TERMS: {
    pathname: '/bnpl-terms'
  } as AppRouteItem,
  SIP_TERMS: {
    pathname: '/sip-terms'
  } as AppRouteItem,
  RECOMMENDATION_OVERVIEW: {
    pathname: '/recommendation/:scriptId'
  },
  NOTIFICATION: {
    pathname: '/notifications'
  } as AppRouteItem,
  RECOMMENDATION_SEARCH: {
    pathname: '/recommendation-search'
  } as AppRouteItem,
  RESEARCH_CATEGORY: {
    pathname: '/research-category/:category'
  } as AppRouteItem,
  ALERTS: {
    pathname: '/alerts'
  } as AppRouteItem,
  DEMAT: {
    pathname: '/reports/demat'
  } as AppRouteItem,
  MAINTENANCE: {
    pathname: '/maintenance'
  } as AppRouteItem,
  PLEDGE_STOCK: {
    pathname: '/portfolio/stocks'
  },
  ANY: {
    pathname: '*'
  } as AppRouteItem
}

export default APP_ROUTES
