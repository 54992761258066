import {
  ActionReducerMapBuilder,
  createSlice,
  CreateSliceOptions
} from '@reduxjs/toolkit'

import {
  flushCumulativePositionDataAction,
  flushOpenPositionDataAction,
  flushTodayPositionDataAction,
  getCumulativePositionsActions,
  getOpenPositionsActions,
  getTodayPositionsActions,
  setCumulativePositionsTabAction
} from './actions'
import { SLICE_NAME } from './selectors'
import { INITIAL_STATE } from './TYPES'

export const DEFAULT_VALUES = {
  selectedSortBy: 'PNL_HIGH_TO_LOW'
}

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    positionsFilter: (state, action) => {
      state.positionsFilter = action?.payload
      if (checkIfDefaultFiltersAreSet(state)) {
        state.isDefaultFiltersEnabled = true
      } else {
        state.isDefaultFiltersEnabled = false
      }
    },
    setPositions: (state, action) => {
      state.positionsData = action?.payload
    }
  },
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {
    builder.addCase(getOpenPositionsActions.success, (state, { payload }) => {
      const { data, totalInvestedValue, scriptToPositionMapper } = payload
      state.openPositionData = data
      state.totalInvestedAmountForOpenPositions = totalInvestedValue
      state.scriptToPositionMapper = scriptToPositionMapper
      setFilterLabels(state, payload.data)
    })

    builder.addCase(getTodayPositionsActions.success, (state, { payload }) => {
      const { data, totalInvestedValue, scriptToPositionMapper } = payload
      state.todayPositionData = data
      state.totalInvestedAmountForTodayPositions = totalInvestedValue
      state.scriptToPositionMapper = scriptToPositionMapper
      setFilterLabels(state, payload.data)
    })

    builder.addCase(
      getCumulativePositionsActions.success,
      (state, { payload }) => {
        const { data, totalInvestedValue, scriptToPositionMapper } = payload
        state.cumulativePositionsData = data // Update the cumulative positions data
        state.totalInvestedAmountForCumulativePositions = totalInvestedValue
        state.scriptToPositionMapper = scriptToPositionMapper
        setFilterLabels(state, payload.data) // Update filter labels if applicable
      }
    )

    builder.addCase(flushOpenPositionDataAction, state => {
      // flushing previous data
      state.openPositionData = []
      state.totalInvestedAmountForOpenPositions = 0
      setFilterLabels(state, [])
    })

    builder.addCase(flushTodayPositionDataAction, state => {
      // flushing previous data
      state.todayPositionData = []
      state.totalInvestedAmountForTodayPositions = 0
      setFilterLabels(state, [])
    })

    builder.addCase(flushCumulativePositionDataAction, state => {
      // flushing previous data
      state.cumulativePositionsData = []
      state.totalInvestedAmountForCumulativePositions = 0
      setFilterLabels(state, [])
    })

    builder.addCase(setCumulativePositionsTabAction, (state, { payload }) => {
      state.cumulativePositionsTabIndicator = payload // Update the cumulative positions tab
    })
  }
}

function checkIfDefaultFiltersAreSet(state: any) {
  const { positionsFilter } = state
  const { stocksFilter, assetsFilter, productsFilter } = positionsFilter
  const noFilters =
    stocksFilter.length + assetsFilter.length + productsFilter.length === 0
  if (positionsFilter.sortBy === DEFAULT_VALUES.selectedSortBy && noFilters) {
    return true
  }
  return false
}

const setFilterLabels = (state: any, payload: any) => {
  const uniqueProductValues = new Set(payload?.map((obj: any) => obj.product))
  const uniqueAssetValues = new Set(payload?.map((obj: any) => obj.instrument))
  const uniqueStockValues = new Set(payload?.map((obj: any) => obj.symbol))
  state.filterLabels = {
    productFilterLabel: Array.from(uniqueProductValues),
    assetFilterLabel: Array.from(uniqueAssetValues),
    stockFilterLabel: Array.from(uniqueStockValues)
  }
  if (checkIfDefaultFiltersAreSet(state)) {
    state.isDefaultFiltersEnabled = true
  } else {
    state.isDefaultFiltersEnabled = false
  }
}

const slice = createSlice(sliceOptions)

export const { positionsFilter, setPositions } = slice.actions

export default slice.reducer
