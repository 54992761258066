import { createSelector } from '@reduxjs/toolkit'

import { I_PORTFOLIO_REDUCER, ISingleMutualFundPortfolioData } from './TYPES'

import { TAppStore } from '~/src/Configurations/AppStore'

import { TMutualFundPortfolioTableIndividualData } from '~/src/Pages/Portfolio/Components/MutualFund/MutualFund.constants'
export const SLICE_NAME = 'portfolio'

const select = (state: TAppStore) => state[SLICE_NAME] as I_PORTFOLIO_REDUCER

export const getMultiplePortfolioSummary = createSelector(select, portfolio => {
  const { multiplePortfolioData } = portfolio
  const { portfolioSummary } = multiplePortfolioData || {}
  const { totalUnrealizedGain = 0, totalDayGain = 0 } = portfolioSummary || {}
  const overallPnlPercentChangeColour = `var(--ds-colour-support${totalUnrealizedGain > 0 ? 'Positive' : 'Negative'} )`
  const todayPnlPercentChangeColour = `var(--ds-colour-support${totalDayGain > 0 ? 'Positive' : 'Negative'} )`
  const overallPnlPercentChangeRemixIcon = `ri-arrow-${totalUnrealizedGain > 0 ? 'up' : 'down'}-fill`
  const todayPnlPercentChangeRemixIcon = `ri-arrow-${totalDayGain > 0 ? 'up' : 'down'}-fill`

  return {
    portfolioSummary,
    overallPnlPercentChangeColour,
    todayPnlPercentChangeColour,
    overallPnlPercentChangeRemixIcon,
    todayPnlPercentChangeRemixIcon
  }
})

export const getMultiplePortfolioTotalInvestment = createSelector(
  select,
  portfolio => {
    const { multiplePortfolioData } = portfolio
    const { portfolioSummary } = multiplePortfolioData || {}
    const { totalInvestedAmount = 0 } = portfolioSummary || {}
    return totalInvestedAmount
  }
)

export const getMultiplePortfolioData = createSelector(
  select,
  portfolio => portfolio?.multiplePortfolioData?.portfolio
)

export const isMultiplePortfolioDataAvailable = createSelector(
  select,
  portfolio => {
    const { multiplePortfolioData } = portfolio
    return (multiplePortfolioData && true) || false
  }
)

export const getSelectedPortfolio = createSelector(
  select,
  portfolio => portfolio.selectedPortfolio
)

export const getMultiplePortFolioSections = createSelector(
  select,
  portfolio => portfolio.multiplePortFolioSections
)

export const getOriginalMutualFundPortfolioData = createSelector(
  select,
  portfolio => portfolio?.mutualFundPortfolioData?.portfolio
)

export const getFilteredMutualFundPortfolioData = createSelector(
  select,
  portfolio => portfolio?.mutualFundSortedAndFilteredData
)

export const getMutualFundPortfolioSummary = createSelector(
  select,
  portfolio => {
    const { mutualFundPortfolioData } = portfolio
    const { portfolioSummary } = mutualFundPortfolioData || {}
    const { totalUnrealizedGain = 0, totalDayGain = 0 } = portfolioSummary || {}
    const overallPnlPercentChangeColour = `var(--ds-colour-support${totalUnrealizedGain > 0 ? 'Positive' : 'Negative'} )`
    const todayPnlPercentChangeColour = `var(--ds-colour-support${totalDayGain > 0 ? 'Positive' : 'Negative'} )`
    const overallPnlPercentChangeRemixIcon = `ri-arrow-${totalUnrealizedGain > 0 ? 'up' : 'down'}-fill`
    const todayPnlPercentChangeRemixIcon = `ri-arrow-${totalDayGain > 0 ? 'up' : 'down'}-fill`

    return {
      portfolioSummary,
      overallPnlPercentChangeColour,
      todayPnlPercentChangeColour,
      overallPnlPercentChangeRemixIcon,
      todayPnlPercentChangeRemixIcon
    }
  }
)

export const getMutualFundTotalInvestment = createSelector(
  select,
  portfolio => {
    const { mutualFundPortfolioData } = portfolio
    const { portfolioSummary } = mutualFundPortfolioData
    const { totalInvestedAmount = 0 } = portfolioSummary || {}
    return totalInvestedAmount
  }
)

export const getMutualFundTableData = createSelector(select, portfolio => {
  const { mutualFundSortedAndFilteredData: portfolioArray = [] } = portfolio
  const mutualFundPortfolioTableData: TMutualFundPortfolioTableIndividualData[] =
    []
  let id = 0
  if (portfolioArray && portfolioArray.length > 0) {
    portfolioArray?.forEach((individualMutualFund: any) => {
      mutualFundPortfolioTableData.push({
        ...individualMutualFund,
        ...{ id: id }
      })
      id = id + 1
    })
  }

  return mutualFundPortfolioTableData
})

export const getShowMutualFundDisclaimerIndicator = createSelector(
  select,
  portfolio => portfolio?.showMutualFundDisclaimer
)

export const getShowMutualFundSortAndFilter = createSelector(
  select,
  portfolio => portfolio?.showMutualFundSortAndFilter
)

export const getSelectedSortAndFilterOption = createSelector(
  select,
  portfolio => portfolio?.selectedSortAndFilterOption
)

export const getSelectedSortByOption = createSelector(
  select,
  portfolio => portfolio?.mutualFundSortAndFilter.sortBy
)

export const getMutualFundFilters = createSelector(
  select,
  portfolio => portfolio?.mutualFundSortAndFilter.mutualFundFilter
)

export const getMutualFundUniqueNamesList = createSelector(
  select,
  portfolio => {
    const { mutualFundPortfolioData } = portfolio || {}
    const { portfolio: PORTFOLIO = [] } = mutualFundPortfolioData || {}

    const map = new Map(
      PORTFOLIO?.map((obj: ISingleMutualFundPortfolioData) => [
        obj.mutualFundName,
        obj.mutualFundName
      ])
    )

    const mergedArray = Array.from(map.values())
    return mergedArray
  }
)

export const isMutualFundsDefaultFiltersEnabled = createSelector(
  select,
  portfolio => portfolio?.isDefaultFiltersEnabled
)

export const mutualFundQuotesDetailsSelector = createSelector(
  select,
  portfolio => portfolio?.mutualFundQuotesDetails
)
export const formattedDematListSelector = createSelector(
  select,
  portfolio => portfolio?.formattedDematList
)
export const selectedDematScriptSelector = createSelector(
  select,
  portfolio => portfolio?.selectedDematScript
)

export const securityLimitListSelector = createSelector(
  select,
  portfolio => portfolio?.securityLimitList
)

export const selectedSecurityLimitScriptSelector = createSelector(
  select,
  portfolio => portfolio?.selectedSecurityLimitScript
)
export const dematListFilterSelector = createSelector(
  select,
  portfolio => portfolio?.dematListFilter
)
export const filteredDematListSelector = createSelector(
  select,
  portfolio => portfolio?.filteredDematList
)
export const totalCollateralAmountSelector = createSelector(
  select,
  portfolio => portfolio?.totalCollateralAmount
)

export const unsettledListSelector = createSelector(
  select,
  portfolio => portfolio?.unsettledList
)
export const totalUnsettledAmountSelector = createSelector(
  select,
  portfolio => portfolio?.totalUnsettledAmount
)

export const portfolioSelectedTabSelector = createSelector(
  select,
  portfolio => portfolio?.portfolioSelectedTab
)
