import { getPlatformConfigsActions } from '../actions'

import serviceActionCreator from '~/src/Redux/serviceActionCreatorWithTokenRotation'

import { asHttp } from '~/src/Configurations/WebHttp'
import { getSubAccountIdFromRedux } from '~/src/Utils/global'
async function getPlatformConfigs() {
  const options = {
    url: `/platform/configs?platform=web`,
    method: 'POST',
    headers: {
      'X-SubAccountID': getSubAccountIdFromRedux()
    }
  }
  const response = await asHttp.request(options)
  const { data: body } = response
  const { data } = body
  return data
}
const getPlatformConfigsAction = serviceActionCreator(
  getPlatformConfigsActions,
  getPlatformConfigs
)
export default getPlatformConfigsAction
