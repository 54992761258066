type TSortObject = {
  sortBy: string
  sortDirection: 'asc' | 'desc' | undefined
}

export interface ISortModelReducer {
  [key: string]: TSortObject
}

export const INITIAL_STATE: ISortModelReducer = {
  orderbook: {
    sortBy: 'omsOrderTS',
    sortDirection: 'desc'
  },
  holding: {
    sortBy: 'investedValue',
    sortDirection: 'desc'
  },
  mutualFund: {
    sortBy: 'marketValue',
    sortDirection: 'desc'
  },
  position: {
    sortBy: 'netValue',
    sortDirection: 'desc'
  },
  sip: {
    sortBy: 'sipDuration',
    sortDirection: 'desc'
  },
  screeners: {
    sortBy: 'perChange',
    sortDirection: 'asc'
  },
  screeners_most_active_by_volume: {
    sortBy: 'volume',
    sortDirection: 'asc'
  },
  screeners_top_gainers: {
    sortBy: 'perChange',
    sortDirection: 'desc'
  },
  alert: {
    sortBy: 'createdAt',
    sortDirection: 'desc'
  },
  gtd: {
    sortBy: 'symbol',
    sortDirection: 'asc'
  },
  demat: {
    sortBy: 'exchangeSymbol',
    sortDirection: 'asc'
  },
  pledge: {
    sortBy: 'haircutPct',
    sortDirection: 'desc'
  }
}
