import * as yup from 'yup'

import {
  IOrderFormSectionProps,
  TOrderFormSectionState
} from './OrderFormSection'

import TickerStore from '~/src/Configurations/TickerStore'

import { TRIGGER_SLICE_ORDER_MESSAGE } from '~/src/Pages/Orderbook/Orderbook.constants'
function accurateModulus(dividend: number, divisor: number) {
  const precision = 1e10 // Scale factor to handle floating-point precision
  const scaledDividend = Math.round(dividend * precision)
  const scaledDivisor = Math.round(divisor * precision)
  const scaledModulus = scaledDividend % scaledDivisor
  return scaledModulus / precision
}
const orderFormSchema = (
  props: IOrderFormSectionProps,
  states: TOrderFormSectionState
) => {
  const store = TickerStore.getState()
  return yup.object().shape({
    quantity: yup
      .number()
      .required('This cannot be empty')
      .test({
        test(value: any, ctx) {
          const {
            stockDetails,
            orderBuyOrSell,
            holdings,
            isModifyOrder,
            isRepeatOrder,
            orderData,
            sliceOrderConfig
          } = props

          const { sectionType } = orderData

          const { parent } = ctx
          const { orderType, triggerPrice } = parent
          const { maxSingleOrderQty, segment, lotsize } = stockDetails || {}

          // 1 - Slicing for AMO | YES // amo orders are updated in orderform no need to put check there
          // 2 - Slicing for GTDt | NO // sectiontype 1 is for gtd 0 is for normal
          // 3 - Clarification for other segments slicing - | EQ, FNO, Options| // slice for all segment is enable no check on that
          // 4 - Modify order - convert to slice - Not allowed // no slice for modify
          // 5 - Repeat order from Orderbook - Single order  // no slice for repeat
          // 6.- SL Orders No slicing. // triggerPrice check
          // 7 - Slicing allowed or not to be controlled by Config clreated one constant isSliceOrdersEnabled
          // 8. - Number of slices to be configurable

          const { enableSliceOrder, max_slice_limit, allowedSegment } =
            sliceOrderConfig

          const formattedValue =
            segment !== 'EQ' && value && lotsize ? value * lotsize : value

          const isSliceOrder =
            sectionType === 0 &&
            formattedValue > maxSingleOrderQty &&
            !isModifyOrder &&
            !isRepeatOrder &&
            !triggerPrice &&
            maxSingleOrderQty &&
            enableSliceOrder &&
            allowedSegment.includes(segment)

          if (isSliceOrder) {
            if (Number(formattedValue / maxSingleOrderQty) > max_slice_limit) {
              return ctx.createError({
                message: `Slice order limit reached reduce the qty to ${maxSingleOrderQty * max_slice_limit} or less`
              })
            }
            return ctx.createError({
              message: TRIGGER_SLICE_ORDER_MESSAGE
            })
          }
          if (
            segment === 'EQ' &&
            value &&
            maxSingleOrderQty &&
            value > maxSingleOrderQty &&
            orderBuyOrSell === 'BUY' &&
            !isSliceOrder
          ) {
            return ctx.createError({
              message: `Enter a quantity less than or equal to ${maxSingleOrderQty.toLocaleString('en-IN')}`
            })
          }
          if (
            segment !== 'EQ' &&
            value &&
            lotsize &&
            maxSingleOrderQty &&
            value * lotsize > maxSingleOrderQty &&
            orderBuyOrSell === 'BUY' &&
            !isSliceOrder
          ) {
            return ctx.createError({
              message: `Enter a lot size less than or equal to ${
                maxSingleOrderQty / lotsize
              }`
            })
          }
          if (
            value &&
            value > holdings &&
            orderBuyOrSell === 'SELL' &&
            orderType === 'DELIVERY'
          ) {
            return ctx.createError({
              message: `Enter less than the available holdings`
            })
          }
          if (value === 0 || value < 0) {
            return ctx.createError({
              message: `Please enter the correct ${
                segment !== 'EQ' ? 'lot size' : 'quantity'
              }`
            })
          }
          return true
        }
      }),
    price: yup.number().test({
      test(value: any, ctx) {
        const { priceBoxFocused } = states
        const { stockDetails } = props
        const { ticksize, DPRLOW, DPRHIGH } = stockDetails || {}
        const { parent } = ctx
        const { marketDepth, sectionType } = parent
        if (marketDepth !== 'market' && value === undefined) {
          return ctx.createError({
            message: 'This cannot be empty'
          })
        }
        if (
          sectionType === 0 &&
          // this check is to make sure that for GTD this validation does not work. sectionType =0 means normal order form
          (DPRHIGH < value || value < DPRLOW) &&
          marketDepth !== 'market' &&
          priceBoxFocused
        ) {
          return ctx.createError({
            message: 'Price should be within DPR'
          })
        }
        if (
          value % 1 !== 0 &&
          accurateModulus(value, ticksize) !== 0 &&
          marketDepth !== 'market' &&
          priceBoxFocused
        ) {
          return ctx.createError({
            message: 'Price should be multiple of tick price'
          })
        }
        return true
      }
    }),
    triggerPrice: yup.number().test({
      test(value: any, ctx) {
        const { ticker } = store
        const { orderScriptId, stockDetails, orderBuyOrSell } = props
        const { ticksize, DPRLOW, DPRHIGH } = stockDetails || {}
        const scriptTickerData = ticker[orderScriptId]
        const { LTP } = scriptTickerData || {}
        const { parent } = ctx
        const { price, marketDepth, validity } = parent
        if (
          value &&
          value % 1 !== 0 &&
          accurateModulus(value, ticksize) !== 0
        ) {
          return ctx.createError({
            message: 'Price should be multiple of tick price'
          })
        }
        if (validity !== 'IOC' && (DPRHIGH < value || value < DPRLOW)) {
          return ctx.createError({
            message: 'Price should be within DPR'
          })
        }
        if (LTP && value && orderBuyOrSell === 'BUY') {
          if (value < Number(LTP)) {
            return ctx.createError({
              message: 'Enter Price higher than LTP'
            })
          }
          if (marketDepth === 'limit' && value > price) {
            return ctx.createError({
              message: 'Enter Price lower than Limit Price'
            })
          }
        }
        if (LTP && value && orderBuyOrSell === 'SELL') {
          if (value > Number(LTP)) {
            return ctx.createError({
              message: 'Enter Price lower than LTP'
            })
          }
          if (marketDepth === 'limit' && value < price) {
            return ctx.createError({
              message: 'Enter Price higher than Limit Price'
            })
          }
        }
        return true
      }
    }),
    disclosedQty: yup.number().test({
      test(value: any, ctx) {
        const { stockDetails } = props
        const { segment } = stockDetails || {}
        const { parent } = ctx
        const { quantity } = parent
        if (value && value > quantity) {
          return ctx.createError({
            message: `Enter a quantity less than or equal to ${quantity}`
          })
        }
        if (segment === 'COMM') {
          if (value && quantity && quantity * 0.25 > value) {
            return ctx.createError({
              message: 'Enter more than 25% of actual quantity'
            })
          }
        }
        if (value && quantity && quantity * 0.1 > value) {
          return ctx.createError({
            message: 'Enter more than 10% of actual quantity'
          })
        }
        return true
      }
    })
  })
}
export default orderFormSchema
