import { createAction } from '@reduxjs/toolkit'

import traceActionsCreator from '../traceActionsCreator'
import { TAppDrawerData } from './TYPES'
export const clearAppStateDialogServiceName = 'appstate/clearAppStateDialog'
export const clearAppStateDialogActions = createAction<any>(
  clearAppStateDialogServiceName
)
export const setAppStateDialogServiceName = 'appstate/setAppStateDialog'
export const setAppStateDialogActions = createAction<any>(
  setAppStateDialogServiceName
)
export const openAppStateDialogServiceName = 'appstate/openAppStateDialog'
export const openAppStateDialogActions = createAction<any>(
  openAppStateDialogServiceName
)
export const closeAppStateDialogServiceName = 'appstate/closeAppStateDialog'
export const closeAppStateDialogActions = createAction<any>(
  closeAppStateDialogServiceName
)
export const openSetOrderDetailsDrawerDataServiceName =
  'appstate/openSetOrderDetailsDrawerData'
export const openSetOrderDetailsDrawerDataActions = createAction<any>(
  openSetOrderDetailsDrawerDataServiceName
)
export const openOptionDialogServiceName = 'appstate/openOptionDialog'
export const openOptionDialogActions = createAction<TAppDrawerData>(
  openOptionDialogServiceName
)
export const closeOptionDialogServiceName = 'appstate/closeOptionDialog'
export const closeOptionDialogActions = createAction(
  closeOptionDialogServiceName
)
export const openAndSetOrderDetailsDrawerServiceName =
  'appstate/openAndSetOrderDetailsDrawer'
export const openAndSetOrderDetailsDrawerActions = createAction<any>(
  openAndSetOrderDetailsDrawerServiceName
)
export const closeOrderDetailsDrawerServiceName =
  'appstate/closeOrderDetailsDrawer'
export const closeOrderDetailsDrawerActions = createAction(
  closeOrderDetailsDrawerServiceName
)
export const openAndSetCancelOrderDrawerDataServiceName =
  'appstate/openAndSetCancelOrderDrawerData'
export const openAndSetCancelOrderDrawerDataActions = createAction<any>(
  openAndSetCancelOrderDrawerDataServiceName
)
export const closeCancelOrderDrawerServiceName =
  'appstate/closeCancelOrderDrawer'
export const closeCancelOrderDrawerActions = createAction(
  closeCancelOrderDrawerServiceName
)

export const getPlatformConfigsServiceName = 'indicators/getPlatformConfigs'
export const getPlatformConfigsActions = traceActionsCreator(
  getPlatformConfigsServiceName
)

export const servilienceEventsServiceName = 'indicators/servilienceEvents'
export const servilienceEventsActions = traceActionsCreator(
  servilienceEventsServiceName
)
