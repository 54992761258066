import { createSelector } from '@reduxjs/toolkit'

import { TAppStore } from '~/src/Configurations/AppStore'
import { TCumulativePosition, TOpenPosition } from './TYPES'

export const SLICE_NAME = 'positions'

const select = (state: TAppStore) => state[SLICE_NAME]

export const getOpenPositionsData = createSelector(
  select,
  positions => positions.openPositionData
)

const handleGetPositionsWithNonZeroQty = (
  positionsData: TCumulativePosition[] | TOpenPosition[]
) => {
  const nonZeroNetQtyPositionsData = positionsData.filter(
    (singlePosition: TCumulativePosition | TOpenPosition) => {
      const { netQty = 0 } = singlePosition
      return netQty !== 0
    }
  )
  return nonZeroNetQtyPositionsData.length
}

export const getOpenPositionsLength = (isCumulativePositionsAllowed: boolean) =>
  createSelector(select, positions => {
    const {
      openPositionData,
      cumulativePositionsData,
      cumulativePositionsTabIndicator
    } = positions
    const isCommoditiesTabSelected = cumulativePositionsTabIndicator === 3
    const isCumulativeOpenPositionsRequired =
      isCumulativePositionsAllowed && !isCommoditiesTabSelected
    if (isCumulativeOpenPositionsRequired) {
      return handleGetPositionsWithNonZeroQty(cumulativePositionsData)
    }
    return handleGetPositionsWithNonZeroQty(openPositionData)
  })

export const getTotalInvestedValueForOpenPositions = createSelector(
  select,
  positions => positions.totalInvestedAmountForOpenPositions
)

export const getTodayPositionsData = createSelector(
  select,
  positions => positions.todayPositionData
)

export const getTotalInvestedValueForTodayPositions = createSelector(
  select,
  positions => positions.totalInvestedAmountForTodayPositions
)

export const getCumulativePositionsData = createSelector(
  select,
  positions => positions?.cumulativePositionsData
)

export const getTotalInvestedValueForCumulativePositions = createSelector(
  select,
  positions => positions.totalInvestedAmountForCumulativePositions
)

export const isDefaultPositionsFiltersEnabled = createSelector(
  select,
  positions => positions.isDefaultFiltersEnabled
)

export const getCumulativePositionTabIndicatorSelector = createSelector(
  select,
  positions => positions.cumulativePositionsTabIndicator
)

export const isDefaultOrdersFiltersEnabled = createSelector(
  select,
  positions => positions.isDefaultFiltersEnabled
)

export const getPositionsFilter = createSelector(
  select,
  positions => positions?.positionsFilter
)

export const getPositionFilterLabel = createSelector(
  select,
  positions => positions.filterLabels
)

export const scriptToPositionMapperSelector = (scriptId: string) =>
  createSelector(select, positions => {
    return positions?.scriptToPositionMapper?.[scriptId] || {}
  })
