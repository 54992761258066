import { createAction } from '@reduxjs/toolkit'

import traceActionsCreator from '../traceActionsCreator'

export const ssoAuthenticateServiceName = 'sso/ssoAuthenticate'
export const ssoAuthenticateActions = traceActionsCreator(
  ssoAuthenticateServiceName
)

export const ssoInitiateServiceName = 'sso/ssoInitiate'
export const ssoInitiateActions = traceActionsCreator(ssoInitiateServiceName)

export const ssoValidateServiceName = 'sso/ssoValidate'
export const ssoValidateActions = traceActionsCreator(ssoValidateServiceName)

export const ssoCompleteServiceName = 'sso/ssoComplete'
export const ssoCompleteActions = traceActionsCreator(ssoCompleteServiceName)

export const updateCustomerDetailsAction = createAction<any, string>(
  'sso/updateCustomerDetails'
)

export const updateRiskDisclosureFlagAction = createAction<any, string>(
  'sso/updateRiskDisclosureFlag'
)

export const updateSliceOrderBannerAction = createAction<any, string>(
  'sso/updateSliceOrderBanner'
)

export const updateAccessTypeAction = createAction<any, string>(
  'sso/updateAccessType'
)
