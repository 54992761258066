import React, { useEffect, useState } from 'react'
import {
  DsBottomSheet,
  DsBox,
  DsIconButton,
  DsImage,
  DsRemixIcon,
  DsTypography
} from '@am92/react-design-system'

import { haveSeenSliceOrderBannerSelect } from '~/src/Redux/SSO/Selectors'

import { SLICE_ORDER_BOX, SLICE_ORDER_BOX2 } from '~/src/Constants/ASSET_MAP'
import { TAppStore } from '~/src/Configurations/AppStore'

import withErrorConnect from '~/src/Lib/withErrorConnect'

export interface ISliceOrderBoxProps {
  haveSeenSliceOrderBanner: boolean
}

const SliceOrderBox: React.FC<ISliceOrderBoxProps> = props => {
  const [showBox, setShowBox] = useState(false)
  const [showBoxBottomSheet, setShowBoxBottomSheet] = useState(false)

  const { haveSeenSliceOrderBanner } = props

  useEffect(() => {
    setShowBox(!haveSeenSliceOrderBanner)
  }, [])

  return (
    <>
      {/* desktop  */}
      {showBox ? (
        <>
          <DsBox
            sx={{
              display: {
                xs: 'none',
                md: 'block'
              }
            }}
          >
            <DsImage width={'100%'} srcSet={SLICE_ORDER_BOX} />
          </DsBox>
        </>
      ) : (
        <DsBox
          sx={{
            borderRadius: 'var(--ds-radius-glacial)',
            justifyContent: 'space-between',
            height: '40px',
            width: '100%',
            display: {
              xs: 'none',
              md: 'flex'
            },
            alignItems: 'center',
            background: '#FFF6F3',
            p: 'var(--ds-radius-glacial)'
          }}
        >
          <DsTypography variant='bodyBoldSmall'>
            What is Order Slicing?
          </DsTypography>
          <DsIconButton
            sx={{ '.MuiIcon-root': { position: 'unset' } }}
            onClick={() => setShowBox(true)}
          >
            <DsRemixIcon
              className={'ri-arrow-down-s-line'}
              sx={{
                cursor: 'pointer',
                position: 'absolute',
                left: '0',
                top: 'var(--ds-spacing-frostbite)'
              }}
            />
          </DsIconButton>
        </DsBox>
      )}
      {/* mobile  */}
      <DsBox
        sx={{
          borderRadius: 'var(--ds-radius-glacial)',
          justifyContent: 'space-between',
          height: '40px',
          width: '100%',
          display: {
            xs: 'flex',
            md: 'none'
          },
          alignItems: 'center'
        }}
      >
        <DsTypography variant='bodyBoldSmall'>
          What is Order Slicing?
        </DsTypography>
        <DsIconButton
          sx={{ '.MuiIcon-root': { position: 'unset' } }}
          onClick={() => setShowBoxBottomSheet(!showBoxBottomSheet)}
        >
          <DsRemixIcon
            className={'ri-arrow-down-s-line'}
            sx={{
              cursor: 'pointer',
              position: 'absolute',
              left: '0',
              top: 'var(--ds-spacing-frostbite)'
            }}
          />
        </DsIconButton>
      </DsBox>

      {showBoxBottomSheet && (
        <DsBottomSheet
          open={showBoxBottomSheet}
          sx={{
            display: {
              xs: 'block',
              md: 'none'
            },
            '.MuiModal-root': {
              zIndex: 1300
            }
          }}
          onClose={() => setShowBoxBottomSheet(false)}
          title='Order Slicing'
        >
          <DsImage width={'100%'} srcSet={SLICE_ORDER_BOX2} />
        </DsBottomSheet>
      )}
    </>
  )
}

const mapStateToProps = (state: TAppStore) => {
  const haveSeenSliceOrderBanner = haveSeenSliceOrderBannerSelect(state)

  return { haveSeenSliceOrderBanner }
}
const mapDispatchToProps = () => ({
  actions: {}
})

export default withErrorConnect(
  mapStateToProps,
  mapDispatchToProps
)(SliceOrderBox)
