import {
  ActionReducerMapBuilder,
  createSlice,
  CreateSliceOptions
} from '@reduxjs/toolkit'

import {
  closeAppStateDialogActions,
  closeCancelOrderDrawerActions,
  closeOptionDialogActions,
  closeOrderDetailsDrawerActions,
  getPlatformConfigsActions,
  openAndSetCancelOrderDrawerDataActions,
  openAppStateDialogActions,
  openOptionDialogActions,
  openSetOrderDetailsDrawerDataActions,
  servilienceEventsActions,
  setAppStateDialogActions
} from './actions'
import { SLICE_NAME } from './selectors'
import { INITIAL_STATE } from './TYPES'

import { _keyBy } from '~/src/Utils/lodash'

export type TDeepLink = {
  path: string
  parameterList: string[]
}

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    setDeepLinkData: (state, action) => {
      const { payload } = action
      state.deepLinkData = payload
    },
    resetDeepLinkData: state => {
      state.deepLinkData = INITIAL_STATE.deepLinkData
    }
  },
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {
    builder.addCase(setAppStateDialogActions, (state, { payload }) => {
      const { key, data } = payload
      state.dialog[key] = data
    })
    builder.addCase(openAppStateDialogActions, (state, { payload }) => {
      state.dialog[payload] = { open: true }
    })
    builder.addCase(closeAppStateDialogActions, (state, { payload }) => {
      state.dialog[payload] = { open: false }
    })
    builder.addCase(
      openAndSetCancelOrderDrawerDataActions,
      (state, { payload }) => {
        state.cancelOrderDrawer = { open: true, data: payload }
      }
    )
    builder.addCase(
      openSetOrderDetailsDrawerDataActions,
      (state, { payload }) => {
        state.orderDrawer = { open: true, data: payload }
      }
    )
    builder.addCase(openOptionDialogActions, (state, { payload }) => {
      state.optionChainDrawer = { open: true, data: payload }
    })
    builder.addCase(closeOptionDialogActions, state => {
      state.optionChainDrawer = { open: false, data: null }
    })
    builder.addCase(closeOrderDetailsDrawerActions, state => {
      state.orderDrawer = { open: false, data: null }
    })
    builder.addCase(closeCancelOrderDrawerActions, state => {
      state.cancelOrderDrawer = { open: false, data: null }
    })
    builder.addCase(getPlatformConfigsActions.success, (state, { payload }) => {
      const { dashboard_layout, surveillance_indicator, slice_order_config } =
        payload

      if (slice_order_config) {
        state.config['slice_order_config'] = slice_order_config || {}
        return
      }

      state.config['surveillance_indicator'] = surveillance_indicator || {}
      state.config['dashboard_layout'] =
        (dashboard_layout.length && _keyBy(dashboard_layout, 'identifier')) ||
        []
    })
    builder.addCase(servilienceEventsActions.success, (state, { payload }) => {
      state.servilience = payload
    })
  }
}
const slice = createSlice(sliceOptions)
export const { setDeepLinkData, resetDeepLinkData } = slice.actions
export default slice.reducer
