import React from 'react'
import {
  DsBox,
  DsIconButton,
  DsRemixIcon,
  DsStack,
  DsTypography
} from '@am92/react-design-system'

import { T_PLACE_ORDER_PAYLOAD } from '~/src/Redux/Orders/Services/placeOrder.Service'

export interface ISliceOrderModalHeaderProps {
  orderDetails: T_PLACE_ORDER_PAYLOAD
  handleClose: () => void
}

const SliceOrderModalHeader: React.FC<ISliceOrderModalHeaderProps> = props => {
  const { orderDetails, handleClose } = props
  const { scriptId, transactionType } = orderDetails
  const scriptDetails = (window as any).secMaster.getByScriptId(scriptId) || {}
  const { segment, coname, exchangeSymbol, exchange } = scriptDetails

  const backgroundColor =
    transactionType === 'BUY' ? 'supportPositive' : 'supportNegative'

  const formattedLabel =
    segment === 'FO' ? coname : exchangeSymbol || exchangeSymbol

  return (
    <>
      {/* // desktop */}
      <DsBox
        sx={{
          width: '100%',
          display: { xs: 'none', md: 'flex' },
          justifyContent: 'space-between',
          position: 'sticky',
          top: '0',
          left: '0',
          zIndex: 1,
          background: 'var(--ds-colour-surfaceBackground)',
          pb: 'var(--ds-spacing-glacial)',
          alignItems: 'center'
        }}
      >
        <DsBox
          sx={{
            display: 'flex',
            gap: 'var(--ds-spacing-quickFreeze)',
            alignItems: 'center'
          }}
        >
          <DsTypography variant='headingBoldExtraSmall'>
            {formattedLabel}
          </DsTypography>
          <DsTypography variant='supportRegularInfo'>{exchange}</DsTypography>

          <DsStack
            sx={{
              p: 'var(--ds-spacing-quickFreeze) var(--ds-spacing-glacial)',
              backgroundColor: `var(--ds-colour-${backgroundColor})`,
              borderRadius: 'var(--ds-radius-quickFreeze)'
            }}
          >
            <DsTypography
              variant='supportRegularMetadata'
              sx={{ color: 'var(--ds-colour-surfaceBackground)' }}
            >
              {transactionType}
            </DsTypography>
          </DsStack>
        </DsBox>

        <DsIconButton
          sx={{ '.MuiIcon-root': { position: 'unset' } }}
          onClick={handleClose}
        >
          <DsRemixIcon
            className='ri-close-line'
            sx={{
              cursor: 'pointer',
              position: 'absolute',
              left: '0',
              top: 'var(--ds-spacing-frostbite)'
            }}
          />
        </DsIconButton>
      </DsBox>

      {/* // mobile */}

      <DsBox
        sx={{
          width: '100%',
          display: { xs: 'flex', md: 'none' },
          justifyContent: 'space-between',
          position: 'sticky',
          top: '0',
          left: '0',
          zIndex: 1,
          background: 'var(--ds-colour-surfaceBackground)',
          pb: 'var(--ds-spacing-glacial)',
          alignItems: 'center'
        }}
      >
        <DsBox sx={{ display: 'flex', gap: 'var(--ds-radius-quickFreeze)' }}>
          <DsIconButton
            sx={{ '.MuiIcon-root': { position: 'unset' } }}
            onClick={handleClose}
          >
            <DsRemixIcon
              className='ri-arrow-left-line'
              sx={{
                cursor: 'pointer',
                position: 'absolute',
                left: '0',
                top: 'var(--ds-spacing-frostbite)'
              }}
            />
          </DsIconButton>

          <DsStack
            sx={{
              gap: 'var(--ds-spacing-quickFreeze)',
              alignItems: 'center'
            }}
          >
            <DsTypography variant='headingBoldExtraSmall'>
              {exchangeSymbol}
            </DsTypography>
            <DsTypography variant='supportRegularInfo'>{exchange}</DsTypography>
          </DsStack>
        </DsBox>

        <DsStack
          sx={{
            p: 'var(--ds-spacing-quickFreeze) var(--ds-spacing-glacial)',
            backgroundColor: `var(--ds-colour-${backgroundColor})`,
            borderRadius: 'var(--ds-radius-quickFreeze)'
          }}
        >
          <DsTypography
            variant='supportRegularMetadata'
            sx={{ color: 'var(--ds-colour-surfaceBackground)' }}
          >
            {transactionType}
          </DsTypography>
        </DsStack>
      </DsBox>
    </>
  )
}

export default SliceOrderModalHeader
