import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice
} from '@reduxjs/toolkit'

import { SLICE_NAME } from './Selectors'
import { updateSortAction } from './Actions'
import { INITIAL_STATE } from './TYPES'

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {
    builder.addCase(updateSortAction, (state, action) => {
      console.log('sortchange', action)
      const { payload } = action
      const { sectionName, sortBy, sortDirection } = payload
      state[sectionName].sortBy = sortBy
      state[sectionName].sortDirection = sortDirection
    })
  }
}

const sortModelSlice = createSlice(sliceOptions)
export default sortModelSlice.reducer
