import React, { useState } from 'react'
import {
  DsBottomSheet,
  DsBox,
  DsDialog,
  DsIconButton,
  DsRemixIcon,
  DsStack,
  DsTypography,
  IwithBreakpoints,
  withBreakpoints
} from '@am92/react-design-system'

import { FormValues } from '../OrderFormSection'
import ViewChargesContent from '../ViewChargesContent'
import SliceOrderBox from './SliceOrderBox'
import SliceOrderModalBottom from './SliceOrderModalBottom'
import SliceOrderModalHeader from './SliceOrderModalHeader'
import SliceOrderOverview from './SliceOrderOverview'
import SliceOrderSummary from './SliceOrderSummary'

import { getSliceConfigSelector } from '~/src/Redux/AppState/selectors'
import { TSliceConfig } from '~/src/Redux/AppState/TYPES'
import {
  getOrderScriptId,
  getRequiredMarginData
} from '~/src/Redux/Orders/selectors'
import { T_PLACE_ORDER_PAYLOAD } from '~/src/Redux/Orders/Services/placeOrder.Service'
import { updateSliceOrderBannerAction } from '~/src/Redux/SSO/Actions'
import { T_SCRIPTDATA } from '~/src/Redux/StockMaster/Reducer'
import { getStockMasterData } from '~/src/Redux/StockMaster/Selectors'

import { TAppDispatch, TAppStore } from '~/src/Configurations/AppStore'
import { getDeviceActiveScreen } from '~/src/Utils/deviceDetails'

import withErrorConnect from '~/src/Lib/withErrorConnect'

export interface ISliceOrderModalProps extends IwithBreakpoints {
  open: boolean
  orderDetails: T_PLACE_ORDER_PAYLOAD
  stockDetails: T_SCRIPTDATA
  margin: number
  values: FormValues
  onClose: (item: boolean) => void
  handlePlaceOrder: (item: FormValues, type: string) => Promise<any>
  actions: ActionType
  sliceOrderConfig: TSliceConfig
}

type ActionType = {
  updateSliceBannerSeen: (req: boolean) => void
}

const SliceOrderModal: React.FC<ISliceOrderModalProps> = props => {
  const {
    open,
    onClose,
    breakpoints = {},
    orderDetails,
    values,
    stockDetails,
    margin,
    handlePlaceOrder,
    actions,
    sliceOrderConfig
  } = props
  const [showTax, setShowTax] = useState(false)
  const { scriptId } = orderDetails
  const { isDesktop } = getDeviceActiveScreen(breakpoints)

  const { coname, exchangeSymbol, segment } = stockDetails || {}
  const scriptName = segment === 'EQ' ? exchangeSymbol : coname
  const { orderType, price, quantity } = values
  const orderValue = Number(price) * Number(quantity)

  const handleClose = () => {
    onClose(false)
  }

  const handleRenderTax = () => {
    setShowTax(!showTax)
  }

  return (
    <>
      <DsDialog
        PaperProps={{
          sx: {
            width: isDesktop ? '480px' : '100%',
            height: isDesktop ? '730px' : '100%',
            borderRadius: {
              xs: 'var(--ds-spacing-zero)',
              md: 'var(--ds-spacing-bitterCold)'
            },
            padding: 0,
            margin: 0,

            '.MuiDialogContent-root': {
              marginTop: 'var(--ds-spacing-zero)',
              p: '0 1.5rem'
            }
          }
        }}
        sx={theme => ({
          padding: 0,
          position: 'fixed',
          zIndex: theme.zIndex.drawer
        })}
        showClose={false}
        onClose={handleClose}
        fullScreen={!isDesktop}
        open={open}
      >
        <SliceOrderModalHeader
          handleClose={handleClose}
          orderDetails={orderDetails}
        />
        <DsStack
          gap={'var(--ds-spacing-mild)'}
          paddingY={'var(--ds-spacing-mild)'}
          paddingBottom={'5rem'}
          sx={{
            overflow: 'scroll'
          }}
        >
          <SliceOrderBox />
          <SliceOrderOverview orderDetails={orderDetails} margin={margin} />
          <SliceOrderSummary
            orderDetails={orderDetails}
            stockDetails={stockDetails}
            sliceOrderConfig={sliceOrderConfig}
          />
          <DsBox>
            <DsBox sx={{ display: 'flex', alignItems: 'center' }}>
              <DsTypography
                variant='bodyRegularSmall'
                sx={{
                  textDecoration: 'underline',
                  textDecorationStyle: 'dotted'
                }}
              >
                View tax & charges
              </DsTypography>
              <DsIconButton
                sx={{ '.MuiIcon-root': { position: 'unset' } }}
                onClick={handleRenderTax}
              >
                <DsRemixIcon
                  className={
                    showTax ? 'ri-arrow-up-s-line' : 'ri-arrow-down-s-line'
                  }
                  sx={{
                    cursor: 'pointer',
                    position: 'absolute',
                    left: '0',
                    top: 'var(--ds-spacing-frostbite)'
                  }}
                />
              </DsIconButton>
            </DsBox>

            {showTax ? (
              <>
                <DsStack
                  sx={{
                    display: { xs: 'none', md: 'flex' },
                    border: '1px solid var(--ds-colour-strokeDefault)',
                    borderRadius: 'var(--ds-radius-glacial)',
                    p: 'var(--ds-spacing-bitterCold)',
                    mt: 'var(--ds-spacing-bitterCold)'
                  }}
                >
                  <DsBox
                    sx={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-between'
                    }}
                  >
                    <DsTypography variant='headingBoldSmall'>
                      Tax & Charges
                    </DsTypography>
                    <DsIconButton
                      sx={{ '.MuiIcon-root': { position: 'unset' } }}
                      onClick={handleRenderTax}
                    >
                      <DsRemixIcon
                        className='ri-close-line'
                        sx={{
                          cursor: 'pointer',
                          position: 'absolute',
                          left: '0',
                          top: 'var(--ds-spacing-frostbite)'
                        }}
                      />
                    </DsIconButton>
                  </DsBox>
                  <ViewChargesContent
                    coname={scriptName}
                    orderType={orderType}
                    orderValue={orderValue}
                    quantity={quantity}
                    marketDepth={values.marketDepth}
                    scriptId={scriptId}
                    price={price}
                    openViewTaxChargesModal={true}
                  />
                </DsStack>
              </>
            ) : null}
          </DsBox>
        </DsStack>

        <DsBottomSheet
          open={showTax}
          sx={{
            display: {
              xs: 'block',
              md: 'none'
            }
          }}
          onClose={() => setShowTax(!showTax)}
          title='Tax & Charges'
        >
          <DsStack gap={3}>
            {showTax && (
              <ViewChargesContent
                coname={scriptName}
                orderType={orderType}
                orderValue={orderValue}
                quantity={quantity}
                marketDepth={values.marketDepth}
                scriptId={scriptId}
                price={price}
                openViewTaxChargesModal={showTax}
              />
            )}
          </DsStack>
        </DsBottomSheet>

        <SliceOrderModalBottom
          orderDetails={orderDetails}
          values={values}
          stockDetails={stockDetails}
          handlePlaceOrder={handlePlaceOrder}
          handleCloseDetailsModal={handleClose}
          updateSliceBannerSeen={actions.updateSliceBannerSeen}
          sliceOrderConfig={sliceOrderConfig}
        />
      </DsDialog>
    </>
  )
}

const mapStateToProps = (state: TAppStore) => {
  const orderScriptId = getOrderScriptId(state)
  const stockDetails = getStockMasterData(orderScriptId)(state)
  const sliceOrderConfig = getSliceConfigSelector(state)
  const requiredMargin = getRequiredMarginData(state)

  const { margin } = requiredMargin
  return { stockDetails, margin, sliceOrderConfig }
}
const mapDispatchToProps = (dispatch: TAppDispatch) => ({
  actions: {
    updateSliceBannerSeen: (req: boolean) =>
      dispatch(updateSliceOrderBannerAction(req))
  }
})

export default withBreakpoints(
  withErrorConnect(mapStateToProps, mapDispatchToProps)(SliceOrderModal)
)
