import React from 'react'
import { DsTypography } from '@am92/react-design-system'

import { T_SIP_INFO, TSelectedStock } from '~/src/Redux/Orders/TYPES'
import { getLtpTickSelector } from '~/src/Redux/StockTicker/Selectors'

import { tickerContext, TTickerStore } from '~/src/Configurations/TickerStore'
import { getCurrencyFormat } from '~/src/Utils/global'

import withErrorConnect from '~/src/Lib/withErrorConnect'

interface SipScriptsDetailsAmtValueProps {
  updateSelectedScriptsData: (item: any) => any
  item: TSelectedStock
  sipInfo: T_SIP_INFO
  value: number
  LTP: number
  isModifing: boolean
  closeprice: number | string
}

class SipScriptsDetailsAmtValue extends React.Component<SipScriptsDetailsAmtValueProps> {
  async componentDidUpdate(
    prevProps: Readonly<SipScriptsDetailsAmtValueProps>
  ): Promise<void> {
    const { value, item, closeprice } = this.props
    if (prevProps.value !== value) {
      const { sipInfo, LTP, isModifing, updateSelectedScriptsData } = this.props
      const { basedOn } = sipInfo
      const { scriptId } = item
      const formattedValue = LTP ? LTP : closeprice || 0
      const formattedBasedOn =
        basedOn.toLowerCase() === 'quantity' ? 'quantity' : 'amount'
      const formattedSecondValue =
        formattedBasedOn === 'amount' ? 'quantity' : 'amount'

      const finalValue =
        formattedSecondValue === 'quantity'
          ? Number(value) / Number(formattedValue)
          : Number(formattedValue) * Number(value)

      let formattedData = {}

      if (isModifing) {
        formattedData = {
          [formattedBasedOn]: value,
          [formattedSecondValue]: finalValue
        }
      } else {
        formattedData = {
          [formattedBasedOn]: value,
          [formattedSecondValue]: finalValue
        }
      }

      await updateSelectedScriptsData({ scriptId, formattedData })
    }
  }

  componentDidMount() {
    const { sipInfo, LTP, isModifing, updateSelectedScriptsData } = this.props

    const { value, item, closeprice } = this.props
    const { basedOn } = sipInfo
    const { scriptId } = item
    const formattedValue = LTP ? LTP : closeprice || 0
    const formattedBasedOn =
      basedOn.toLowerCase() === 'quantity' ? 'quantity' : 'amount'
    const formattedSecondValue =
      formattedBasedOn === 'amount' ? 'quantity' : 'amount'

    const finalValue =
      formattedSecondValue === 'quantity'
        ? Number(value) / Number(formattedValue)
        : Number(formattedValue) * Number(value)

    let formattedData = {}

    if (isModifing) {
      formattedData = {
        [formattedBasedOn]: value,
        [formattedSecondValue]: finalValue
      }
    } else {
      formattedData = {
        [formattedBasedOn]: value,
        [formattedSecondValue]: finalValue
      }
    }

    updateSelectedScriptsData({ scriptId, formattedData })
  }

  render() {
    const { value, sipInfo, LTP, closeprice } = this.props
    const { basedOn } = sipInfo

    const formattedValue = LTP ? LTP : closeprice || 0

    const normalizedBasedOn = basedOn.toLowerCase()

    const finalValue =
      normalizedBasedOn === 'amount'
        ? getCurrencyFormat(
            Math.floor(Number(value) / Number(formattedValue)),
            {
              style: 'decimal'
            }
          )
        : getCurrencyFormat(Number(formattedValue) * Number(value), {
            style: 'decimal'
          }) || 0

    return (
      <>
        <DsTypography
          sx={{ color: 'var(--ds-colour-typoSecondary)' }}
          variant='bodyRegularSmall'
        >
          {finalValue}
        </DsTypography>
      </>
    )
  }
}
const mapStateToProps = (state: TTickerStore, ownProps: any) => {
  const { item } = ownProps
  const LTP = getLtpTickSelector(state, item.scriptId)

  return {
    LTP
  }
}

const mapDispatchToProps = () => ({
  actions: {}
})

export default withErrorConnect(mapStateToProps, mapDispatchToProps, null, {
  context: tickerContext
})(SipScriptsDetailsAmtValue)
