import React, { useEffect } from 'react'
import { enqueueNotistack } from '@am92/react-design-system'

import { useNetworkStatus } from './hooks/useNetworkStatus'

const NetworkStatusToast = () => {
  const isOnline = useNetworkStatus()

  useEffect(() => {
    if (!isOnline) {
      enqueueNotistack({
        message: 'Check your internet connection',
        autoHideDuration: 3000
      })
    }
  }, [isOnline])

  return <></>
}

export default NetworkStatusToast
