import { combineReducers, ReducersMapObject } from 'redux'
import { persistReducer } from 'redux-persist'

import HistoricalDataReducer from './/HistoricalData/Reducer'
import AlertsReducer from './Alerts/Reducer'
import { SLICE_NAME as AlertsSliceName } from './Alerts/Selectors'
import AppStateReducer from './AppState/reducer'
import { SLICE_NAME as appState } from './AppState/selectors'
import AuthReducer from './Auth/Reducer'
import { SLICE_NAME as AuthSliceName } from './Auth/Selectors'
import CustomerReducer from './Customer/Reducer'
import { SLICE_NAME as CustomerSliceName } from './Customer/Selectors'
import DashboardReducer from './Dashboard/Reducer'
import { SLICE_NAME as DashboardSliceName } from './Dashboard/Selectors'
import { T_DASHBOARD_REDUCER } from './Dashboard/TYPES'
import DerivativesReducer from './Derivatives/Reducer'
import { SLICE_NAME as DerivativesSliceName } from './Derivatives/Selectors'
import ErrorReducer from './Error/Reducer'
import { SLICE_NAME as ErrorSliceName } from './Error/Selectors'
import EventsReducer from './Events/reducer'
import { SLICE_NAME as EventsSliceName } from './Events/selectors'
import ExchangeNotificationReducer from './ExchangeNotification/Reducer'
import { SLICE_NAME as ExchangeNotificationSliceName } from './ExchangeNotification/Selectors'
import { SLICE_NAME as HistoricalDataName } from './HistoricalData/Selectors'
import IndicatorsReducer from './Indicators/Reducer'
import { SLICE_NAME as IndicatorsSliceName } from './Indicators/Selectors'
import FundsReducer from './Inquiry/Reducer'
import { SLICE_NAME as FundsSliceName } from './Inquiry/Selectors'
import NewsReducer from './News/reducer'
import { SLICE_NAME as NewsSliceName } from './News/selectors'
import NotificationsReducer from './Notifications/Reducer'
import { SLICE_NAME as NotificationsSliceName } from './Notifications/Selectors'
import OrdersReducer, { ordersPersistConfig } from './Orders/reducer'
import { SLICE_NAME as OrdersSliceName } from './Orders/selectors'
import { T_ORDER_REDUCER } from './Orders/TYPES'
import PledgeDataReducer from './Pledge/reducer'
import { SLICE_NAME as PledgeDataSliceName } from './Pledge/selectors'
import PortfolioReducer, { portfolioPersistConfig } from './Portfolio/reducer'
import { SLICE_NAME as PortfolioSliceName } from './Portfolio/selectors'
import PositionsReducer from './Positions/reducer'
import { SLICE_NAME as PositionsSliceName } from './Positions/selectors'
import { TPositionsReducer } from './Positions/TYPES'
import ResearchAndRecommendationReducer from './ResearchAndRecommendation/Reducers'
import { SLICE_NAME as ResearchAndRecommendationSliceName } from './ResearchAndRecommendation/Selectors'
import ReverseFeedReducer from './ReverseFeed/Reducer'
import { SLICE_NAME as ReverseFeedSliceName } from './ReverseFeed/Selectors'
import ScreenersReducer from './Screeners/Reducers'
import { SLICE_NAME as ScreenersSliceName } from './Screeners/Selectors'
import SearchReducer from './Search/Reducer'
import { SLICE_NAME as SearchSliceName } from './Search/Selectors'
import SSOReducer, { ssoPersistConfig } from './SSO/Reducer'
import { SLICE_NAME as SSOSliceName } from './SSO/Selectors'
import StockMasterReducer from './StockMaster/Reducer'
import { SLICE_NAME as StockMasterSliceName } from './StockMaster/Selectors'
import SelectedStockReducer from './Stocks/Reducer'
import { SLICE_NAME as SelectedStockSliceName } from './Stocks/Selectors'
import StockWatchlistMasterReducer from './StockWatchlistMaster/Reducer'
import { SLICE_NAME as StockWatchlistMasterSliceName } from './StockWatchlistMaster/Selectors'
import ThemeReducer from './Theme/Reducer'
import { SLICE_NAME as ThemeSliceName } from './Theme/Selectors'
import { T_THEME_REDUCER } from './Theme/TYPES'
import WatchlistReducer from './WatchList/Reducer'
import { SLICE_NAME as WatchlistSliceName } from './WatchList/Selectors'

import { SLICE_NAME as SortModelSliceName } from './SortModel/Selectors'
import { ISortModelReducer } from './SortModel/TYPES'
import SortModelReducer from './SortModel/Reducer'

import ServiceTrackerReducer from './ServiceTracker/Reducer'
import { SLICE_NAME as ServiceTrackerSliceName } from './ServiceTracker/Selectors'
import { IAppStateReducer } from './AppState/TYPES'

export type TReducers = {
  [ThemeSliceName]: T_THEME_REDUCER
  [DashboardSliceName]: T_DASHBOARD_REDUCER
  [OrdersSliceName]: T_ORDER_REDUCER
  [PositionsSliceName]: TPositionsReducer
  [appState]: IAppStateReducer
  [SortModelSliceName]: ISortModelReducer
  [AuthSliceName]: any // TODO - PRIORITY: define valid type
  [IndicatorsSliceName]: any // TODO - PRIORITY: define valid type
  [WatchlistSliceName]: any // TODO - PRIORITY: define valid type
  [StockMasterSliceName]: any // TODO - PRIORITY: define valid type
  [StockWatchlistMasterSliceName]: any // TODO - PRIORITY: define valid type
  [SelectedStockSliceName]: any // TODO - PRIORITY: define valid type
  [ServiceTrackerSliceName]: any // TODO - PRIORITY: define valid type
  [SSOSliceName]: any // TODO - PRIORITY: define valid type
  [ErrorSliceName]: any // TODO - PRIORITY: define valid type
  [FundsSliceName]: any // TODO - PRIORITY: define valid type
  [PledgeDataSliceName]: any // TODO - PRIORITY: define valid type
  [SearchSliceName]: any // TODO - PRIORITY: define valid type
  [CustomerSliceName]: any // TODO - PRIORITY: define valid type
  [NewsSliceName]: any // TODO - PRIORITY: define valid type
  [EventsSliceName]: any // TODO - PRIORITY: define valid type
  [ScreenersSliceName]: any // TODO - PRIORITY: define valid type
  [HistoricalDataName]: any // TODO - PRIORITY: define valid type
  [ExchangeNotificationSliceName]: any // TODO - PRIORITY: define valid type
  [DerivativesSliceName]: any // TODO - PRIORITY: define valid type
  [ResearchAndRecommendationSliceName]: any // TODO - PRIORITY: define valid type
  [NotificationsSliceName]: any // TODO - PRIORITY: define valid type
  [AlertsSliceName]: any // TODO - PRIORITY: define valid type
  [ReverseFeedSliceName]: any // TODO - PRIORITY: define valid type
  [PortfolioSliceName]: any // TODO - PRIORITY: define valid type
}

const reducers: ReducersMapObject<TReducers> = {
  [ThemeSliceName]: ThemeReducer,
  [AuthSliceName]: AuthReducer,
  [IndicatorsSliceName]: IndicatorsReducer,
  [WatchlistSliceName]: WatchlistReducer,
  [DashboardSliceName]: DashboardReducer,
  [StockMasterSliceName]: StockMasterReducer,
  [StockWatchlistMasterSliceName]: StockWatchlistMasterReducer,
  [SelectedStockSliceName]: SelectedStockReducer,
  [ServiceTrackerSliceName]: ServiceTrackerReducer,
  [SSOSliceName]: persistReducer(ssoPersistConfig, SSOReducer),
  [ErrorSliceName]: ErrorReducer,
  [FundsSliceName]: FundsReducer,
  [OrdersSliceName]: persistReducer(ordersPersistConfig, OrdersReducer),
  [PositionsSliceName]: PositionsReducer,
  [PledgeDataSliceName]: PledgeDataReducer,
  [SearchSliceName]: SearchReducer,
  [CustomerSliceName]: CustomerReducer,
  [NewsSliceName]: NewsReducer,
  [EventsSliceName]: EventsReducer,
  [ScreenersSliceName]: ScreenersReducer,
  [HistoricalDataName]: HistoricalDataReducer,
  [ExchangeNotificationSliceName]: ExchangeNotificationReducer,
  [DerivativesSliceName]: DerivativesReducer,
  [ResearchAndRecommendationSliceName]: ResearchAndRecommendationReducer,
  [NotificationsSliceName]: NotificationsReducer,
  [AlertsSliceName]: AlertsReducer,
  [ReverseFeedSliceName]: ReverseFeedReducer,
  [PortfolioSliceName]: persistReducer(
    portfolioPersistConfig,
    PortfolioReducer
  ),
  [appState]: AppStateReducer,
  [SortModelSliceName]: SortModelReducer
}

export default combineReducers<ReducersMapObject<TReducers>>(reducers)
export const persistedReducers: (keyof TReducers)[] = []
