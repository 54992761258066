import React, { Component } from 'react'
import { DsTypography } from '@am92/react-design-system'

import { getLtpTickSelector } from '~/src/Redux/StockTicker/Selectors'

import { tickerContext, TTickerStore } from '~/src/Configurations/TickerStore'
import { getCurrencyFormat } from '~/src/Utils/global'

import withErrorConnect from '~/src/Lib/withErrorConnect'

interface IBnplValueProps {
  scriptId: string
  closeprice: number
  LTP: number
  eMarginPercentage: number
  forceRender: boolean
  options: Intl.NumberFormatOptions
  isEmarginActiveFlag: boolean
  quantity: number
  price: number
  marketDepth: string
}

interface IBnplValueStates {
  showLTP: boolean
  bnplValue: number
}

class BnplValue extends Component<IBnplValueProps, IBnplValueStates> {
  constructor(props: IBnplValueProps) {
    super(props)
    this.state = {
      showLTP: false,
      bnplValue: 0
    }
  }

  componentDidUpdate(prevProps: Readonly<IBnplValueProps>): void {
    const { LTP, forceRender, quantity, price, marketDepth } = this.props
    const { forceRender: PREV_FORCE_RENDER } = prevProps
    const { showLTP } = this.state

    if (marketDepth === 'market' && LTP && !showLTP) {
      this.setState({ bnplValue: LTP * quantity, showLTP: true })
    }

    if (marketDepth === 'limit' && !showLTP) {
      this.setState({ bnplValue: (price || 0) * quantity, showLTP: true })
    }

    if (forceRender !== PREV_FORCE_RENDER) {
      this.setState({ showLTP: false })
    }
  }

  render() {
    const { bnplValue } = this.state
    const { eMarginPercentage = 0, options, isEmarginActiveFlag } = this.props
    const bnplValueOnEmarginPercentage =
      eMarginPercentage && bnplValue * eMarginPercentage * 0.01
    return (
      <>
        {(bnplValueOnEmarginPercentage && isEmarginActiveFlag && (
          <>
            <DsTypography
              variant='bodyRegularSmall'
              sx={{ textDecoration: 'line-through' }}
              color='var(--ds-colour-typoTertiary)'
            >
              {getCurrencyFormat(bnplValue, options)}
            </DsTypography>{' '}
            <DsTypography variant='bodyRegularSmall'>
              {getCurrencyFormat(bnplValueOnEmarginPercentage, options)}
            </DsTypography>
          </>
        )) || (
          <DsTypography variant='bodyRegularSmall'>
            {getCurrencyFormat(bnplValueOnEmarginPercentage, options)}
          </DsTypography>
        )}
      </>
    )
  }
}

const mapStateToProps = (state: TTickerStore, ownProps: any) => {
  const { scriptId, closeprice } = ownProps
  const LTP = getLtpTickSelector(state, scriptId) || closeprice
  return {
    LTP
  }
}

const mapDispatchToProps = () => ({
  actions: {}
})

export default withErrorConnect(mapStateToProps, mapDispatchToProps, null, {
  context: tickerContext
})(BnplValue)
