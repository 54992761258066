import { createSelector } from '@reduxjs/toolkit'

import { TAppStore } from '~/src/Configurations/AppStore'
import { getPrimaryDpAccount } from '~/src/Utils/global'

const segmentMapping: { [key: number]: string[] } = {
  0: ['nseCash', 'bseCash'],
  1: ['nseFO'],
  2: ['nseCdx'],
  3: ['mcxCmx', 'ncdexCmx']
}

// Selectors
export const SLICE_NAME = 'customer'
const select = (state: TAppStore) => state[SLICE_NAME]
const customerProfileSelect = (state: TAppStore) =>
  state[SLICE_NAME].customerProfile
export const bankAccountDetailsSelect = (state: TAppStore) =>
  state[SLICE_NAME].customerProfile?.bankAccountDetails
export const bankBalanceSelect = (state: TAppStore) =>
  state[SLICE_NAME].bankbalance
export const dpAccountDetailsSelect = (state: any) =>
  state[SLICE_NAME].customerProfile?.dpAccountDetails

export const isSegmentEnabledSelector = (
  cumulativePositionsTabIndicator: number
) =>
  createSelector(customerProfileSelect, customerProfileSelect => {
    const { segmentsEnabled } = customerProfileSelect
    let isSegmentEnabled = false
    segmentMapping[cumulativePositionsTabIndicator].map((segment: string) => {
      if (segmentsEnabled[segment]) {
        isSegmentEnabled = true
      }
    })
    return isSegmentEnabled
  })

export const isCumulativePositionsAllowedSelector = createSelector(
  customerProfileSelect,
  customerProfile => customerProfile?.cumulativePositionsAllowed
)

export const getCustomerProfileSelector = createSelector(
  customerProfileSelect,
  customerProfile => customerProfile
)

export const isNSEFOEnabled = createSelector(
  customerProfileSelect,
  customerProfile => customerProfile.segmentsEnabled?.nseFO
)

export const pledgeNudgeDetailsSelector = createSelector(
  customerProfileSelect,
  customerProfile => {
    const { productsAllowed } = customerProfile || {}
    const { pledgeMargin } = productsAllowed || {}
    const { isActive, metadata } = pledgeMargin || {}
    const { code = '' } = metadata || {}
    const showPledgeNudge = isActive || code !== 'ERR_OMS_UNIMPLEMENTED'
    return {
      showPledgeNudge,
      pledgeMarginActive: isActive,
      code
    }
  }
)

//Get bank details
const _getActiveBankAccount = (data: any) => {
  return data && Object.keys(data).length > 0
    ? data.find((d: { isDefault: boolean }) => {
        return d?.isDefault === true
      })
    : {}
}

export const currentActiveBankAccountSelector = createSelector(
  bankAccountDetailsSelect,
  bankAccountDetails => _getActiveBankAccount(bankAccountDetails)
)

// HRT allowed check
const _getIsHRTAllowed = (data: any) => {
  const { hrtAllowed = false } = data || {}
  return hrtAllowed
}

export const isHRTAllowedSelector = createSelector(
  currentActiveBankAccountSelector,
  bankAccountDetail => _getIsHRTAllowed(bankAccountDetail)
)

//POA customer select
const _getIsThreeInOneCustomer = (data: any) => {
  const { hasPOA = '', hrtAllowed = '' } = data || {}
  return hasPOA === true && hrtAllowed === true
}

export const isThreeInOneCustomerSelector = createSelector(
  currentActiveBankAccountSelector,
  bankAccountDetail => _getIsThreeInOneCustomer(bankAccountDetail)
)

// bank balance
export const getAvailableBalanceSelector = createSelector(
  bankBalanceSelect,
  bankbalance => bankbalance?.balance?.availableBalance
)

// commodity check
const _getUserCommodityValidation = (data: any) => {
  const { segmentsEnabled } = data
  const { mcxCmx = '', ncdexCmx = '' } = segmentsEnabled || {}

  if (mcxCmx || ncdexCmx) {
    return true
  }
  return false
}

export const isUserHaveCommodity = createSelector(
  customerProfileSelect,
  customerProfile => _getUserCommodityValidation(customerProfile)
)

// spot check
export const checkSpotActiveSelector = createSelector(
  customerProfileSelect,
  customerProfile => customerProfile.productsAllowed?.spot?.isActive
)

export const isEmarginActive = createSelector(select, customer => {
  const { customerProfile = {} } = customer
  const { emargin = {} } = customerProfile?.productsAllowed || {}
  return emargin?.isActive
})

export const getEMarginDetails = createSelector(select, customer => {
  const { emarginDetails = {} } = customer
  const eMarginPercentage =
    emarginDetails?.marginDetails?.eMarginPercentage || 0
  const isEmarginPreviledged =
    emarginDetails?.commonPrivilegeDetails?.emarginPrivilege || false
  return { eMarginPercentage, isEmarginPreviledged }
})

export const getDpIdAndEdisFlagOfCustomer = createSelector(select, customer => {
  const { customerProfile = {} } = customer
  const { dpAccountDetails } = customerProfile
  const { dpId, edis } = getPrimaryDpAccount(dpAccountDetails) || {}
  const { isActive } = edis || {}
  return {
    dpId,
    isEdisActive: isActive
  }
})

// dp account details selector
const _getActiveDpAccount = (data: any) => {
  return data && Object.keys(data).length > 0
    ? data.find((d: { dpDefault: boolean }) => {
        return d?.dpDefault === true
      })
    : {}
}

export const currentActiveDpAccountSelector = createSelector(
  dpAccountDetailsSelect,
  dpAccountDetails => _getActiveDpAccount(dpAccountDetails)
)

// partners selectors
export const getPartnertsCategoriesList = createSelector(select, partners =>
  Object.keys(partners.partners)
)

export const getPartnersList = createSelector(
  select,
  partners => partners.formattedPartners
)

export const partners = createSelector(select, partners => partners.partners)

export const getResearchPartnersList = createSelector(
  select,
  partners => partners.researchPartners
)

//reports selector
export const getReportsList = createSelector(select, data => data.reports)

export const selectedPartner = createSelector(
  select,
  data => data?.selectedPartner
)

export const openPartnerSearch = createSelector(
  select,
  data => data?.openPartnerSearch
)
export const partnerSearchString = createSelector(
  select,
  data => data?.searchString
)
export const fundsSegment = createSelector(
  select,
  data => data?.selectedFundsSegment
)

// gtd and sip allowed selector
export const isGTDAllowedSelector = createSelector(select, customer => {
  const { customerProfile = {} } = customer
  const { productsAllowed } = customerProfile
  const { gtd } = productsAllowed || {}
  const { isActive } = gtd || {}
  return isActive
})

export const isSIPAllowedSelector = createSelector(select, customer => {
  const { customerProfile = {} } = customer
  const { productsAllowed } = customerProfile
  const { sip } = productsAllowed || {}
  const { isActive } = sip || {}
  return isActive
})
