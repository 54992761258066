import React from 'react'
import { DsTypography, DsTypographyProps } from '@am92/react-design-system'
import { connect, ConnectedProps, DispatchProp } from 'react-redux'

// context
import { tickerContext, TTickerStore } from '~/src/Configurations/TickerStore'

// selectors
import { getRequiredTickerDataSelector } from '~/src/Redux/StockTicker/Selectors'

// utils
import { getCurrencyFormat } from '~/src/Utils/global'
import { AnyAction } from 'redux'

interface IStockTickerWithLtpProps {
  scriptId: string
  closeprice: number
  segment: string
  withCurrencySymbol?: boolean
  LtpTypoProps?: DsTypographyProps
  showLtpWithHighlight?: boolean
}

type IStockTickerWithLtpState = {
  LtpTypoPropsFromState: DsTypographyProps
}

class StockTickerWithLTP extends React.PureComponent<
  IStockTickerWithLtpProps & PropsFromRedux,
  IStockTickerWithLtpState
> {
  constructor(props: IStockTickerWithLtpProps & PropsFromRedux) {
    super(props)
    this.state = {
      LtpTypoPropsFromState: {}
    }
  }

  componentDidUpdate(
    prevProps: Readonly<
      IStockTickerWithLtpProps &
        DispatchProp<AnyAction> & { LTP: any; NET_CHANGE: any }
    >
  ): void {
    const { NET_CHANGE, showLtpWithHighlight, LtpTypoProps } = this.props
    const { NET_CHANGE: prevNetChange } = prevProps
    if (showLtpWithHighlight && prevNetChange !== NET_CHANGE) {
      this.setState({
        LtpTypoPropsFromState: {
          color: `var(--ds-colour-${NET_CHANGE > 0 ? 'iconPositive' : 'iconNegative'})`,
          variant: 'bodyBoldSmall'
        }
      })
      setTimeout(() => {
        this.setState({
          LtpTypoPropsFromState: {
            ...LtpTypoProps
          }
        })
      }, 500)
    }
  }

  render() {
    const {
      segment,
      withCurrencySymbol = true,
      LtpTypoProps,
      LTP,
      showLtpWithHighlight
    } = this.props
    const { LtpTypoPropsFromState } = this.state
    const options = {
      minimumFractionDigits: segment === 'CURR' ? 4 : 2
    }
    const ltp = getCurrencyFormat(
      LTP || 0,
      withCurrencySymbol ? options : { ...options, style: 'decimal' }
    )
    return (
      <DsTypography
        variant='bodyRegularSmall'
        {...(showLtpWithHighlight ? LtpTypoPropsFromState : LtpTypoProps)}
      >
        {ltp}
      </DsTypography>
    )
  }
}

const mapStateToProps = (
  state: TTickerStore,
  ownProps: IStockTickerWithLtpProps
) => {
  const { scriptId, closeprice = 0, segment } = ownProps
  const { LTP, NET_CHANGE } = getRequiredTickerDataSelector(
    state,
    scriptId,
    closeprice,
    segment
  )

  return {
    LTP: LTP || closeprice,
    NET_CHANGE
  }
}

const connector = connect(mapStateToProps, null, null, {
  context: tickerContext
})

type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(StockTickerWithLTP)
