import React, { Component } from 'react'
import {
  DsFormControl,
  DsFormHelperText,
  DsInputBase,
  DsInputLabel,
  DsRemixIcon,
  DsStack,
  DsTypography
} from '@am92/react-design-system'
import { FormikErrors, FormikProps } from 'formik'

import NumberFormatter from '../../NumberFormatter'
import { FormValues } from './OrderFormSection'

import { changeOrderFormData } from '~/src/Redux/Orders/reducer'
import { getOrderPageModalIndicator } from '~/src/Redux/Orders/selectors'
import { T_SCRIPTDATA } from '~/src/Redux/StockMaster/Reducer'

import withErrorConnect from '~/src/Lib/withErrorConnect'
// lib
import { IWithRouterProps } from '~/src/Lib/withRouter'

type ActionTypes = {
  changeOrderFormData: (payload: { key: string; data: any }) => Promise<any>
}

export interface IQuantitySectionProps extends IWithRouterProps {
  actions: ActionTypes
  sliceError: boolean
  sliceHelperText: string | undefined
  quantityError: boolean
  isBUY: boolean
  quantityHelperText: string | undefined
  handleChange: (e: React.ChangeEvent<any>) => void
  quantity: number
  breakpoints: any
  stockDetails: T_SCRIPTDATA
  isModifyOrder: boolean
  isRepeatOrder: boolean
  orderPageModalIndicator: boolean
  formikBag: FormikProps<FormValues>
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean
  ) => Promise<void | FormikErrors<FormValues>>
}

class QuantitySection extends Component<IQuantitySectionProps> {
  constructor(props: IQuantitySectionProps) {
    super(props)
  }
  state: Readonly<any> = {
    qty: null
  }
  componentDidMount() {
    const { quantity, stockDetails, isModifyOrder, isRepeatOrder } = this.props

    const { segment, lotsize } = stockDetails || {}

    this.setState({ qty: null })

    if (isModifyOrder || isRepeatOrder) {
      if (segment === 'EQ') {
        this.setState({ qty: quantity })
      } else {
        this.setState({ qty: Number(quantity) / Number(lotsize) })
      }
    } else {
      this.setState({ qty: quantity })
    }

    // if (segment !== 'EQ') {
    //   setFieldValue('quantity', Number(quantity) / Number(lotsize))
    // }
  }

  componentDidUpdate(prevProps: Readonly<IQuantitySectionProps>): void {
    const { orderPageModalIndicator, quantity } = this.props
    if (orderPageModalIndicator !== prevProps.orderPageModalIndicator) {
      this.setState({ qty: 1 })
    }
    if (this.isQuantityChanged(quantity, prevProps.quantity)) {
      this.setState({ qty: quantity })
    }
  }
  isQuantityChanged(quantity: number, prevQuantity: number) {
    if (Number.isNaN(quantity) && Number.isNaN(prevQuantity)) {
      return false
    }

    return quantity !== prevQuantity
  }
  renderLabel = (color: string) => {
    const { stockDetails, sliceError } = this.props
    const { segment, lotsize } = stockDetails || {}
    const { qty } = this.state
    const formattedColor = sliceError ? color : 'unset'
    return (
      <DsInputLabel
        label={
          <DsTypography color={formattedColor} variant='bodyRegularMedium'>
            {segment === 'EQ' ? (lotsize > 1 ? 'Lots' : 'Quantity') : 'Lots'}
          </DsTypography>
        }
        sx={{ marginBottom: 'var(--ds-spacing-zero)' }}
        {...((segment === 'EQ' && lotsize > 1) || segment !== 'EQ'
          ? {
              labelSupportText: (
                <DsTypography
                  color={formattedColor}
                  variant='bodyRegularMedium'
                >
                  Size: {lotsize} Qty: {lotsize * qty}
                </DsTypography>
              )
            }
          : '')}
      />
    )
  }

  render() {
    const {
      quantityError,
      quantityHelperText,
      handleChange,
      sliceError,
      isBUY
    } = this.props
    const { qty } = this.state
    const formattedSliceOrderColor =
      sliceError && isBUY
        ? 'var(--palette-success-main) !important'
        : 'var(--palette-error-main)'
    return (
      <DsFormControl
        sx={{
          paddingLeft: {
            xs: 'var(--ds-spacing-bitterCold)',
            md: 'var(--ds-spacing-mild)'
          },
          paddingRight: {
            xs: 'var(--ds-spacing-bitterCold)',
            md: 'var(--ds-spacing-mild)'
          }
        }}
        error={quantityError}
      >
        <DsStack
          sx={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          {this.renderLabel(formattedSliceOrderColor)}
          <DsInputBase
            sx={{
              borderColor: sliceError
                ? isBUY
                  ? 'var(--palette-success-main)!important'
                  : 'var(--palette-error-main)'
                : ''
            }}
            inputComponent={NumberFormatter}
            name='quantity'
            placeholder='Enter Quantity'
            onChange={e => {
              this.setState({ qty: e.target.value })
              handleChange(e)
            }}
            value={qty}
            inputProps={{
              inputMode: 'numeric'
            }}
          />
        </DsStack>
        <DsStack
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
            gap: 'var(--ds-spacing-quickFreeze)',
            marginLeft: 'var(--ds-spacing-quickFreeze)',
            marginTop: 'var(--ds-spacing-glacial)'
          }}
        >
          {(quantityError && (
            <DsRemixIcon
              className={
                sliceError ? 'ri-checkbox-circle-fill' : 'ri-error-warning-fill'
              }
              fontSize='small'
              sx={{
                color: formattedSliceOrderColor
              }}
            />
          )) ||
            null}
          <DsFormHelperText
            error={quantityError}
            sx={{
              marginTop: 'var(--ds-spacing-zero)',
              padding: 'var(--ds-spacing-zero)',
              minHeight: 'unset',
              color: formattedSliceOrderColor
            }}
          >
            {quantityHelperText}
          </DsFormHelperText>
        </DsStack>
      </DsFormControl>
    )
  }
}

const mapStateToProps = (state: any) => {
  const orderPageModalIndicator = getOrderPageModalIndicator(state)
  return { orderPageModalIndicator }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    changeOrderFormData: (payload: { key: string; data: any }) =>
      dispatch(changeOrderFormData(payload))
  }
})

export default withErrorConnect(
  mapStateToProps,
  mapDispatchToProps
)(QuantitySection)
