import React, { ReactNode } from 'react'
import { DsSkeleton, DsStack } from '@am92/react-design-system'

class AlertPageSkeleton extends React.PureComponent {
  render(): ReactNode {
    return (
      <>
        <DsStack spacing={'var(--ds-spacing-warm)'} sx={{ width: '100%' }}>
          <DsStack sx={{ flexDirection: 'column' }}>
            <DsSkeleton
              variant='text'
              sx={{ height: '80px', width: '200px' }}
            />
          </DsStack>
          <DsStack spacing={'var(--ds-spacing-cool)'} sx={{ width: '100%' }}>
            <DsSkeleton variant='rounded' sx={{ height: '50px' }} />
          </DsStack>
          <DsStack
            spacing={'var(--ds-spacing-frostbite)'}
            sx={{ width: '100%' }}
          >
            <DsSkeleton variant='rounded' sx={{ height: '380px' }} />
          </DsStack>
        </DsStack>
      </>
    )
  }
}

export default AlertPageSkeleton
